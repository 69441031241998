import classnames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import useAbortController from "../../hooks/useAbortController";
import useStatusBarState from "../../hooks/useStatusBar";
import { MenuOptionValue, RemoteState, Result } from "../../services/types";
import strings from "../../strings";
import ArrowDown from "../../svg/ArrowDown.svg?react";
import ArrowUp from "../../svg/ArrowUp.svg?react";
import Cross from "../../svg/Cross.svg?react";
import CloseButton from "../CloseButton";
import { getString } from "../errors/Errors";
import styles from "./HeaderMenu.module.css";
import HeaderMenuOption, { Option } from "./HeaderMenuOption";

interface HeaderMenuProps {
  isFilterable?: boolean;
  isSortable?: boolean;
  fieldName: string;
  title: string;
  onClose: () => void;
  setOrderByDescending: (orderByDescending: boolean) => void;
  getFilters?: (fieldName: string, abortSignal: AbortSignal) => Promise<Result<MenuOptionValue[] | null>>;
  updateFilter?: (fieldName: string, values: MenuOptionValue[]) => void;
  selectedOptions: MenuOptionValue[];
  formatter?: (value: MenuOptionValue) => string;
}

const HeaderMenu: FC<HeaderMenuProps> = (props: HeaderMenuProps) => {
  const [abortController, resetAbortController] = useAbortController();
  const submenuRef = useRef<HTMLDivElement>(null);

  const statusBar = useStatusBarState();
  const [remoteState, setRemoteState] = useState<RemoteState>("loading");
  const [options, setOptions] = useState<Option[]>([]);

  const buildSelectedOptions = (values: MenuOptionValue[]) => {
    const options: Option[] = values.map((x) => ({
      value: x,
      selected:
        props.selectedOptions.filter((y) => {
          return y === x;
        })[0] !== undefined,
    }));
    return options;
  };

  const getFilters = async () => {
    if (props.getFilters) {
      const abortController = resetAbortController();
      const result = await props.getFilters(props.fieldName, abortController.signal);
      if (!abortController.signal.aborted) {
        if (result.data) {
          const options = buildSelectedOptions(result.data);
          setOptions(options);
          setRemoteState("success");
        }
        if (result.error) {
          statusBar.addError(getString(result.error));
          setRemoteState("failed");
        }
      }
    }
  };

  useEffect(() => {
    props.isFilterable && getFilters();

    const onClick = (event: MouseEvent) => {
      const target = event.target as Node;
      if (!(target instanceof Element)) return;

      if (submenuRef.current && !ReactDOM.findDOMNode(submenuRef.current)?.contains(target)) {
        props.onClose();
      }
    };

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
      abortController && abortController.abort();
    };
  }, []);

  const setAsc = () => {
    props.setOrderByDescending(false);
  };

  const setDesc = () => {
    props.setOrderByDescending(true);
  };

  const onClose = (event: React.MouseEvent) => {
    props.onClose();
    event.stopPropagation();
  };

  const update = (options: Option[]) => {
    setOptions(options);
    props.updateFilter &&
      props.updateFilter(
        props.fieldName,
        options.filter((x) => x.selected).map((x) => x.value),
      );
  };

  const clearFilters = () => {
    const newOptions: Option[] = options.map((x: Option) => {
      return {
        ...x,
        selected: false,
      };
    });
    update(newOptions);
  };

  const updateFilters = (option: Option) => {
    const os: Option[] = options.filter((x: Option) => x.value !== option.value);
    const newOptions: Option[] = [...os, option];
    update(newOptions);
  };

  const orderOptions = (a: Option, b: Option): number => {
    if (!a.value) return -1;
    if (!b.value) return 1;

    if (typeof a.value === "number" && typeof b.value === "number") {
      return a.value - b.value;
    }

    const valA = a.value.toString().toLowerCase();
    const valB = b.value.toString().toLowerCase();
    if (valA < valB) return -1;
    if (valA > valB) return 1;
    return 0;
  };

  return (
    <div ref={submenuRef}>
      {(props.isSortable || props.isFilterable) && (
        <div className={styles.menu}>
          <div>
            {!props.isSortable && (
              <div className={classnames(styles.option, styles.wrap)}>{strings.columnNotSortable}</div>
            )}
            {props.isSortable && (
              <>
                <div className={classnames(styles.option, styles["option-enabled"])} onClick={setAsc}>
                  <ArrowUp />
                  <span>Aufsteigend</span>
                </div>
                <div className={classnames(styles.option, styles["option-enabled"])} onClick={setDesc}>
                  <ArrowDown />
                  <span>Absteigend</span>
                </div>
              </>
            )}
            <div>
              <hr className={styles.separator} />
            </div>
            {!props.isFilterable && (
              <div className={classnames(styles.option, styles.wrap)}>{strings.columnNotFilterable}</div>
            )}
            {props.isFilterable && (
              <>
                <div
                  className={classnames(
                    styles.option,
                    styles.wrap,
                    options.filter((x) => x.selected).length === 0
                      ? styles["option-disabled"]
                      : styles["option-enabled"],
                  )}
                  onClick={clearFilters}
                >
                  <Cross />
                  <span>Filter für {props.title} löschen</span>
                </div>
                {remoteState == "success" && (
                  <div className={styles.options}>
                    {options.sort(orderOptions).map((x) => (
                      <HeaderMenuOption
                        key={`${x.value}`}
                        option={x}
                        updateFilters={updateFilters}
                        formatter={props.formatter}
                      ></HeaderMenuOption>
                    ))}
                  </div>
                )}
                {remoteState == "loading" && (
                  <li className={classnames(styles.option, styles["option-enabled"])}>{strings.loading}</li>
                )}
              </>
            )}
            <div className={styles["close-button"]}>
              <CloseButton onClose={onClose}></CloseButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMenu;

import { FC } from "react";
import CheckBox from "../../../components/forms/CheckBox";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import Link from "../../../components/Link";
import { Administration } from "../../../listsSettings/administration";
import { ValidationErrors } from "../../../services/types";
import * as SchemaFields from "../schemas/Fields";
import { searchSchemas } from "../schemas/webapi";
import * as Fields from "./Fields";
import { dispUrl, ListForm } from "./types";

interface NewEditTableProps {
  state: ListForm;
  setState: React.Dispatch<React.SetStateAction<ListForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.internalName.title}</FormLabel>
        <FormData>
          <Input
            value={state.internalName}
            onChange={(value) =>
              setState({
                ...state,
                internalName: value,
              })
            }
          />
          <FormError error={errors[Fields.internalName.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.schema.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.schema?.id}
            value={state.schema?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                schema: value,
                inheritPermissions: value ? state.inheritPermissions : false,
              })
            }
            searchItems={searchSchemas}
            searchDialogTitle={Administration.Lists.Schemas.Title}
            searchDialogFields={[SchemaFields.name]}
            getTitle={(item) => item.name ?? ""}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.inheritPermissions.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.inheritPermissions}
            onChange={(value) =>
              setState({
                ...state,
                inheritPermissions: value,
              })
            }
            disabled={!state.schema}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.url.title}</FormLabel>
        <FormData disp>
          <Link href={dispUrl(state)}></Link>
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import { Administration } from "../../../listsSettings/administration";
import { Result } from "../../../services/types";
import { administration, deleteItems, insertItem, updateItem } from "../../../services/webapi";
import { ListUserPermissionFull, ListUserPermissionPost } from "./types";

const listUserPermissionsUrl: string = `${administration}/${Administration.Lists.ListUserPermissions.InternalName}`;

export const insertListUserPermission = async (
  listUserPermission: ListUserPermissionPost,
  signal: AbortSignal,
): Promise<Result<ListUserPermissionFull | null>> => insertItem(listUserPermissionsUrl, listUserPermission, signal);

export const updateListUserPermission = async (
  listUserPermissionId: number,
  listUserPermission: ListUserPermissionPost,
  signal: AbortSignal,
): Promise<Result<ListUserPermissionFull | null>> => {
  const url = `${listUserPermissionsUrl}/${listUserPermissionId}`;
  return updateItem(url, listUserPermission, signal);
};

export const deleteListUserPermissions = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(listUserPermissionsUrl, ids, signal);

import { FC } from "react";
import { Item, SearchResult } from "../../services/types";
import NavigateBefore from "../../svg/NavigateBefore.svg?react";
import NavigateNext from "../../svg/NavigateNext.svg?react";
import styles from "./Navigation.module.css";
import { DefaultPagination, Pagination } from "./Pagination";

interface NavigationProps<T extends Item> {
  pagination: Pagination;
  searchResult: SearchResult<T>;
  id: string;
  navigateToPage: (page: number) => void;
}

const Navigation: FC<NavigationProps<Item>> = <T extends Item>(props: NavigationProps<T>) => {
  const page = props.pagination.page ?? DefaultPagination.page ?? 1;
  const itemsPerPage = props.pagination.itemsPerPage ?? DefaultPagination.itemsPerPage ?? 30;

  const prevPage = (page: number) => {
    if (page > 1) {
      props.navigateToPage(page - 1);
    }
  };

  const nextPage = (searchResult: SearchResult<Item>, page: number, itemsPerPage: number) => {
    if (page < Math.ceil(searchResult.total / itemsPerPage)) {
      props.navigateToPage(page + 1);
    }
  };

  return (
    <div className={styles.container}>
      {page > 1 && (
        <div className={styles.icon} onClick={() => prevPage(page)}>
          <NavigateBefore />
        </div>
      )}
      <div className={styles.pages}>
        {(page - 1) * itemsPerPage + 1} -{Math.min(itemsPerPage * page, props.searchResult.total)}
      </div>
      {itemsPerPage * page < props.searchResult.total && (
        <div className={styles.icon} onClick={() => nextPage(props.searchResult, page, itemsPerPage)}>
          <NavigateNext />
        </div>
      )}
    </div>
  );
};

export default Navigation;

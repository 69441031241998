import { FC, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { MenuItem } from "../../../services/types";
import { getGlobalMenuItems } from "../../../services/webapi";
import ArrowDropDown from "../../../svg/ArrowDropDown.svg?react";
import { getString } from "./../../errors/Errors";
import styles from "./GlobalNavigation.module.css";

export const GlobalNavigation: FC = () => {
  const statusBar = useStatusBarState();
  const currentSchema = useGlobalState((state) => state.schema);
  const menuItems = useGlobalState((state) => state.itemsGlobalMenu);
  const setItemsGlobalMenu = useGlobalState((state) => state.setItemsGlobalMenu);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const result = await getGlobalMenuItems(abortController.signal);
      result.error && statusBar.addError(getString(result.error));
      result.data && setItemsGlobalMenu(result.data);
      if (result.data) {
        const getSortedItems = (items: MenuItem[]): MenuItem[] => {
          return items.map((x) => ({ ...x, children: getSortedItems(x.children ?? []) }));
        };
        const sortedItems = getSortedItems(result.data);
        setItemsGlobalMenu(sortedItems);
      }
    })();

    return () => abortController.abort();
  }, []);

  const getTarget = (openNewTab: boolean): string => (openNewTab ? "_blank" : "_self");

  const getLinkParent = (item: MenuItem, parent?: MenuItem) => {
    const to = parent ? `${parent.url}/${item.url}` : item.url;
    return (
      <>
        {to && (
          <Link to={to} target={getTarget(item.openNewTab)}>
            {item.url == `/${currentSchema.item?.internalName}` ||
            (item.url == "/" && currentSchema.loaded && !currentSchema.item) ? (
              <b>{item.title}</b>
            ) : (
              <>{item.title}</>
            )}
          </Link>
        )}
      </>
    );
  };

  const renderItem = (item: MenuItem) => (
    <div className={styles["nav-item"]}>
      <div className={styles["nav-item-title"]}>
        {item.children && item.children.length > 0 ? (
          <>
            <span>{item.title}</span>
            <ArrowDropDown />
          </>
        ) : (
          getLinkParent(item)
        )}
      </div>
      {item.children && item.children.length > 0 && (
        <div className={styles["nav-item-children"]}>
          {item.children.map((child: MenuItem, childIndex: number) => (
            <div key={childIndex}>{getLinkParent(child, item)}</div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className={styles.nav}>
        {menuItems
          .sort((a, b) => a.order - b.order)
          .filter((a) => a.url || a.children?.length)
          .map((item: MenuItem, itemIndex: number) => (
            <Fragment key={`globalMenuItem_${itemIndex}`}>{renderItem(item)}</Fragment>
          ))}
      </div>
      <hr className={styles["no-margin"]} />
    </>
  );
};

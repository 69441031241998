import { FC } from "react";
import styles from "./Title.module.css";

interface TitleProps {
  text: string;
}

const Title: FC<TitleProps> = ({ text }: TitleProps) => {
  return (
    <div className={styles.title}>
      <h2>{text}</h2>
    </div>
  );
};

export default Title;

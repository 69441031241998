import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  putReference,
  removeReferencedItems,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { UserFull } from "../users/types";
import { GroupFull, GroupPost } from "./types";

const groupsUrl: string = `${administration}/${Administration.Lists.Groups.InternalName}`;
export const insertGroup = async (group: GroupPost, signal: AbortSignal): Promise<Result<GroupFull | null>> =>
  insertItem(groupsUrl, group, signal);

export const updateGroup = async (
  groupId: string,
  group: GroupPost,
  signal: AbortSignal,
): Promise<Result<GroupFull | null>> => updateItem(`${groupsUrl}/${groupId}`, group, signal);

export const deleteGroup = (id: string, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${groupsUrl}/${id}`, signal);

export const deleteGroups = (ids: string[], signal: AbortSignal): Promise<Result<{ [key: string]: boolean } | null>> =>
  deleteItems(groupsUrl, ids, signal);

export const getGroup = async (id: string, signal: AbortSignal): Promise<Result<GroupFull | null>> =>
  getItem<GroupFull>(`${groupsUrl}/${id}`, signal);

export const getGroups = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<GroupFull> | null>> => getItems<GroupFull>(groupsUrl, pagination, signal);

export const getGroupFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(groupsUrl, fieldName, signal);

export const searchGroups = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<GroupFull> | null>> => searchItems<GroupFull>(groupsUrl, searchValue, signal);

export const getGroupUsers = async (
  groupId: string,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> =>
  getItems<UserFull>(`${groupsUrl}/${groupId}/users`, pagination, signal);

export const getGroupUsersFilters = (
  groupId: string,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${groupsUrl}/${groupId}/users`, fieldname, signal);

export const getGroupGroups = async (
  groupId: string,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<GroupFull> | null>> =>
  getItems<GroupFull>(`${groupsUrl}/${groupId}/parents`, pagination, signal);

export const getGroupGroupsFilters = (
  groupId: string,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${groupsUrl}/${groupId}/groups`, fieldname, signal);

export const addGroupGroup = (
  group: GroupFull,
  parent: GroupFull,
  signal: AbortSignal,
): Promise<CustomError | null> => {
  const url = `${groupsUrl}/${parent.id}/parents/${group.id}`;
  return putReference(url, signal);
};

export const removeGroupsOfParent = (
  parent: GroupFull,
  groupsToRemove: GroupFull[],
  signal: AbortSignal,
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${groupsUrl}/${parent.id}/groups`;
  return removeReferencedItems(
    url,
    groupsToRemove.map((x) => x.id, signal),
    signal,
  );
};

export const removeGroupGroups = (
  group: GroupFull,
  groupsToRemove: GroupFull[],
  signal: AbortSignal,
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${groupsUrl}/${group.id}/parents`;
  return removeReferencedItems(
    url,
    groupsToRemove.map((x) => x.id, signal),
    signal,
  );
};

export const removeGroupUsers = (
  group: GroupFull,
  usersToRemove: UserFull[],
  signal: AbortSignal,
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${groupsUrl}/${group.id}/users`;
  return removeReferencedItems(
    url,
    usersToRemove.map((x) => x.id, signal),
    signal,
  );
};

export const getGroupsOfParent = async (
  parentId: string,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<GroupFull> | null>> =>
  getItems<GroupFull>(`${groupsUrl}/${parentId}/groups`, pagination, signal);

export const getGroupsOfParentFilters = (
  parentId: string,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${groupsUrl}/${parentId}/parent`, fieldname, signal);

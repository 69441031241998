import { FC } from "react";
import styles from "./Message.module.css";

interface MessageProps {
  children: React.ReactNode;
}

const Message: FC<MessageProps> = ({ children }: MessageProps) => <span className={styles.text}>{children}</span>;

export default Message;

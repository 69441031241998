import Link from "../../../components/Link";
import { IDialogField, IListviewField, MenuOptionValue } from "../../../services/types";
import {
  formatBoolean,
  formatDateFromISOString,
  formatTimeFromMinutes,
  getCurrentUrlAsSource,
} from "../../../services/utils";
import { ChapterEventFull, getEventFrom, getEventTo } from "./types";
import { dispForm } from "./urls";

export const title: IListviewField<ChapterEventFull> & IDialogField<ChapterEventFull> = {
  title: "Titel",
  fieldName: "title",
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.title}</Link>
  ),
  renderDialogValue: (item: ChapterEventFull) => item.title,
};

export const description: IListviewField<ChapterEventFull> = {
  title: "Beschreibung",
  fieldName: "description",
  renderListValue: (item: ChapterEventFull) => item.description,
};

export const location: IListviewField<ChapterEventFull> = {
  title: "Ort",
  fieldName: "location",
  renderListValue: (item: ChapterEventFull) => item.location,
};

export const date: IListviewField<ChapterEventFull> = {
  title: "Datum",
  fieldName: "date",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => formatDateFromISOString(item.from),
  renderMenuOptionValue: (value: MenuOptionValue) => formatDateFromISOString(value as string) || "(Leer)",
};

export const from: IListviewField<ChapterEventFull> = {
  title: "Von",
  fieldName: "from",
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => formatTimeFromMinutes(getEventFrom(item)),
};

export const to: IListviewField<ChapterEventFull> = {
  title: "Bis",
  fieldName: "to",
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => formatTimeFromMinutes(getEventTo(item)),
};

export const visibleOnChaptersIt: IListviewField<ChapterEventFull> = {
  title: "Sichtbar für gesamtes OG-Portal",
  fieldName: "visibleOnChaptersIt",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => formatBoolean(item.visibleOnChaptersIt),
};

export const visibleOnHgvIt: IListviewField<ChapterEventFull> = {
  title: "Sichtbar auf HGV.it",
  fieldName: "visibleOnHgvIt",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => formatBoolean(item.visibleOnHgvIt),
};

export const chapter: IListviewField<ChapterEventFull> = {
  title: "Sichtbar für Ortsgruppe",
  fieldName: "chapter.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => item.chapter?.membersChapter?.name,
};

export const district: IListviewField<ChapterEventFull> = {
  title: "Sichtbar für Bezirk",
  fieldName: "district.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => item.district?.name,
};

export const category: IListviewField<ChapterEventFull> = {
  title: "Kategorie",
  fieldName: "category.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterEventFull) => item.category.name,
};

export const signUpLink: IListviewField<ChapterEventFull> = {
  title: "Anmeldelink",
  fieldName: "signUpLink",
  renderListValue: (item: ChapterEventFull) =>
    item.signUpLink && <Link href={item.signUpLink} openInNewTab external></Link>,
};

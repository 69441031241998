import Link from "../../../components/Link";
import Number from "../../../components/Number";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { AreaFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<AreaFull> & IDialogField<AreaFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: AreaFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: AreaFull) => item.name,
};

export const order: IListviewField<AreaFull> = {
  title: "Reihenfolge",
  fieldName: "order",
  isSortable: true,
  renderListValue: (item: AreaFull) => <Number value={item.order} />,
};

export const district: IListviewField<AreaFull> = {
  title: "Bezirk",
  fieldName: "district.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: AreaFull) => item.district.name,
};

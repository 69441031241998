import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Conventions } from "../../../listsSettings/conventions";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  conventions,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { ContactFull, ContactPost } from "./types";

const contactsUrl: string = `${conventions}/${Conventions.Lists.Contacts.InternalName}`;
export const insertContact = async (contact: ContactPost, signal: AbortSignal): Promise<Result<ContactFull | null>> =>
  insertItem(contactsUrl, contact, signal);

export const updateContact = async (
  contactId: number,
  contact: ContactPost,
  signal: AbortSignal,
): Promise<Result<ContactFull | null>> => updateItem(`${contactsUrl}/${contactId}`, contact, signal);

export const deleteContact = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${contactsUrl}/${id}`, signal);

export const deleteContacts = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(contactsUrl, ids, signal);

export const getContact = async (id: number, signal: AbortSignal): Promise<Result<ContactFull | null>> =>
  getItem<ContactFull>(`${contactsUrl}/${id}`, signal);

export const getContacts = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<ContactFull> | null>> => getItems<ContactFull>(contactsUrl, pagination, signal);

export const getContactFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(contactsUrl, fieldName, signal);

const calendarTypes = <const>["month", "week", "day"];
export type CalendarType = (typeof calendarTypes)[number];
export const isCalendarType = (i: string | null): i is CalendarType => {
  return calendarTypes.includes(i as CalendarType);
};

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

export interface CalendarProps {
  updateCalendar: (title: string, type: CalendarType) => void;
  data: CalendarListData[];
  colors?: {
    backGround?: Color;
    text?: Color;
  };
  addHref?: string;
  onAddItem?: (date: Date) => void;
}

export interface CalendarItem {
  id: number;
  title: string;
  description?: string | null;
  from: Date;
  to: Date;
}

export interface CalendarListData {
  dispUrl: string;
  getItems: (from: Date, to: Date) => Promise<CalendarItem[]>;
  colors?: {
    backGround?: Color;
    text?: Color;
  };
}

export interface CalenderDataItem {
  data: CalendarListData;
  item: CalendarItem;
}

export interface CalendarCell {
  value: { data?: CalendarListData; item: CalendarItem };
  width?: number;
}

export interface CalendarDay {
  [key: string]: CalendarCell[];
}

export interface CalendarWeek {
  [key: string]: CalendarDay;
}

import classNames from "classnames";
import * as React from "react";
import { useEffect, useRef } from "react";
import styles from "./DropDownItem.module.css";
import DropDownContext from "./DropDownItemContext";

interface DropDownItemProps {
  className?: string;
  icon?: {
    className?: string;
    isOpaque?: boolean;
  };
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  title?: string;
}

const DropDownItem = ({ className, icon, onClick, title }: DropDownItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const dropDownContext = React.useContext(DropDownContext);

  if (dropDownContext) {
    const { registerItem } = dropDownContext;

    useEffect(() => {
      if (ref && ref.current) {
        registerItem(ref);
      }
    }, [ref, registerItem]);
  }

  return (
    <div className={classNames(className, styles.item)} onClick={onClick} ref={ref} title={title}>
      {icon?.className && (
        <i className={classNames(styles.icon, icon?.className, { [styles.opaque]: icon.isOpaque })} />
      )}
      {title && <span className={styles.text}>{title}</span>}
    </div>
  );
};

export default DropDownItem;

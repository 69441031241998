import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface PartnerFull extends CreationInformationItem {
  id: number;
  name: string;
  street: string | null;
  zip: string | null;
  city: string | null;
  province: string | null;
}

export interface PartnerForm {
  name: string | null;
  street: string | null;
  zip: string | null;
  city: string | null;
  province: string | null;
}

export interface PartnerPost {
  name: string;
  street: string | null;
  zip: string | null;
  city: string | null;
  province: string | null;
}

export const mapToPOST = (item: PartnerForm): PartnerPost => {
  return {
    name: item.name || "",
    street: item.street,
    zip: item.zip,
    city: item.city,
    province: item.province,
  };
};

export const validate = (
  state: PartnerForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

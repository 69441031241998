import { Administration } from "../../../listsSettings/administration";
import { Result } from "../../../services/types";
import { administration, deleteItems, insertItem, updateItem } from "../../../services/webapi";
import { ListGroupPermissionFull, ListGroupPermissionPost } from "./types";

const listGroupPermissionsUrl: string = `${administration}/${Administration.Lists.ListGroupPermissions.InternalName}`;

export const insertListGroupPermission = async (
  listGroupPermission: ListGroupPermissionPost,
  signal: AbortSignal,
): Promise<Result<ListGroupPermissionFull | null>> => insertItem(listGroupPermissionsUrl, listGroupPermission, signal);

export const updateListGroupPermission = async (
  listGroupPermissionId: number,
  listGroupPermission: ListGroupPermissionPost,
  signal: AbortSignal,
): Promise<Result<ListGroupPermissionFull | null>> => {
  const url = `${listGroupPermissionsUrl}/${listGroupPermissionId}`;
  return updateItem(url, listGroupPermission, signal);
};

export const deleteListGroupPermissions = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(listGroupPermissionsUrl, ids, signal);

import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface UserFull extends CreationInformationItem {
  id: number;
  name: string;
  key: string;
}

export interface UserForm {
  name: string | null;
  key: string | null;
}

export interface UserPost {
  name: string;
  key: string;
}

export const mapToPOST = (item: UserForm): UserPost => {
  return {
    name: item.name || "",
    key: item.key || "",
  };
};

export const validate = (
  state: UserForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.key) {
    errors[Fields.key.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

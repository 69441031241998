import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import { Members } from "../../../listsSettings/members";
import { ErrorCode, IAction } from "../../../services/types";
import { getCloseAction } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { Company } from "./types";
import { allItems } from "./urls";
import { getCompany } from "./webapi";

const DispForm: FC = () => {
  const navigateTo = useNavigate();
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [state, setState] = useState<Company>();
  const [abortController, resetAbortController] = useAbortController();
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Members.Lists.Companies.InternalName,
      Members.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getCompany(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);
        }
      }
    }
  };

  const closeAction: IAction = getCloseAction(navigateTo, allItems);

  useEffect(() => {
    if (state) {
      setListName(Members.Lists.Companies.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Mitgliedsbetrieb"} />
          <Actions actions={[closeAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.companyNumber.title}</FormLabel>
              <FormData disp>{state?.id}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

import classNames from "classnames";
import {
  add,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  getDaysInMonth,
  isAfter,
  isBefore,
  isLastDayOfMonth,
  isSameDay,
  startOfMonth,
  startOfWeek,
} from "date-fns/esm";
import { FC } from "react";
import useOnKeyDownHandler from "../../hooks/useOnKeyDownHandler";
import { weekDaysShort } from "../../services/types";
import Caption from "./Caption";
import styles from "./DayPicker.module.css";
import Table from "./Table";

interface DayPickerProps {
  date: Date;
  onSelect: (value: Date) => void;
  onMove: (key: keyof Duration, value: number) => void;
  onCancel: () => void;
  setType: (returnTo: "cal" | "months" | "years") => void;
  setReturnTo: (returnTo: "cal" | "months") => void;
}

const DayPicker: FC<DayPickerProps> = (props: DayPickerProps) => {
  const monthStart = startOfMonth(props.date);
  const monthEnd = endOfMonth(props.date);
  const today = new Date();

  const calStart = startOfWeek(monthStart);
  const calEnd = endOfWeek(monthEnd);

  const days = differenceInDays(calEnd, calStart) + 1;

  let tmpDate = new Date(calStart);
  const weekRows = Array.from({ length: days }).reduce((rv: Date[][], _, i) => {
    const row = Math.floor(i / 7);
    const column = i - row * 7;
    (rv[row] = rv[row] || [])[column] = new Date(tmpDate);
    i++;
    tmpDate = add(tmpDate, { days: 1 });
    return rv;
  }, []);

  useOnKeyDownHandler({
    arrowUp: (options) => props.onMove(options.shiftKey ? "months" : "weeks", -1),
    arrowDown: (options) => props.onMove(options.shiftKey ? "months" : "weeks", 1),
    arrowLeft: (options) => props.onMove(options.shiftKey ? "weeks" : "days", -1),
    arrowRight: (options) => props.onMove(options.shiftKey ? "weeks" : "days", 1),
    pageUp: (options) => props.onMove(options.shiftKey ? "years" : "months", -1),
    pageDown: (options) => props.onMove(options.shiftKey ? "years" : "months", 1),
    enter: () => props.onSelect(props.date),
    space: () => props.onSelect(props.date),
    home: () => {
      if (props.date.getDate() !== 1) {
        props.onMove("days", -1 * (props.date.getDate() - 1));
      }
    },
    end: () => {
      if (!isLastDayOfMonth(props.date)) {
        props.onMove("days", getDaysInMonth(props.date) - props.date.getDate());
      }
    },
    escape: props.onCancel,
  });

  return (
    <>
      <Caption
        captions={[
          {
            title: props.date.toLocaleDateString("de", { month: "long" }),
            onclick: () => {
              props.setType("months");
              props.setReturnTo("months");
            },
          },
          {
            title: props.date.getFullYear().toString(),
            onclick: () => {
              props.setType("years");
              props.setReturnTo("cal");
            },
          },
        ]}
        navigateBack={() => props.onMove("months", -1)}
        navigateForward={() => props.onMove("months", 1)}
      />
      <Table
        header={
          <tr>
            {weekDaysShort.map((x, i) => (
              <th key={`day_picker_th_${i}`} className={styles.header}>
                {x}
              </th>
            ))}
          </tr>
        }
        body={
          <>
            {weekRows.map((row, rowIndex) => (
              <tr key={`day_month_picker_row_${rowIndex}`}>
                {row.map((day) => (
                  <td key={`day_month_picker_row_${day.toISOString()}`}>
                    <div
                      className={classNames(styles.day, {
                        [styles.today]: isSameDay(day, today),
                        [styles.selected]: isSameDay(day, props.date),
                        [styles.outside]: isBefore(day, monthStart) || isAfter(day, monthEnd),
                      })}
                      onClick={() => props.onSelect(day)}
                      tabIndex={isSameDay(day, props.date) ? 0 : isBefore(day, today) ? -1 : 1}
                    >
                      {day.getDate()}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </>
        }
      ></Table>
    </>
  );
};

export default DayPicker;

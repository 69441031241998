import Euro from "../../../components/Euro";
import { IListviewField } from "../../../services/types";
import { formatDateFromISOString } from "../../../services/utils";
import { Fee } from "./types";

export const member: IListviewField<Fee> = {
  title: "Mitglied",
  fieldName: "member.name",
  isSortable: true,
  renderListValue: (item: Fee) => item.member.name,
};

export const amount: IListviewField<Fee> = {
  title: "Beitrag",
  fieldName: "amount",
  isSortable: true,
  renderListValue: (item: Fee) => <Euro value={item.amount} />,
};

export const adjustmentAmount: IListviewField<Fee> = {
  title: "Korrektur Beitrag",
  fieldName: "adjustmentAmount",
  isSortable: true,
  renderListValue: (item: Fee) => <Euro value={item.adjustmentAmount} />,
};

export const finalAmount: IListviewField<Fee> = {
  title: "Zu zahlender Beitrag",
  fieldName: "amountToBePaid",
  isSortable: true,
  renderListValue: (item: Fee) => <Euro value={item.amount - (item.adjustmentAmount ?? 0)} />,
};

export const dueDate: IListviewField<Fee> = {
  title: "Fälligkeit",
  fieldName: "dueDate",
  isSortable: true,
  renderListValue: (item: Fee) => formatDateFromISOString(item.dueDate),
};

export const documentDate: IListviewField<Fee> = {
  title: "Dokument Datum",
  fieldName: "documentDate",
  isSortable: true,
  renderListValue: (item: Fee) => formatDateFromISOString(item.documentDate),
};

export const documentNumber: IListviewField<Fee> = {
  title: "Dokument Nr.",
  fieldName: "documentNumber",
  isSortable: true,
  renderListValue: (item: Fee) => item.documentNumber,
};

export const paymentType: IListviewField<Fee> = {
  title: "Zahlungsart",
  fieldName: "paymentType",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: Fee) => item.paymentType,
};

import { $patchStyleText } from "@lexical/selection";
import classNames from "classnames";
import { $getSelection, $isRangeSelection, LexicalEditor } from "lexical";
import { FC, useCallback } from "react";
import DropDown from "./DropDown";
import DropDownItem from "./DropDownItem";
import styles from "./FontDropDown.module.css";

interface FontDropDownProps {
  editor: LexicalEditor;
  value: string;
  type: "font-family" | "font-size";
}

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ["10px", "10px"],
  ["11px", "11px"],
  ["12px", "12px"],
  ["13px", "13px"],
  ["14px", "14px"],
  ["15px", "15px"],
  ["16px", "16px"],
  ["17px", "17px"],
  ["18px", "18px"],
  ["19px", "19px"],
  ["20px", "20px"],
];

const FontDropDown: FC<FontDropDownProps> = ({ editor, value, type }) => {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [type]: option,
          });
        }
      });
    },
    [editor, type],
  );

  return (
    <DropDown
      label={value}
      buttonIconClassName={classNames({
        [styles["font-family"]]: type === "font-family",
      })}
    >
      {(type === "font-family" ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, text]) => (
        <DropDownItem onClick={() => handleClick(option)} key={option} title={text} />
      ))}
    </DropDown>
  );
};

export default FontDropDown;

import { Pagination } from "../../../components/listview/Pagination";
import { Members } from "../../../listsSettings/members";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { getFilters, getItem, getItems, members } from "../../../services/webapi";
import { MemberBonus } from "./types";

const bonuses: string = `${members}/${Members.Lists.Bonuses.InternalName}`;

export const getMemberBonus = async (id: number, signal: AbortSignal): Promise<Result<MemberBonus | null>> => {
  const url = `${bonuses}/${id}`;
  return getItem<MemberBonus>(url, signal);
};

export const getBonuses = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<MemberBonus> | null>> => getItems<MemberBonus>(bonuses, pagination, signal);

export const getBonusesBonusesPreviousYear = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<MemberBonus> | null>> =>
  getItems<MemberBonus>(`${bonuses}/previous_year`, pagination, signal);

export const getMemberBonusFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(bonuses, fieldName, signal);

export const getMemberBonusBonusesPreviousYearFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${bonuses}/previous_year`, fieldName, signal);

import classNames from "classnames";
import { FC } from "react";
import styles from "./Textarea.module.css";

interface TextareaProps {
  value?: string;
  readOnly?: boolean;
  rows?: number;
}

const Textarea: FC<TextareaProps> = ({ value, readOnly, rows }: TextareaProps) => {
  return (
    <textarea
      value={value}
      readOnly={readOnly}
      className={classNames(styles.textarea, { [styles.readOnly]: readOnly })}
      rows={rows}
    ></textarea>
  );
};

export default Textarea;

import { formatGender } from "../../../components/forms/GenderSelectBox";
import { formatLanguage } from "../../../components/forms/LanguageSelectBox";
import Link from "../../../components/Link";
import { IDialogField, IListviewField, MenuOptionValue } from "../../../services/types";
import { formatDateFromISOString, getCurrentUrlAsSource } from "../../../services/utils";
import { getItemTitle, PersonFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<PersonFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: PersonFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{getItemTitle(item)}</Link>
  ),
};

export const lastName: IListviewField<PersonFull> & IDialogField<PersonFull> = {
  title: "Nachname",
  fieldName: "lastName",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.lastName,
  renderDialogValue: (item: PersonFull) => item.lastName,
};

export const firstName: IListviewField<PersonFull> & IDialogField<PersonFull> = {
  title: "Vorname",
  fieldName: "firstName",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.firstName,
  renderDialogValue: (item: PersonFull) => item.firstName,
};

export const taxCode: IListviewField<PersonFull> = {
  title: "Steuernummer",
  fieldName: "taxCode",
  isSortable: true,
  renderListValue: (item: PersonFull) => item.taxCode,
};

export const salutation: IListviewField<PersonFull> = {
  title: "Anrede",
  fieldName: "salutation",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.salutation,
};

export const phone: IListviewField<PersonFull> = {
  title: "Telefon",
  fieldName: "phone",
  isSortable: true,
  renderListValue: (item: PersonFull) => item.phone,
};

export const mobile: IListviewField<PersonFull> = {
  title: "Mobil",
  fieldName: "mobile",
  isSortable: true,
  renderListValue: (item: PersonFull) => item.mobile,
};

export const email: IListviewField<PersonFull> = {
  title: "E-Mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: PersonFull) => item.email,
};

export const zip: IListviewField<PersonFull> = {
  title: "PLZ",
  fieldName: "zip",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.zip,
};

export const street: IListviewField<PersonFull> = {
  title: "Straße",
  fieldName: "street",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.street,
};

export const city: IListviewField<PersonFull> = {
  title: "Stadt",
  fieldName: "city",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.city,
};

export const province: IListviewField<PersonFull> = {
  title: "Provinz",
  fieldName: "province",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.province,
};

export const country: IListviewField<PersonFull> = {
  title: "Land",
  fieldName: "country",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.country,
};

export const birthplace: IListviewField<PersonFull> = {
  title: "Geburtsort",
  fieldName: "birthplace",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => item.birthplace,
};

export const birthdate: IListviewField<PersonFull> = {
  title: "Geburtstag",
  fieldName: "birthdate",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => formatDateFromISOString(item.birthdate),
  renderMenuOptionValue: (value: MenuOptionValue) => formatDateFromISOString(value as string) || "(Leer)",
};

export const gender: IListviewField<PersonFull> = {
  title: "Geschlecht",
  fieldName: "gender",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => formatGender(item.gender),
  renderMenuOptionValue: (value: MenuOptionValue) => formatGender(value as string) || "(Leer)",
};

export const language: IListviewField<PersonFull> = {
  title: "Sprache",
  fieldName: "language",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: PersonFull) => formatLanguage(item.language),
  renderMenuOptionValue: (value: MenuOptionValue) => formatLanguage(value as string) || "(Leer)",
};

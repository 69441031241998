import { isValidPhoneNumber } from "libphonenumber-js";
import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import { isEmailValid } from "../../../services/utils";
import strings from "../../../strings";
import { ConventionFull } from "../conventions/types";
import * as Fields from "./Fields";

export interface ContactFull extends CreationInformationItem {
  id: number;
  name: string;
  phone: string | null;
  mobile: string | null;
  email: string | null;
  website: string | null;
  convention: ConventionFull;
}

export interface ContactForm {
  name: string | null;
  phone: string | null;
  mobile: string | null;
  email: string | null;
  website: string | null;
  convention: ConventionFull | null;
}

export interface ContactPost {
  name: string;
  phone: string | null;
  mobile: string | null;
  email: string | null;
  website: string | null;
  conventionId: number;
}

export const mapToPOST = (item: ContactForm): ContactPost => {
  return {
    name: item.name || "",
    conventionId: item.convention?.id ?? 0,
    phone: item.phone,
    mobile: item.mobile,
    email: item.email,
    website: item.website,
  };
};

export const validate = (
  state: ContactForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (state.email && !isEmailValid(state.email)) {
    errors[Fields.email.fieldName] = strings.invalidText;
  }
  if (state.phone && !isValidPhoneNumber(state.phone)) {
    errors[Fields.phone.fieldName] = strings.invalidText;
  }
  if (state.mobile && !isValidPhoneNumber(state.mobile)) {
    errors[Fields.mobile.fieldName] = strings.invalidText;
  }
  if (!state.convention?.id) {
    errors[Fields.convention.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

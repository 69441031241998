import { FC, PropsWithChildren } from "react";
import styles from "./Button.module.css";

interface ButtonProp {
  onclick: (event: React.MouseEvent) => void;
  disabled?: boolean;
}

const Button: FC<PropsWithChildren<ButtonProp>> = ({
  children,
  onclick,
  disabled,
}: PropsWithChildren<ButtonProp>): JSX.Element => (
  <button onClick={onclick} className={styles.button} disabled={disabled}>
    {children}
  </button>
);

export default Button;

import { FC, useEffect } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NotFound from "../components/errors/NotFound";
import useGlobalState from "../hooks/useGlobalState";
import { Public } from "../listsSettings/public";
import AllItemsPersons from "./public/persons/AllItems";
import DispFormPersons from "./public/persons/DispForm";
import EditFormPersons from "./public/persons/EditForm";
import NewFormPersons from "./public/persons/NewForm";

const Home: FC = () => {
  const setCurrentSchema = useGlobalState((state) => state.setCurrentSchema);

  useEffect(() => {
    setCurrentSchema(null);
  }, []);

  return (
    <Routes>
      <Route path="/me/:userId?">TODO</Route>

      <Route path={`/`} element={<AllItemsPersons />} />

      <Route path={`_lists/${Public.Persons.InternalName}`}>
        <Route index element={<AllItemsPersons />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsPersons />} />
          <Route path="new" element={<NewFormPersons />} />
          <Route path="disp/:id" element={<DispFormPersons />} />
          <Route path="edit/:id" element={<EditFormPersons />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Home;

import Email from "../../../components/Email";
import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { UserFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<UserFull> & IDialogField<UserFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: UserFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: UserFull) => item.name,
};

export const department: IListviewField<UserFull> & IDialogField<UserFull> = {
  title: "Abteilung",
  fieldName: "department",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: UserFull) => item.department,
  renderDialogValue: (item: UserFull) => item.department,
};

export const email: IListviewField<UserFull> & IDialogField<UserFull> = {
  title: "E-mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: UserFull) => <Email value={item.email} />,
  renderDialogValue: (item: UserFull) => item.email,
};

export const accountEnabled: IListviewField<UserFull> & IDialogField<UserFull> = {
  title: "Aktiv",
  fieldName: "accountEnabled",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserFull) => formatBoolean(item.accountEnabled),
  renderDialogValue: (item: UserFull) => formatBoolean(item.accountEnabled),
};

export const offices: IListviewField<UserFull> = {
  title: "Büro",
  fieldName: "office.name",
  isFilterable: true,
  renderListValue: (item: UserFull) =>
    item.offices && item.offices.length
      ? item.offices.length == 1
        ? item.offices[0]?.name
        : item.offices.map((x) => `${x.name};`).join(" ")
      : null,
};

import Link from "../../../components/Link";
import { IDialogField, IListviewField, MenuOptionValue } from "../../../services/types";
import { formatDateFromISOString, getCurrentUrlAsSource } from "../../../services/utils";
import { getChapterTitle } from "../../members/chapters/types";
import { ChapterFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<ChapterFull> & IDialogField<ChapterFull> = {
  title: "Name",
  fieldName: "membersChapter.name",
  isSortable: true,
  renderListValue: (item: ChapterFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.membersChapter?.name}</Link>
  ),
  renderDialogValue: (item: ChapterFull) => item.membersChapter?.name,
};

export const area: IListviewField<ChapterFull> & IDialogField<ChapterFull> = {
  title: "Gebiet",
  fieldName: "area.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterFull) => item.area?.name,
  renderDialogValue: (item: ChapterFull) => item.area?.name,
};

export const from: IListviewField<ChapterFull> = {
  title: "Gültigkeit von",
  fieldName: "from",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterFull) => formatDateFromISOString(item.from),
  renderMenuOptionValue: (value: MenuOptionValue) => formatDateFromISOString(value as string) || "(Leer)",
};

export const to: IListviewField<ChapterFull> = {
  title: "Gültigkeit bis",
  fieldName: "to",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterFull) => formatDateFromISOString(item.to),
  renderMenuOptionValue: (value: MenuOptionValue) => formatDateFromISOString(value as string) || "(Leer)",
};

export const district: IListviewField<ChapterFull> & IDialogField<ChapterFull> = {
  title: "Bezirk",
  fieldName: "area.district.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterFull) => item.area?.district?.name,
  renderDialogValue: (item: ChapterFull) => item.area?.district?.name,
};

export const membersChapter: IListviewField<ChapterFull> = {
  title: "Ombis Referenz",
  fieldName: "membersChapter",
  isSortable: true,
  renderListValue: (item: ChapterFull) => item.membersChapter?.name,
};

export const membersChapterCode: IListviewField<ChapterFull> = {
  title: "Ombis Referenz",
  fieldName: "membersChapter.code",
  isSortable: true,
  renderListValue: (item: ChapterFull) => getChapterTitle(item.membersChapter),
};

export const vatNumber: IListviewField<ChapterFull> = {
  title: "MwSt. Nummer",
  fieldName: "vatNumber",
  isSortable: true,
  renderListValue: (item: ChapterFull) => item.vatNumber,
};

export const taxCode: IListviewField<ChapterFull> = {
  title: "Steuernummer",
  fieldName: "taxCode",
  isSortable: true,
  renderListValue: (item: ChapterFull) => item.taxCode,
};

export const iban: IListviewField<ChapterFull> = {
  title: "IBAN",
  fieldName: "iban",
  isSortable: true,
  renderListValue: (item: ChapterFull) => item.iban,
};

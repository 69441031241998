import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export const getItemTitle = (item?: LegislatureFull | null): string =>
  item
    ? `Legislatur ${item?.year} - ${item?.year && item?.durationYears ? item.year + item.durationYears : ""}`.trim()
    : "";

export interface LegislatureFull extends CreationInformationItem {
  id: number;
  year: number;
  durationYears: number;
}

export interface LegislatureForm {
  year: number | null;
  durationYears: number | null;
}

export interface LegislaturePost {
  year: number;
  durationYears: number;
}

export const mapToPOST = (item: LegislatureForm): LegislaturePost => {
  return {
    year: item.year || 0,
    durationYears: item.durationYears || 0,
  };
};

export const validate = (
  state: LegislatureForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.year) {
    errors[Fields.year.fieldName] = strings.required;
  }
  if (!state.durationYears) {
    errors[Fields.duration.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

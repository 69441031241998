import { FC, useState } from "react";
import * as Fields from "../../../home/administration/currentNavigationItems/Fields";
import { MenuItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import Button from "./../../Button";
import { DialogBorder } from "./../../dialogs/DialogBorder";
import { DialogCloseIcon } from "./../../dialogs/DialogCloseIcon";
import { DialogContainer } from "./../../dialogs/DialogContainer";
import { Draggable } from "./../../dialogs/Draggable";
import CheckBox from "./../../forms/CheckBox";
import FormData from "./../../forms/FormData";
import FormError from "./../../forms/FormError";
import FormLabel from "./../../forms/FormLabel";
import FormTable from "./../../forms/FormTable";
import FormTr from "./../../forms/FormTr";
import Input from "./../../forms/Input";
import Title from "./../../Title";
import styles from "./NewEditMenuItem.module.css";

interface NewEditMenuItemProps {
  onAddOrUpdate: (item: MenuItem) => void;
  onCancel: () => void;
  item?: MenuItem;
}

const NewEditMenuItem: FC<NewEditMenuItemProps> = ({ onAddOrUpdate, onCancel, item }: NewEditMenuItemProps) => {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [state, setState] = useState<MenuItem>(
    item
      ? {
          ...item,
        }
      : {
          title: "",
          url: null,
          openNewTab: false,
          order: 99,
        },
  );

  const validate = (item: MenuItem) => {
    const errors: ValidationErrors = {};
    if (!item.title) {
      errors[Fields.name.fieldName] = strings.required;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Draggable>
      <DialogContainer>
        <DialogCloseIcon onClose={() => onCancel()} />
        <DialogBorder>
          <Title text={item ? "Link bearbeiten" : "Link hinzufügen"} />
          <FormTable>
            <FormTr>
              <FormLabel required>{Fields.name.title}</FormLabel>
              <FormData>
                <Input
                  value={state.title}
                  onChange={(value) =>
                    setState({
                      ...state,
                      title: value,
                    })
                  }
                />
                <FormError error={errors[Fields.name.fieldName]} />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.url.title}</FormLabel>
              <FormData>
                <Input
                  value={state.url}
                  onChange={(value) =>
                    setState({
                      ...state,
                      url: value,
                    })
                  }
                />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel noWrap>{Fields.openNewTab.title}</FormLabel>
              <FormData>
                <CheckBox
                  value={state.openNewTab}
                  onChange={(value) =>
                    setState({
                      ...state,
                      openNewTab: value,
                    })
                  }
                />
              </FormData>
            </FormTr>
            <tr>
              <td colSpan={2} className={styles["align-right"]}>
                {state.url ? (
                  <div>
                    <a href={state.url} target="_blank">
                      Link testen
                    </a>
                  </div>
                ) : (
                  <div className={styles["link-disabled"]}>Link testen</div>
                )}
              </td>
            </tr>
          </FormTable>
          <div className={styles.buttons}>
            <Button onclick={() => validate(state) && onAddOrUpdate(state)}>OK</Button>
            <div>&nbsp;</div>
            <Button onclick={onCancel}>Abbrechen</Button>
          </div>
        </DialogBorder>
      </DialogContainer>
    </Draggable>
  );
};

export default NewEditMenuItem;

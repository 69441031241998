import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { PartnerForm } from "./types";

const NewEditTable = ({
  state,
  setState,
  errors,
}: {
  state: PartnerForm;
  setState: React.Dispatch<React.SetStateAction<PartnerForm | undefined>>;
  errors: ValidationErrors;
}) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
            autoFocus
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.street.title}</FormLabel>
        <FormData>
          <Input
            value={state.street}
            onChange={(value) =>
              setState({
                ...state,
                street: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.zip.title}</FormLabel>
        <FormData>
          <Input
            value={state.zip}
            onChange={(value) =>
              setState({
                ...state,
                zip: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.city.title}</FormLabel>
        <FormData>
          <Input
            value={state.city}
            onChange={(value) =>
              setState({
                ...state,
                city: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.province.title}</FormLabel>
        <FormData>
          <Input
            value={state.province}
            onChange={(value) =>
              setState({
                ...state,
                province: value,
              })
            }
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Errors, { getString } from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { getCurrentUrlAsSource, getSelectedItems } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import * as Fields from "./Fields";
import { CommuneFull } from "./types";
import { newForm } from "./urls";
import { deleteCommunes, getCommuneFilters, getCommunes } from "./webapi";

const AllItems: FC = () => {
  const navigateTo = useNavigate();
  const [abortController, resetAbortController] = useAbortController();
  const listviewId = "communes-all-items";
  const fields: IListviewField<CommuneFull>[] = [Fields.name, Fields.code, Fields.province];

  const statusBar = useStatusBarState();
  const [communes, setCommunes] = useState<SearchResult<CommuneFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  useEffect(() => {
    loadCommunes(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadCommunes = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Communes.InternalName,
      Administration.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getCommunes(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setCommunes(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  const newAction: IAction = {
    title: strings.new,
    icon: Add,

    onClick: () => navigateTo(`${newForm}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("create"),
  };

  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      const selectedItems = getSelectedItems(communes);
      if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
        const abortController = resetAbortController();
        const result = await deleteCommunes(
          selectedItems.map((x) => x.id),
          abortController.signal,
        );

        if (result.error) {
          statusBar.addError(getString(result.error));
        } else if (result.data) {
          const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

          if (s.length != selectedItems.length) {
            statusBar.addWarning(strings.notAllElementsDeleted);
          }
          if (s.length > 0) {
            await loadCommunes(pagination);
          }
        }
      }
    },
    disabled: getSelectedItems(communes).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const actions = [newAction, deleteAction];

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            actions={actions}
            viewProperties={{
              schema: Administration.InternalName,
              list: Administration.Lists.Communes.InternalName,
              name: "all_items",
              setListAndPage: true,
            }}
            fields={fields}
            reload={loadCommunes}
            searchResult={communes}
            allowSelectRows
            getFilters={getCommuneFilters}
            pagination={pagination}
            updateItems={(items) =>
              communes &&
              setCommunes({
                ...communes,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default AllItems;

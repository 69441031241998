import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { EditorState, LexicalEditor } from "lexical";
import { useLayoutEffect } from "react";

export function OnChangePlugin({
  ignoreHistoryMergeTagChange = false,
  ignoreInitialChange = true,
  ignoreSelectionChange = false,
  onChange,
}: {
  ignoreHistoryMergeTagChange?: boolean;
  ignoreInitialChange?: boolean;
  ignoreSelectionChange?: boolean;
  onChange: (editorState: EditorState, editor: LexicalEditor) => void;
}): null {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return editor.registerUpdateListener(({ editorState, dirtyElements, dirtyLeaves, prevEditorState, tags }) => {
      if (
        (ignoreSelectionChange && dirtyElements.size === 0 && dirtyLeaves.size === 0) ||
        (ignoreHistoryMergeTagChange && tags.has("history-merge"))
      ) {
        return;
      }

      if (ignoreInitialChange && prevEditorState.isEmpty()) {
        return;
      }

      editor.update(() => {
        onChange(editorState, editor);
      });
    });
  }, [editor, ignoreHistoryMergeTagChange, ignoreInitialChange, ignoreSelectionChange, onChange]);

  return null;
}

import { FC } from "react";
import styles from "./FormError.module.css";

interface FormErrorProps {
  error?: string;
}

const FormError: FC<FormErrorProps> = ({ error }: FormErrorProps) => (
  <>{error && <div className={styles.error}>{error}</div>}</>
);

export default FormError;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Conventions } from "../../../listsSettings/conventions";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  conventions,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { PartnerFull, PartnerPost } from "./types";

const partnersUrl: string = `${conventions}/${Conventions.Lists.Partners.InternalName}`;
export const insertPartner = async (partner: PartnerPost, signal: AbortSignal): Promise<Result<PartnerFull | null>> =>
  insertItem(partnersUrl, partner, signal);

export const updatePartner = async (
  partnerId: number,
  partner: PartnerPost,
  signal: AbortSignal,
): Promise<Result<PartnerFull | null>> => updateItem(`${partnersUrl}/${partnerId}`, partner, signal);

export const deletePartner = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${partnersUrl}/${id}`, signal);

export const deletePartners = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(partnersUrl, ids, signal);

export const getPartner = async (id: number, signal: AbortSignal): Promise<Result<PartnerFull | null>> =>
  getItem<PartnerFull>(`${partnersUrl}/${id}`, signal);

export const getPartners = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<PartnerFull> | null>> => getItems<PartnerFull>(partnersUrl, pagination, signal);

export const getPartnerFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(partnersUrl, fieldName, signal);

export const searchPartners = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<PartnerFull> | null>> => searchItems<PartnerFull>(partnersUrl, searchValue, signal);

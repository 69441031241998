import { FC, useState } from "react";
import SelectBox, { SelectBoxProps } from "./SelectBox";

const languages = ["de", "it", "en"] as const;
type Language = (typeof languages)[number];

interface LanguageOption {
  id: Language;
  display: string;
}

const allLanguages: LanguageOption[] = [
  {
    id: "de",
    display: "Deutsch",
  },
  {
    id: "it",
    display: "Italienisch",
  },
  {
    id: "en",
    display: "Englisch",
  },
];

const allLanguagesMap = allLanguages.reduce((rv: { [key: string]: LanguageOption }, x) => {
  rv[x.id] = x;
  return rv;
}, {});

export const formatLanguage = (language?: string | null): string | null | undefined =>
  language ? allLanguagesMap[language]?.display : language;

interface LanguageSelectBoxProps {
  value: string | null;
  allowEmpty?: boolean;
  onChange: (x: string | null) => void;
  languagesToShow: Language[];
}

const LanguageSelectBox: FC<LanguageSelectBoxProps> = (props: LanguageSelectBoxProps) => {
  const [showOhne] = useState(props.allowEmpty || !props.value);

  const options: LanguageOption[] = allLanguages
    .filter((x) => props.languagesToShow.indexOf(x.id) >= 0)
    .sort((a, b) => props.languagesToShow.indexOf(a.id) - props.languagesToShow.indexOf(b.id));
  const optionsMap = options.reduce((rv: { [key: string]: LanguageOption }, x) => {
    rv[x.id] = x;
    return rv;
  }, {});

  const selectBoxProps: SelectBoxProps<LanguageOption> = {
    options: options,
    value: props.value ? optionsMap[props.value] : null,
    onChange: (x: LanguageOption | null) => props.onChange(x?.id ?? null),
    getId: (x: LanguageOption) => x.id,
    getValue: (x: LanguageOption) => x.display,
    allowEmpty: showOhne,
  };

  return <SelectBox {...selectBoxProps}></SelectBox>;
};

export default LanguageSelectBox;

import { FC } from "react";
import CheckBox from "../../../components/forms/CheckBox";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import NumberInput from "../../../components/forms/NumberInput";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { RoleForm } from "./types";

interface NewEditTableProps {
  state: RoleForm;
  setState: React.Dispatch<React.SetStateAction<RoleForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.forDistricts.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.forDistricts}
            onChange={(value) =>
              setState({
                ...state,
                forDistricts: value,
              })
            }
          />
          <FormError error={errors[Fields.forDistricts.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.forAreas.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.forAreas}
            onChange={(value) =>
              setState({
                ...state,
                forAreas: value,
              })
            }
          />
          <FormError error={errors[Fields.forAreas.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.forChapters.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.forChapters}
            onChange={(value) =>
              setState({
                ...state,
                forChapters: value,
              })
            }
          />
          <FormError error={errors[Fields.forChapters.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.order.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.order}
            onChange={(value) =>
              setState({
                ...state,
                order: value,
              })
            }
          />
          <FormError error={errors[Fields.order.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

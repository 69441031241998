import classNames from "classnames";
import { FC, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Errors from "../../components/errors/Errors";
import useGlobalState from "../../hooks/useGlobalState";
import { Functionaries } from "../../listsSettings/functionaries";
import { ErrorCode } from "../../services/types";
import { getCurrentUrlAsSource } from "../../services/utils";
import { getUserPermissions } from "../../services/webapi";
import { AreaFull } from "./areas/types";
import styles from "./Default.module.css";
import { DistrictFull } from "./districts/types";
import { FunctionaryFull } from "./functionaries/types";
import { dispForm } from "./functionaries/urls";
import { getOrganizationChart } from "./functionaries/webapi";

const Default: FC = () => {
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [chapters, setChapters] = useState<number>();
  const [members, setMembers] = useState<number>();
  const [president, setPresident] = useState<FunctionaryFull>();
  const [countryCommittee, setCountryCommittee] = useState<FunctionaryFull[]>();
  const [districts, setDistricts] = useState<[DistrictFull, FunctionaryFull][]>();
  const [areas, setAreas] = useState<{
    depth: number;
    items: {
      [key: string]: {
        area: AreaFull;
        functionaries: FunctionaryFull[];
      }[];
    };
  }>();

  const setListName = useGlobalState((state) => state.setListName);
  const setPage = useGlobalState((state) => state.setPage);

  useEffect(() => {
    const abortController = new AbortController();

    setListName(null);
    setPage(null);

    (async () => {
      const permissions = await getUserPermissions(
        Functionaries.Lists.Functionaries.InternalName,
        Functionaries.InternalName,
        abortController.signal,
      );
      if (!abortController.signal.aborted) {
        const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
        //!canRead && setErrorCode(403);
        if (canRead) {
          const result = await getOrganizationChart(abortController.signal);
          if (!abortController.signal.aborted) {
            result.error && setErrorCode(result.error.code);
            if (result.data) {
              result.data.president && setPresident(result.data.president);
              setCountryCommittee(result.data.countryCommittee);
              setChapters(result.data.chapters);
              setMembers(result.data.members);

              const districts = result.data.districtCommittee.reduce(
                (rv: { [key: string]: [DistrictFull, FunctionaryFull] }, x: FunctionaryFull) => {
                  if (x.district?.id && !rv[x.district.id]) {
                    rv[x.district.id] = [x.district, x];
                  }
                  return rv;
                },
                {},
              );

              const ds = Object.keys(districts)
                .reduce((rv: [DistrictFull, FunctionaryFull][], x: string) => {
                  const d = districts[x];
                  if (d) {
                    return [...rv, d];
                  }
                  return rv;
                }, [])
                .sort(([a, _], [b, __]) => a.order - b.order);
              setDistricts(ds);

              const valuesMap = result.data.areaCommittee.reduce(
                (
                  rv: { [key: string]: { area: AreaFull; district: DistrictFull; functionaries: FunctionaryFull[] } },
                  x: FunctionaryFull,
                ) => {
                  if (x.area?.id && x.district?.id) {
                    if (!rv[x.area.id]) {
                      rv[x.area.id] = {
                        area: x.area,
                        district: x.district,
                        functionaries: [],
                      };
                    }
                    rv[x.area.id]?.functionaries.push(x);
                  }
                  return rv;
                },
                {},
              );

              const areasMap = Object.keys(valuesMap).reduce(
                (
                  rv: {
                    [key: string]: {
                      area: AreaFull;
                      functionaries: FunctionaryFull[];
                    }[];
                  },
                  key: string,
                ) => {
                  const value = valuesMap[key];
                  if (value) {
                    if (!rv[value.district.id]) {
                      rv[value.district.id] = [];
                    }
                    rv[value.district.id]?.push(value);
                    rv[value.district.id]?.sort((a, b) => a.area.order - b.area.order);
                  }

                  return rv;
                },
                {},
              );

              const maxDepth = Object.keys(areasMap).reduce((depth, x) => {
                return Math.max(depth, areasMap[x]?.length ?? 0);
              }, 0);

              setAreas({
                depth: maxDepth,
                items: areasMap,
              });
            }
          }
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  const arrayChunks = (arr: FunctionaryFull[], size: number) => {
    var arr2 = arr.slice(0),
      arrays = [];

    while (arr2.length > 0) {
      arrays.push(arr2.splice(0, size));
    }

    return arrays;
  };

  const sortByName = (array: FunctionaryFull[]) =>
    array.sort((a, b) =>
      `${a.person?.firstName} ${a.person?.lastName}`.localeCompare(`${b.person?.firstName} ${b.person?.lastName}`),
    );

  return (
    <>
      {members && (
        <div>
          <table>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <div className={classNames(styles.box, styles.box3, styles.yellow, styles.bold)}>Präsident/in</div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div className={classNames(styles.box, styles.box3, styles["gray-light"], styles.bold)}>
                    {president && (
                      <Link to={`${dispForm}/${president.id}?${getCurrentUrlAsSource()}`}>
                        {president.person?.firstName} {president.person?.lastName}
                      </Link>
                    )}
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <div className={classNames(styles.box, styles.yellow, styles.bold)}>Landesausschuss</div>
                </td>
              </tr>
              {countryCommittee &&
                arrayChunks(sortByName(countryCommittee), 3).map((x, index) => (
                  <tr key={index}>
                    {x.map((y) => (
                      <td key={y.id}>
                        <div className={styles["box-container"]}>
                          <div className={classNames(styles.box, styles.box3, styles["gray-light"], styles.bold)}>
                            <Link to={`${dispForm}/${y.id}?${getCurrentUrlAsSource()}`}>
                              {y.person?.firstName} {y.person?.lastName}
                            </Link>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          <div>&nbsp;</div>
          <table>
            <tbody>
              <tr>
                <td colSpan={districts?.length}>
                  <div className={classNames(styles.box, styles.yellow, styles.bold)}>Bezirk</div>
                </td>
              </tr>
              <tr>
                {districts?.map(([district, _]) => (
                  <td key={district.id}>
                    <div className={classNames(styles.box, styles.box4, styles["gray-dark"], styles.bold)}>
                      {district.name}
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                {districts?.map(([x, functionary]) => (
                  <td key={x.id}>
                    <div className={classNames(styles.box, styles.box4, styles["gray-light"])}>
                      <div className={styles.bold}>
                        <Link to={`${dispForm}/${x.id}?${getCurrentUrlAsSource()}`}>
                          {functionary.person?.firstName} {functionary.person?.lastName}
                        </Link>
                      </div>
                      <div>{functionary.role?.name}</div>
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className={classNames(styles.box, styles.gray, styles.bold)}>Gebiet</div>
                </td>
              </tr>
              {[...Array(areas?.depth).keys()].map((_, index) => {
                return (
                  <Fragment key={`areas_${index}`}>
                    <tr>
                      {districts?.map(([district, _], areaNameIndex) => {
                        const item = areas?.items[district.id]?.[index];
                        return (
                          <Fragment key={`areas_${index}_${areaNameIndex}`}>
                            <td valign="top">
                              {item && (
                                <div className={classNames(styles.box, styles.box4, styles.gray, styles.bold)}>
                                  {item.area?.name}
                                </div>
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                    <tr>
                      {districts?.map(([district, _], areaFunctionaryIndex) => {
                        const item = areas?.items[district.id]?.[index];
                        return (
                          <Fragment key={`areas_${index}_${areaFunctionaryIndex}`}>
                            <td valign="top">
                              {item?.functionaries.map((y) => (
                                <Fragment key={`functionary_${y.id}`}>
                                  <div className={classNames(styles.box, styles.box4, styles["gray-light"])}>
                                    <div className={styles.bold}>
                                      <Link to={`${dispForm}/${y.id}?${getCurrentUrlAsSource()}`}>
                                        {y.person?.firstName} {y.person?.lastName}
                                      </Link>
                                    </div>
                                    <div>{y.role?.name}</div>
                                  </div>
                                </Fragment>
                              ))}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>
                  <div
                    className={classNames(styles.box, styles.box3, styles.yellow, styles["auto-width"], styles.bold)}
                  >
                    {chapters} ORTSGRUPPEN
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className={classNames(styles.box, styles["gray-light"], styles.bold)}>
                    ORTSOBMANN/-FRAU, ORTSAUSSCHUSS, ORTSVERSAMMLUNG
                  </div>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>
                  <div
                    className={classNames(styles.box, styles.box3, styles.yellow, styles["auto-width"], styles.bold)}
                  >
                    {members} MITGLIEDER
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default Default;

const Chapters = {
  InternalName: "chapters",
  Title: "Ortsgruppen",
};
const LegalRepresentatives = {
  InternalName: "legal_representatives",
  Title: "Gesetzliche Vertreter",
};
const AccountingItems = {
  InternalName: "accounting_items",
  Title: "Posten",
};
const LegalSeats = {
  InternalName: "legal_seats",
  Title: "Rechtsitze",
};
const Bonuses = {
  InternalName: "bonuses",
  Title: "Mitgliedsboni",
};
const Companies = {
  InternalName: "companies",
  Title: "Mitgliedsbetriebe",
};
const Fees = {
  InternalName: "fees",
  Title: "Mitgliedsbeiträge",
};
const Members_ = {
  InternalName: "members",
  Title: "Mitglieder",
};

export const Members = {
  InternalName: "members",
  Title: "Mitglieder",
  Lists: {
    AccountingItems: AccountingItems,
    Chapters: Chapters,
    LegalRepresentatives: LegalRepresentatives,
    LegalSeats: LegalSeats,
    Bonuses: Bonuses,
    Companies: Companies,
    Fees: Fees,
    Members: Members_,
  },
};

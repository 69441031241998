import { FC } from "react";
import { camalize } from "../../services/utils";
import styles from "./CheckBox.module.css";

interface CheckBoxProps {
  value: boolean;
  label?: string;
  onChange: (x: boolean) => void;
  disabled?: boolean;
}

const CheckBox: FC<CheckBoxProps> = (props: CheckBoxProps) => {
  const id = camalize(props.label ?? "");

  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        defaultChecked={props.value}
        onChange={(event) => props.onChange(event.target.checked)}
        id={id}
        disabled={props.disabled}
      />
      {id && (
        <label className={styles.label} htmlFor={id}>
          {props.label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;

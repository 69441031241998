import Link from "../../../components/Link";
import Number from "../../../components/Number";
import { IDialogField, IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { ViewFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<ViewFull> & IDialogField<ViewFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: ViewFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: ViewFull) => item.name,
};

export const internalName: IListviewField<ViewFull> & IDialogField<ViewFull> = {
  title: "Interner-Name",
  fieldName: "internalName",
  isSortable: true,
  renderListValue: (item: ViewFull) => item.internalName,
  renderDialogValue: (item: ViewFull) => item.internalName,
};

export const showSearchBox: IListviewField<ViewFull> = {
  title: "Suchfeld anzeigen",
  fieldName: "showSearchBox",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ViewFull) => formatBoolean(item.showSearchBox),
};

export const itemsPerPage: IListviewField<ViewFull> = {
  title: "Elemente/Seite",
  fieldName: "itemsPerPage",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ViewFull) => <Number value={item.itemsPerPage} />,
};

export const list: IListviewField<ViewFull> & IDialogField<ViewFull> = {
  title: "Liste",
  fieldName: "list.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ViewFull) => item?.list?.name,
  renderDialogValue: (item: ViewFull) => item?.list?.name,
};

export const schema: IListviewField<ViewFull> = {
  title: "Schema",
  fieldName: "list.schema.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ViewFull) => item?.list?.schema?.name,
};

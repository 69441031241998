import { FC, PropsWithChildren, useEffect } from "react";
import useGlobalState from "../hooks/useGlobalState";
import useStatusBarState from "../hooks/useStatusBar";
import { getCurrentUserInfo } from "../services/webapi";
import { getString } from "./errors/Errors";
import styles from "./MainLayout.module.css";
import CurrentNavigation from "./menus/current/CurrentNavigation";
import { GlobalNavigation } from "./menus/global/GlobalNavigation";

interface MainLayoutProps {
  hideCurrentNavigation?: boolean;
}

const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  children,
  hideCurrentNavigation,
}: PropsWithChildren<MainLayoutProps>) => {
  const statusBar = useStatusBarState();
  const setIsUserAdmin = useGlobalState((state) => state.setIsUserAdmin);

  useEffect(() => {
    const ac = new AbortController();
    (async () => {
      const result = await getCurrentUserInfo(ac.signal);
      result.error && statusBar.addError(getString(result.error));
      result.data && setIsUserAdmin(result.data.isAdmin);
    })();

    return () => ac.abort();
  }, []);

  const itemsGlobalMenu = useGlobalState((state) => state.itemsGlobalMenu);
  const schema = useGlobalState((state) => state.schema);
  const listName = useGlobalState((state) => state.listName);
  const page = useGlobalState((state) => state.page);

  useEffect(() => {
    statusBar.clear();
  }, [schema.item?.id.toString(), listName, page]);

  const title = listName ?? schema.item?.name ?? null;
  document.title = (title ? `${title} - ` : "") + "HGV Portal";

  return (
    <>
      {itemsGlobalMenu && (
        <>
          <div className={styles.scrollable}>
            <GlobalNavigation />
            <div className={styles.row}>
              {!hideCurrentNavigation ? (
                <>
                  <CurrentNavigation />
                  <div className={styles.content}>{children}</div>
                </>
              ) : (
                children
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MainLayout;

import { FC, ReactNode } from "react";
import { CustomError } from "../components/errors/Errors";
import { UserLight } from "../home/administration/users/types";

export const weekDaysShort = ["Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa.", "So."];
export const weekDaysLong = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

const remoteState = <const>["loading", "failed", "success", "canceled"];
export type RemoteState = (typeof remoteState)[number];

const permission = <const>["create", "read-all", "edit-all", "read-own", "edit-own", "delete-all", "delete-own"];
type Permission = (typeof permission)[number];

const errorCodes = <const>[400, 403, 404, 409, 422, 500];
export type ErrorCode = (typeof errorCodes)[number];

const errorTypes = <const>["missing_value", "invalid_value", "duplicate_value", "reference_not_found"];
export type ErrorType = (typeof errorTypes)[number];

export type CreationInformation = {
  author?: UserLight;
  createdAt?: string;
  editor?: UserLight;
  updatedAt?: string;
};

export interface UserPermissions {
  schemaPermission: boolean;
  listPermissions: Permission[];
}

export interface ValidationErrors {
  [key: string]: string | undefined;
}

export type Item = {
  selected?: boolean;
};

export type CreationInformationItem = Item & CreationInformation;

export type FileItem = CreationInformationItem & {
  url: string;
  filename: string;
  extension: string | null;
};

export type SearchResult<T extends Item> = {
  total: number;
  items: T[];
};

export interface MenuItem {
  id?: number;
  title: string;
  url: string | null;
  openNewTab: boolean;
  order: number;
  children?: MenuItem[];
  deleted?: boolean;
}

export interface ListviewParameters {
  schema?: string | null;
  list: string;
  view: string;
}

interface ViewOptions {
  name: string;
  internalName: string;
  showSearchBox: string;
  showTitle: string;
  itemsPerPage: string;
  views?: ViewOptions[] | null;
}

export interface ListviewOptions {
  schema: {
    name: string;
    internalName: string;
  };
  list: {
    name: string;
    internalName: string;
  };
  view: ViewOptions;
}

export type MenuOptionValue = string | number | boolean | null;

export interface IListviewField<T extends Item> {
  fieldName: string;
  title: string;
  isSortable?: boolean;
  isFilterable?: boolean;
  alignRight?: boolean;
  renderListValue?: (item: T) => ReactNode;
  renderMenuOptionValue?: (value: MenuOptionValue) => string;
}

export interface IDialogField<V> {
  fieldName: string;
  title: string;
  renderDialogValue: (item: V) => ReactNode;
}

export interface IAction {
  title: string;
  icon: FC;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
}

export interface FileInfo {
  data: string;
  contentType: string;
}

export interface UserInfo {
  isAdmin: boolean;
}

export interface Result<T> {
  data: T | null;
  error: CustomError | null;
}

export interface PostPolicy {
  objectId: string;
  url: string;
  fields: {
    [key: string]: string;
  };
}

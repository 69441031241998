import { TextFormatType } from "lexical/nodes/LexicalTextNode";

const blockTypes = <const>["paragraph", "h1", "h2", "h3", "ul", "ol", "quote", "code"];
export type BlockType = (typeof blockTypes)[number];
export const isBlockType = (tag: string): tag is BlockType => blockTypes.includes(tag as BlockType);

export const BlockTypeMap = new Map<BlockType, string>([
  ["paragraph", "Normal"],
  ["h1", "Große Überschrift"],
  ["h2", "Mittlere Überschrift"],
  ["h3", "Kleine Überschrift"],
  ["ul", "Ungeordnete Liste"],
  ["ol", "Geordnete Liste"],
  ["quote", "Zitat"],
  ["code", "Code Block"],
]);

const textFormattingTypes = <const>["link", "clear"];
export type TextFormatButtonType = (typeof textFormattingTypes)[number] | TextFormatType;

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ConfiguredMiddleware } from "wretch";
import { msalApp } from "./auth";

type AuthOptions = {
  scopes: string[];
};

type AuthMiddleware = (options?: AuthOptions) => ConfiguredMiddleware;

export const authMiddleware: AuthMiddleware =
  ({ scopes } = { scopes: [] }) =>
  (next) =>
  (url, opts) => {
    const account = msalApp.getAllAccounts()[0];
    if (!account) {
      const msg = "No authenticated user account was found.";
      console.error(msg);
      throw Error(msg);
    }

    return msalApp
      .acquireTokenSilent({
        scopes,
        account: account,
      })
      .then((res) => {
        return next(url, {
          ...opts,
          headers: {
            ...opts["headers"],
            Authorization: `Bearer ${res.accessToken}`,
          },
        });
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          msalApp.loginRedirect();
        }
        return Promise.reject(error);
      });
  };

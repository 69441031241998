import { isValid } from "date-fns";
import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { ChapterEventCategoryFull } from "../chapterEventsCategories/types";
import { ChapterFull } from "../chapters/types";
import { DistrictFull } from "../districts/types";
import * as Fields from "./Fields";

export interface ChapterEventFull extends CreationInformationItem {
  id: number;
  title: string;
  description: string | null;
  location: string | null;
  from: string;
  to: string;
  visibleOnHgvIt: boolean;
  visibleOnChaptersIt: boolean;
  signUpLink: string | null;
  chapter: ChapterFull | null;
  district: DistrictFull | null;
  category: ChapterEventCategoryFull;
}

export interface ChapterEventForm {
  title: string | null;
  description: string | null;
  location: string | null;
  date: string | null;
  from: number | null;
  to: number | null;
  visibleOnHgvIt: boolean;
  visibleOnChaptersIt: boolean;
  signUpLink: string | null;
  chapter: ChapterFull | null;
  district: DistrictFull | null;
  category: ChapterEventCategoryFull | null;
}

export interface ChapterEventPost {
  title: string;
  description: string | null;
  location: string | null;
  from: string | null;
  to: string | null;
  visibleOnHgvIt: boolean;
  visibleOnChaptersIt: boolean;
  signUpLink: string | null;
  chapterId: number | null;
  districtId: number | null;
  categoryId: number;
}

export const mapToPOST = (item: ChapterEventForm): ChapterEventPost => {
  const formatDate = (date: string | null, time: number | null): string => {
    if (!date) {
      return "";
    }
    const t = time ?? 0;
    const h = Math.floor(t / 60);
    const m = t - h * 60;
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), h, m).toISOString();
  };

  return {
    title: item.title || "",
    description: item.description,
    location: item.location,
    from: formatDate(item.date, item.from),
    to: formatDate(item.date, item.to),
    visibleOnHgvIt: item.visibleOnHgvIt,
    visibleOnChaptersIt: item.visibleOnChaptersIt,
    signUpLink: item.signUpLink,
    chapterId: item.chapter?.id || null,
    districtId: item.district?.id || null,
    categoryId: item.category?.id || 0,
  };
};

export const getEventFrom = (item: ChapterEventFull) => {
  const date = new Date(item.from);
  return isValid(date) ? date.getHours() * 60 + date.getMinutes() : null;
};

export const getEventTo = (item: ChapterEventFull) => {
  const date = new Date(item.to);
  return isValid(date) ? date.getHours() * 60 + date.getMinutes() : null;
};

export const validate = (
  state: ChapterEventForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.title) {
    errors[Fields.title.fieldName] = strings.required;
  }
  if (!state.category) {
    errors[Fields.category.fieldName] = strings.required;
  }
  if (!state.date) {
    errors[Fields.date.fieldName] = strings.required;
  }
  if (!state.from && state.from !== 0) {
    errors[Fields.from.fieldName] = strings.required;
  }
  if (!state.to && state.to !== 0) {
    errors[Fields.to.fieldName] = strings.required;
  }
  if (
    (state.from || state.from === 0) &&
    (state.to || state.to === 0) &&
    (state.from >= state.to || state.from < 0 || state.from >= 1440 || state.to <= 0 || state.to > 1440)
  ) {
    errors[Fields.to.fieldName] = "Es muss eine gültige Zeitspanne (0-24) angegeben werden.";
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  putReference,
  removeReferencedItems,
  searchItems,
  updateItem,
  updateWithoutResult,
} from "../../../services/webapi";
import { GroupFull } from "../groups/types";
import { UserFull } from "../users/types";
import { CurrentNavigationItemFull, CurrentNavigationItemPost } from "./types";

const currentNavigationItemsUrl: string = `${administration}/${Administration.Lists.CurrentNavigationItems.InternalName}`;
export const insertCurrentNavigationItem = async (
  currentNavigationItem: CurrentNavigationItemPost,
  signal: AbortSignal,
): Promise<Result<CurrentNavigationItemFull | null>> =>
  insertItem(currentNavigationItemsUrl, currentNavigationItem, signal);

export const updateCurrentNavigationItem = async (
  currentNavigationItemId: number,
  currentNavigationItem: CurrentNavigationItemPost,
  signal: AbortSignal,
): Promise<Result<CurrentNavigationItemFull | null>> =>
  updateItem(`${currentNavigationItemsUrl}/${currentNavigationItemId}`, currentNavigationItem, signal);

export const upsertCurrentMenuItems = async (
  schemaId: number | null,
  currentNavigationItems: CurrentNavigationItemPost[],
  signal: AbortSignal,
): Promise<CustomError | null> => {
  const url = schemaId ? `${currentNavigationItemsUrl}?schema_id=${schemaId}` : currentNavigationItemsUrl;
  return await updateWithoutResult<CurrentNavigationItemPost[]>(url, currentNavigationItems, signal);
};

export const deleteCurrentNavigationItem = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${currentNavigationItemsUrl}/${id}`, signal);

export const deleteCurrentNavigationItems = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(currentNavigationItemsUrl, ids, signal);

export const getCurrentNavigationItem = async (
  id: number,
  signal: AbortSignal,
): Promise<Result<CurrentNavigationItemFull | null>> =>
  getItem<CurrentNavigationItemFull>(`${currentNavigationItemsUrl}/${id}`, signal);

export const getCurrentNavigationItems = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<CurrentNavigationItemFull> | null>> =>
  getItems<CurrentNavigationItemFull>(currentNavigationItemsUrl, pagination, signal);

export const getCurrentNavigationItemFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(currentNavigationItemsUrl, fieldName, signal);

export const searchCurrentNavigationItems = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<CurrentNavigationItemFull> | null>> =>
  searchItems<CurrentNavigationItemFull>(currentNavigationItemsUrl, searchValue, signal);

export const getCurrentNavigationItemUsers = async (
  currentNavigationItemId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> =>
  getItems<UserFull>(`${currentNavigationItemsUrl}/${currentNavigationItemId}/users`, pagination, signal);

export const getCurrentNavigationItemUsersFilters = (
  currentNavigationItemId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${currentNavigationItemsUrl}/${currentNavigationItemId}/users`, fieldname, signal);

export const getCurrentNavigationItemGroups = async (
  currentNavigationItemId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<GroupFull> | null>> =>
  getItems<GroupFull>(`${currentNavigationItemsUrl}/${currentNavigationItemId}/groups`, pagination, signal);

export const getCurrentNavigationItemGroupsFilters = (
  currentNavigationItemId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${currentNavigationItemsUrl}/${currentNavigationItemId}/groups`, fieldname, signal);

export const addUser = (
  currentNavigationItem: CurrentNavigationItemFull,
  user: UserFull,
  signal: AbortSignal,
): Promise<CustomError | null> => {
  const url = `${currentNavigationItemsUrl}/${currentNavigationItem.id}/user/${user.id}`;
  return putReference(url, signal);
};

export const addGroup = (
  currentNavigationItem: CurrentNavigationItemFull,
  group: GroupFull,
  signal: AbortSignal,
): Promise<CustomError | null> => {
  const url = `${currentNavigationItemsUrl}/${currentNavigationItem.id}/group/${group.id}`;
  return putReference(url, signal);
};

export const removeGroups = (
  currentNavigationItem: CurrentNavigationItemFull,
  groups: GroupFull[],
  signal: AbortSignal,
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${currentNavigationItemsUrl}/${currentNavigationItem.id}/groups`;
  return removeReferencedItems(
    url,
    groups.map((x) => x.id),
    signal,
  );
};

export const removeUsers = (
  currentNavigationItem: CurrentNavigationItemFull,
  users: UserFull[],
  signal: AbortSignal,
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${currentNavigationItemsUrl}/${currentNavigationItem.id}/users`;
  return removeReferencedItems(
    url,
    users.map((x) => x.id),
    signal,
  );
};

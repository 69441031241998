import { isValid, parse, parseISO } from "date-fns";
import { FC, useState } from "react";
import { dateToString, formatDateFromISOString } from "../../services/utils";
import Calendar from "../../svg/Calendar.svg?react";
import DatePicker from "../datePicker/DatePicker";
import styles from "./DatePickerWithInput.module.css";

interface DatePickerProps {
  value: string | null;
  onSelect: (date: string | null) => void;
}

const parseDateFromString = (value: string) => {
  const input = value.length > 10 ? value.substring(0, 10) : value;
  const date = parse(input, "dd.MM.yyyy", new Date());
  return date;
};

const DatePickerWithInput: FC<DatePickerProps> = ({ value, onSelect }: DatePickerProps) => {
  const initialValue = value && value.length >= 10 ? parseISO(value) : null;
  const inputValue = initialValue && isValid(initialValue) ? formatDateFromISOString(value) : (value ?? undefined);

  const [inputState, setInputState] = useState<string>(inputValue ?? "");
  const [show, setShow] = useState(false);

  const blur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.length > 10 ? event.target.value.substring(0, 10) : event.target.value;
    const date = parseDateFromString(newValue);
    const valid = isValid(date);
    onSelect(valid ? dateToString(date) : newValue);
    setInputState(newValue);
  };

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.length > 10 ? event.target.value.substring(0, 10) : event.target.value;
    onSelect(newValue);
    setInputState(newValue);
  };

  const select = (date: Date | null | undefined) => {
    const value = date ? dateToString(date) : null;
    setShow(false);
    onSelect(value);
    setInputState(formatDateFromISOString(value));
  };

  const openPicker = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    event.preventDefault();
    setShow(true);
  };

  const getValueForPicker = (): Date | undefined => {
    if (!value) {
      return;
    }

    const dateFromISO = parseISO(value);
    if (isValid(dateFromISO)) {
      return dateFromISO;
    }
    const date = parseDateFromString(value);
    return isValid(date) ? date : undefined;
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["input-container"]}>
        <div className={styles["input-inner-container"]}>
          <input type="text" value={inputState} className={styles.input} onBlur={blur} onChange={change} />
        </div>
        <div onClick={openPicker} className={styles["img-container"]}>
          <Calendar />
        </div>
      </div>
      <div className={styles["picker-container"]}>
        {show && <DatePicker value={getValueForPicker()} onSelect={select} onClose={() => setShow(false)} />}
      </div>
    </div>
  );
};

export default DatePickerWithInput;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Conventions } from "../../../listsSettings/conventions";
import { MenuOptionValue, PostPolicy, Result, SearchResult } from "../../../services/types";
import {
  conventions,
  createFileUploadUrl,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { AttachmentFull } from "../attachments/types";
import { ContactFull } from "../contacts/types";
import { ConventionFull, ConventionPost } from "./types";

const conventionsUrl: string = `${conventions}/${Conventions.Lists.Conventions.InternalName}`;
export const insertConvention = async (
  convention: ConventionPost,
  signal: AbortSignal,
): Promise<Result<ConventionFull | null>> => insertItem(conventionsUrl, convention, signal);

export const updateConvention = async (
  conventionId: number,
  convention: ConventionPost,
  signal: AbortSignal,
): Promise<Result<ConventionFull | null>> => {
  console.log(convention);
  return updateItem(`${conventionsUrl}/${conventionId}`, convention, signal);
};

export const deleteConvention = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${conventionsUrl}/${id}`, signal);

export const deleteConventions = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(conventionsUrl, ids, signal);

export const getConvention = async (id: number, signal: AbortSignal): Promise<Result<ConventionFull | null>> =>
  getItem<ConventionFull>(`${conventionsUrl}/${id}`, signal);

export const getConventions = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<ConventionFull> | null>> => getItems<ConventionFull>(conventionsUrl, pagination, signal);

export const getConventionFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(conventionsUrl, fieldName, signal);

export const getConventionContacts = async (
  conventionId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<ContactFull> | null>> =>
  getItems<ContactFull>(`${conventionsUrl}/${conventionId}/contacts`, pagination, signal);

export const getConventionContactsFilters = (
  conventionId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${conventionsUrl}/${conventionId}/contacts`, fieldname, signal);

export const getConventionAttachments = async (
  conventionId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<AttachmentFull> | null>> =>
  getItems<AttachmentFull>(`${conventionsUrl}/${conventionId}/attachments`, pagination, signal);

export const getConventionAttachmentsFilters = (
  conventionId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${conventionsUrl}/${conventionId}/attachments`, fieldname, signal);

export const createAttachmentsUploadUrl = (
  signal: AbortSignal,
  conventionId: number,
  filename: string,
): Promise<Result<PostPolicy | null>> =>
  createFileUploadUrl(`${conventionsUrl}/${conventionId}/attachments`, signal, {
    filename: filename,
  });

import { FC, useEffect, useState } from "react";
import Errors from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { UserFull } from "./types";
import { getUserFilters, getUsers } from "./webapi";

const AllItems: FC = () => {
  const listviewId = "users-all-items";
  const fields: IListviewField<UserFull>[] = [
    Fields.name,
    Fields.email,
    Fields.department,
    Fields.offices,
    Fields.accountEnabled,
  ];

  const [users, setUsers] = useState<SearchResult<UserFull> | null>(null);
  const [abortController, resetAbortController] = useAbortController();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  useEffect(() => {
    loadUsers(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadUsers = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Users.InternalName,
      Administration.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getUsers(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setUsers(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            viewProperties={{
              schema: Administration.InternalName,
              list: Administration.Lists.Users.InternalName,
              name: "all_items",
              setListAndPage: true,
            }}
            fields={fields}
            reload={loadUsers}
            searchResult={users}
            getFilters={getUserFilters}
            pagination={pagination}
            updateItems={(items) =>
              users &&
              setUsers({
                ...users,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default AllItems;

import { AsYouType, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { FC, useEffect, useState } from "react";
import styles from "./EditPhoneNumber.module.css";

export interface PhoneCountry {
  code: string;
  name: string;
  phoneCode: string;
}

interface EditPhoneNumberProps {
  countries: PhoneCountry[];
  defaultCountry?: string;
  value?: string | null;
  onChange: (x: string | null) => void;
}

const EditPhoneNumber: FC<EditPhoneNumberProps> = (props: EditPhoneNumberProps) => {
  const [country, setCountry] = useState<PhoneCountry>();

  const countriesMap = props.countries.reduce((rv: { [key: string]: PhoneCountry }, c) => {
    rv[c.code.toUpperCase()] = c;
    return rv;
  }, {});

  useEffect(() => {
    if (props.countries) {
      const c = countriesMap[props.defaultCountry ?? "IT"];
      setCountry(c);
    }
  }, [props.countries]);

  const onChange = (value: string | null) => {
    props.onChange(value?.replace(/\s/g, "") ?? null);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (country) {
      const c = countriesMap[event.target.value.toUpperCase()];
      if (c) {
        const newValue = props.value?.replace(country.phoneCode, c.phoneCode);
        onChange(newValue ?? null);
        setCountry(c);
      }
    }
  };

  const formatValue = (value: string | null | undefined, country: PhoneCountry): string | undefined => {
    if (!value || value.length < country.phoneCode.length) {
      return country.phoneCode;
    }

    if (isValidPhoneNumber(value)) {
      const parsedPhoneNumber = parsePhoneNumber(value);
      return parsedPhoneNumber.formatInternational();
    }
    return new AsYouType().input(value);
  };

  return (
    <>
      {country && (
        <div className={styles.container}>
          <div className={styles.inner}>
            <select className={styles["country-select"]} onChange={onSelectChange}>
              {props.countries.map((c) => (
                <option key={c.code} value={c.code}>
                  {c.name}
                </option>
              ))}
            </select>
            <div className={styles["country-icon"]}>
              {country && (
                <img
                  className={styles["country-icon-img"]}
                  alt={country.name}
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`}
                />
              )}
            </div>
            <div className={styles.arrow}></div>
          </div>
          <input
            type="tel"
            className={styles.input}
            value={formatValue(props.value, country)}
            onChange={onInputChange}
          />
        </div>
      )}
    </>
  );
};

export default EditPhoneNumber;

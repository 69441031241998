import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import FileUpload from "../../../components/forms/FileUpload";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import SelectBox from "../../../components/forms/SelectBox";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Conventions } from "../../../listsSettings/conventions";
import { ErrorCode, IAction, ValidationErrors } from "../../../services/types";
import { getCancelAction, getEncodedSourceFromUrl, getNumberFromUrl, getSaveAction } from "../../../services/utils";
import { getUserPermissions, uploadFile } from "../../../services/webapi";
import strings from "../../../strings";
import { ConventionFull } from "../conventions/types";
import { createAttachmentsUploadUrl, getConvention, getConventions } from "../conventions/webapi";
import * as Fields from "./Fields";
import { AttachmentForm, mapToPOST, validate } from "./types";
import { allItems, dispForm } from "./urls";
import { insertAttachment } from "./webapi";

import { UserPermissions } from "../../../services/types";

const NewForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const statusBar = useStatusBarState();
  const [state, setState] = useState<AttachmentForm | undefined>({
    displayName: null,
    filename: null,
    convention: null,
  });
  const [selectedFile, setSelectedFile] = useState<File>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [conventions, setConventions] = useState<ConventionFull[]>([]);

  const navigateTo = useNavigate();

  useEffect(() => {
    (async () => {
      const abortController = resetAbortController();
      const permissions = await getUserPermissions(
        Conventions.Lists.Attachments.InternalName,
        Conventions.InternalName,
        abortController.signal,
      );
      if (!abortController.signal.aborted) {
        setPermissions(permissions);
        const canCreate = permissions.schemaPermission && permissions.listPermissions.includes("create");

        if (canCreate) {
          const conventionsResult = await getConventions({}, abortController.signal);
          conventionsResult.data && setConventions(conventionsResult.data.items);

          const conventionId = getNumberFromUrl("conventionId");
          if (conventionId) {
            const result = await getConvention(conventionId, abortController.signal);
            if (result.error) {
              console.error(result.error);
              statusBar.addError(strings.genericText);
            }
            if (result.data) {
              setState((prevstate) => {
                return {
                  displayName: prevstate?.displayName ?? null,
                  filename: prevstate?.filename ?? null,
                  convention: result.data ?? null,
                };
              });
            }
          }
        } else {
          setErrorCode(403);
        }
      }
    })();

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const save = async () => {
    if (state && validate(state, setErrors) && state?.convention?.id && state?.filename && selectedFile) {
      const abortController = resetAbortController();
      const policyResult = await createAttachmentsUploadUrl(
        abortController.signal,
        state.convention.id,
        state.filename,
      );

      if (policyResult.error) {
        statusBar.addError(getString(policyResult.error));
        return;
      }
      if (policyResult.data) {
        const uploadResult = await uploadFile(policyResult.data, abortController.signal, selectedFile);

        if (uploadResult.error) {
          statusBar.addError(getString(uploadResult.error));
          return;
        }
        if (uploadResult.data) {
          const result = await insertAttachment(mapToPOST(state), abortController.signal);

          if (result.error) {
            if (result.error.code == 409 || result.error.code == 422) {
              const newErrors = result.error.details?.reduce((rv: ValidationErrors, d) => {
                rv[d.field] = strings.getMessageFromTypeError(d.type);
                return rv;
              }, {});

              newErrors && setErrors(newErrors);
            } else {
              statusBar.addError(getString(result.error));
            }
          }
          result.data && navigateTo(`${dispForm}/${result.data.id}?${getEncodedSourceFromUrl()}`);
        }
      }
    }
  };

  const actions: IAction[] = [getSaveAction(save), getCancelAction(navigateTo, allItems)];

  useEffect(() => {
    if (permissions) {
      setListName(Conventions.Lists.Attachments.Title);
      setPage(strings.newElement);
    }
  }, [permissions]);

  return (
    <>
      {permissions &&
        (state && !errorCode ? (
          <>
            <Title text={"Anhang"} />
            <Actions actions={actions} />

            <FormTable>
              <FormTr>
                <FormLabel required>{Fields.file.title}</FormLabel>
                <FormData>
                  <FileUpload
                    filename={selectedFile?.name}
                    onUpload={(value) => {
                      setSelectedFile(value);
                      setState({
                        ...state,
                        filename: value.name,
                      });
                    }}
                  />
                  <FormError error={errors[Fields.file.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel>{Fields.displayname.title}</FormLabel>
                <FormData>
                  <Input
                    value={state.displayName}
                    onChange={(value) =>
                      setState({
                        ...state,
                        displayName: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.displayname.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel required>{Fields.convention.title}</FormLabel>
                <FormData>
                  <SelectBox
                    value={state.convention}
                    onChange={(value) =>
                      setState({
                        ...state,
                        convention: value,
                      })
                    }
                    options={conventions}
                    getId={(item) => `${item.id}`}
                    getValue={(item) => item.title}
                  />
                  <FormError error={errors[Fields.convention.fieldName]} />
                </FormData>
              </FormTr>
            </FormTable>
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default NewForm;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { LegislatureFull, LegislaturePost } from "./types";

const legislaturesUrl: string = `${functionaries}/${Functionaries.Lists.Legislatures.InternalName}`;
export const insertLegislature = async (
  legislature: LegislaturePost,
  signal: AbortSignal,
): Promise<Result<LegislatureFull | null>> => insertItem(legislaturesUrl, legislature, signal);

export const updateLegislature = async (
  legislatureId: number,
  legislature: LegislaturePost,
  signal: AbortSignal,
): Promise<Result<LegislatureFull | null>> => updateItem(`${legislaturesUrl}/${legislatureId}`, legislature, signal);

export const deleteLegislature = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${legislaturesUrl}/${id}`, signal);

export const deleteLegislatures = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(legislaturesUrl, ids, signal);

export const getLegislature = async (id: number, signal: AbortSignal): Promise<Result<LegislatureFull | null>> =>
  getItem<LegislatureFull>(`${legislaturesUrl}/${id}`, signal);

export const getLegislatures = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<LegislatureFull> | null>> =>
  getItems<LegislatureFull>(legislaturesUrl, pagination, signal);

export const getLegislatureFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(legislaturesUrl, fieldName, signal);

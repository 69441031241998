import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, ValidationErrors } from "../../../services/types";
import { getCancelAction, getEncodedSourceFromUrl, getSaveAction } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import * as Fields from "./Fields";
import { CommuneForm, mapToPOST, validate } from "./types";
import { allItems, dispForm } from "./urls";
import { insertCommune } from "./webapi";

import { UserPermissions } from "../../../services/types";

const NewForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const statusBar = useStatusBarState();
  const [state, setState] = useState<CommuneForm>({
    code: null,
    name: null,
    province: null,
  });
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();

  const [errors, setErrors] = useState<ValidationErrors>({});
  const navigateTo = useNavigate();

  useEffect(() => {
    (async () => {
      const abortController = resetAbortController();
      const permissions = await getUserPermissions(
        Administration.Lists.Communes.InternalName,
        Administration.InternalName,
        abortController.signal,
      );
      if (!abortController.signal.aborted) {
        setPermissions(permissions);
        const canCreate = permissions.schemaPermission && permissions.listPermissions.includes("create");
        !canCreate && setErrorCode(403);
      }
    })();

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const save = async () => {
    if (state && validate(state, setErrors)) {
      const abortController = resetAbortController();
      const result = await insertCommune(mapToPOST(state), abortController.signal);

      if (result.error) {
        if (result.error.code == 409 || result.error.code == 422) {
          const newErrors = result.error.details?.reduce((rv: ValidationErrors, d) => {
            rv[d.field] = strings.getMessageFromTypeError(d.type);
            return rv;
          }, {});

          newErrors && setErrors(newErrors);
        } else {
          statusBar.addError(getString(result.error));
        }
      }
      result.data && navigateTo(`${dispForm}/${result.data.id}?${getEncodedSourceFromUrl()}`);
    }
  };

  const actions: IAction[] = [getSaveAction(save), getCancelAction(navigateTo, allItems)];

  useEffect(() => {
    if (permissions) {
      setListName(Administration.Lists.Communes.Title);
      setPage(strings.newElement);
    }
  }, [permissions]);

  return (
    <>
      {permissions &&
        (!errorCode && state ? (
          <>
            <Title text={"Gemeinde"} />
            <Actions actions={actions} />

            <FormTable>
              <FormTr>
                <FormLabel required>{Fields.code.title}</FormLabel>
                <FormData>
                  <Input
                    value={state.code}
                    onChange={(value) =>
                      setState({
                        ...state,
                        code: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.code.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel required>{Fields.name.title}</FormLabel>
                <FormData>
                  <Input
                    value={state.name}
                    onChange={(value) =>
                      setState({
                        ...state,
                        name: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.name.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel>{Fields.province.title}</FormLabel>
                <FormData>
                  <Input
                    value={state.province}
                    onChange={(value) =>
                      setState({
                        ...state,
                        province: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.province.fieldName]} />
                </FormData>
              </FormTr>
            </FormTable>
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default NewForm;

import Link from "../../../components/Link";
import { IListviewField, MenuOptionValue } from "../../../services/types";
import { formatDateFromISOString } from "../../../services/utils";
import { getItemTitle as getPersonTitle } from "../../public/persons/types";
import { dispForm as dispFormPersons } from "../../public/persons/urls";
import { getItemTitle as getLegislatureTitle } from "../legislatures/types";
import { FunctionaryFull } from "./types";

export const person: IListviewField<FunctionaryFull> = {
  title: "Person",
  fieldName: "person.name",
  renderListValue: (item: FunctionaryFull) => (
    <Link href={`${dispFormPersons}/${item.person?.id}`}>{getPersonTitle(item.person)}</Link>
  ),
};

export const lastName: IListviewField<FunctionaryFull> = {
  title: "Nachname",
  fieldName: "person.lastName",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.person?.lastName,
};

export const firstName: IListviewField<FunctionaryFull> = {
  title: "Vorname",
  fieldName: "person.firstName",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.person?.firstName,
};

export const chapter: IListviewField<FunctionaryFull> = {
  title: "Ortsgruppe",
  fieldName: "chapter.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.chapter?.membersChapter?.name,
};

export const endDate: IListviewField<FunctionaryFull> = {
  title: "Enddatum",
  fieldName: "endDate",
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => formatDateFromISOString(item.endDate),
  renderMenuOptionValue: (value: MenuOptionValue) => formatDateFromISOString(value as string) || "(Leer)",
};

export const legislature: IListviewField<FunctionaryFull> = {
  title: "Legislatur",
  fieldName: "legislature.year",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => getLegislatureTitle(item.legislature),
};

export const area: IListviewField<FunctionaryFull> = {
  title: "Gebiet",
  fieldName: "area.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.area?.name,
};

export const district: IListviewField<FunctionaryFull> = {
  title: "Bezirk",
  fieldName: "district.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.district?.name,
};

export const role: IListviewField<FunctionaryFull> = {
  title: "Rolle",
  fieldName: "role.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.role?.name,
};

export const electionDate: IListviewField<FunctionaryFull> = {
  title: "Wahltag",
  fieldName: "electionDate",
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => formatDateFromISOString(item.electionDate),
  renderMenuOptionValue: (value: MenuOptionValue) => formatDateFromISOString(value as string) || "(Leer)",
};

export const company: IListviewField<FunctionaryFull> = {
  title: "Betrieb",
  fieldName: "company.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: FunctionaryFull) => item.company?.name,
};

import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Actions from "../../../components/controls/Actions";
import { SearchDialog } from "../../../components/dialogs/SearchDialog";
import Errors, { getString } from "../../../components/errors/Errors";
import CheckBox from "../../../components/forms/CheckBox";
import DatePicker from "../../../components/forms/DatePickerWithInput";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Lookup from "../../../components/forms/Lookup";
import { DefaultPagination, Pagination } from "../../../components/listview/Pagination";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { Members } from "../../../listsSettings/members";
import { Public } from "../../../listsSettings/public";
import { ErrorCode, IAction, UserPermissions, ValidationErrors } from "../../../services/types";
import { getCancelAction } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Cross from "../../../svg/Cross.svg?react";
import Save from "../../../svg/Save.svg?react";
import * as CompanyFields from "../../members/companies/Fields";
import { Company } from "../../members/companies/types";
import { searchCompanies } from "../../members/companies/webapi";
import * as PersonsFields from "../../public/persons/Fields";
import { getItemTitle, PersonFull } from "../../public/persons/types";
import { searchPersons } from "../../public/persons/webapi";
import { AreaFull } from "../areas/types";
import * as ChapterFields from "../chapters/Fields";
import { ChapterFull } from "../chapters/types";
import { searchChapters } from "../chapters/webapi";
import { DistrictFull } from "../districts/types";
import * as LegislatureFields from "../legislatures/Fields";
import SelectBoxLegislature from "../legislatures/SelectBoxLegislature";
import { LegislatureFull } from "../legislatures/types";
import { getLegislatures } from "../legislatures/webapi";
import * as RoleFields from "../roles/Fields";
import { RoleFull } from "../roles/types";
import { getRoles } from "../roles/webapi";
import * as Fields from "./Fields";
import styles from "./InsertBatch.module.css";
import { FunctionaryPost } from "./types";
import { allItems } from "./urls";
import { insertFunctionaries } from "./webapi";

type FunctionaryWithRoles = {
  person: PersonFull | null;
  chapter: ChapterFull | null;
  area: AreaFull | null;
  district: DistrictFull | null;
  roles: RoleFull[];
  company: Company | null;
};

const InsertBatch: FC = () => {
  const navigateTo = useNavigate();
  const statusBar = useStatusBarState();

  const [abortController, resetAbortController] = useAbortController();

  const [state, setState] = useState<{
    legislature: LegislatureFull | null;
    functionaries: FunctionaryWithRoles[];
    electionDate: string | null;
  }>({
    legislature: null,
    functionaries: [],
    electionDate: null,
  });

  const [errors, setErrors] = useState<ValidationErrors>({});
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [showPersonsDialog, setShowPersonsDialog] = useState(false);
  const [showCompaniesDialog, setShowCompaniesDialog] = useState(false);
  const [legislatures, setLegislature] = useState<LegislatureFull[]>([]);
  const [roles, setRoles] = useState<RoleFull[]>([]);

  const defaultPagination: Pagination = {
    ...DefaultPagination,
    itemsPerPage: 100,
  };

  const update = (index: number, functionary: FunctionaryWithRoles) => {
    const newFunctionaries = state.functionaries.map((x) => ({ ...x }));
    newFunctionaries[index] = functionary;
    setState((prevstate) => ({
      ...prevstate,
      functionaries: newFunctionaries,
    }));
  };

  useEffect(() => {
    (async () => {
      const abortController = resetAbortController();
      const [legislatures, roles, permissions] = await Promise.all([
        getLegislatures(
          {
            ...defaultPagination,
            orderBy: LegislatureFields.year.fieldName,
            orderByDescending: true,
          },
          abortController.signal,
        ),
        getRoles(
          {
            ...defaultPagination,
            orderBy: RoleFields.name.fieldName,
          },
          abortController.signal,
        ),
        getUserPermissions(
          Functionaries.Lists.Functionaries.InternalName,
          Functionaries.InternalName,
          abortController.signal,
        ),
      ]);
      if (!abortController.signal.aborted) {
        setPermissions(permissions);
        legislatures.data && setLegislature(legislatures.data.items);
        roles.data && setRoles(roles.data.items);
        !permissions && setErrorCode(403);
      }
    })();

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const addPerson = (person: PersonFull) => {
    setShowPersonsDialog(false);

    const persons = state.functionaries.filter((x) => x.person?.id == person.id);
    if (persons.length > 0) {
      statusBar.addError(`<b>${getItemTitle(person)}</b> ist schon vorhanden.`);
      return;
    }
    setState((prevstate) => ({
      ...prevstate,
      functionaries: [
        ...prevstate.functionaries,
        {
          person: person,
          company: null,
          district: null,
          area: null,
          chapter: null,
          roles: roles.map((x) => ({ ...x })),
        },
      ],
    }));
  };

  const setCompany = (company: Company) => {
    setShowCompaniesDialog(false);

    setState((prevstate) => ({
      ...prevstate,
      company: company,
    }));
  };
  const validate = (): boolean => {
    const errors: ValidationErrors = {};
    if (!state.legislature) {
      errors[Fields.legislature.fieldName] = strings.required;
    }
    if (!state.electionDate) {
      errors[Fields.electionDate.fieldName] = strings.required;
    }

    state.functionaries.length === 0 ? statusBar.addError("Es wurden keine Funktionäre angegeben.") : statusBar.clear();

    const missingPersons = !state.functionaries.reduce((rv: boolean, f, index) => {
      const error = !f.person;
      errors[`${Fields.person.fieldName}_${index}`] = error ? "error" : undefined;
      return rv && !error;
    }, true);

    const missingCompanies = !state.functionaries.reduce((rv: boolean, f, index) => {
      const error = !f.company;
      errors[`${Fields.company.fieldName}_${index}`] = error ? "error" : undefined;
      return rv && !error;
    }, true);

    const missingChapters = !state.functionaries.reduce((rv: boolean, f, index) => {
      const error = !f.chapter?.id;
      errors[`${Fields.chapter.fieldName}_${index}`] = error ? "error" : undefined;
      return rv && !error;
    }, true);

    const missingRoles = !state.functionaries.reduce((rv: boolean, f, index) => {
      const error = f.roles.filter((x) => x.selected).length == 0;
      errors[`${Fields.role.fieldName}_${index}`] = error ? "error" : undefined;
      return rv && !error;
    }, true);

    setErrors(errors);

    return (
      Object.keys(errors).filter((x) => errors[x]).length === 0 &&
      state.functionaries.length > 0 &&
      !missingPersons &&
      !missingCompanies &&
      !missingChapters &&
      !missingRoles
    );
  };

  const save = async (): Promise<boolean | null> => {
    if (!validate()) return null;

    const items = state.functionaries.reduce((rv, f) => {
      const selected: FunctionaryPost[] = f.roles
        .filter((x) => x.selected)
        .map((x) => ({
          personId: f.person?.id ?? 0,
          legislatureId: state.legislature?.id ?? 0,
          chapterId: f.chapter?.id ?? null,
          areaId: f.chapter?.area?.id ?? null,
          districtId: f.chapter?.area?.district?.id ?? null,
          roleId: x.id ?? 0,
          electionDate: state.electionDate ?? "",
          companyId: f.company?.id ?? null,
          endDate: null,
        }));
      return [...rv, ...selected];
    }, new Array<FunctionaryPost>());

    const abortController = resetAbortController();
    const result = await insertFunctionaries(items, abortController.signal);

    if (result.error) {
      if (result.error.code == 409 || result.error.code == 422) {
        const newErrors = result.error.details?.reduce((rv: ValidationErrors, d) => {
          rv[d.field] = strings.getMessageFromTypeError(d.type);
          return rv;
        }, {});

        newErrors && setErrors(newErrors);
      } else {
        statusBar.addError(getString(result.error));
      }
    }
    return result.data;
  };

  const actions: IAction[] = [
    {
      title: "Elemente speichern",
      icon: Save,
      onClick: save,
    },
    getCancelAction(navigateTo, allItems),
  ];

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <>
            <Title text={strings.new} />
            <Actions actions={actions} />
            {showPersonsDialog && (
              <SearchDialog
                isOpen={showPersonsDialog}
                searchItems={(serchValue, abortController) => searchPersons(serchValue, abortController.signal)}
                onItemSelected={addPerson}
                cancelClicked={() => setShowPersonsDialog(false)}
                title={Public.Persons.Title}
                fields={[PersonsFields.lastName, PersonsFields.firstName]}
                getTitle={getItemTitle}
              />
            )}
            {showCompaniesDialog && (
              <SearchDialog
                isOpen={showCompaniesDialog}
                searchItems={(serchValue, abortController) => searchCompanies(serchValue, abortController.signal)}
                onItemSelected={setCompany}
                cancelClicked={() => setShowCompaniesDialog(false)}
                title={Members.Lists.Companies.Title}
                fields={[
                  CompanyFields.name,
                  CompanyFields.companyNumber,
                  CompanyFields.member,
                  CompanyFields.memberNumber,
                ]}
                getTitle={(item) => item.name}
              />
            )}
            <FormTable>
              <FormTr>
                <FormLabel required>{Fields.legislature.title}</FormLabel>
                <FormData>
                  <SelectBoxLegislature
                    value={state.legislature}
                    options={legislatures}
                    onChange={(value) =>
                      setState((prevstate) => ({
                        ...prevstate,
                        legislature: value,
                      }))
                    }
                  />
                  <FormError error={errors[Fields.legislature.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel required>{Fields.electionDate.title}</FormLabel>
                <FormData>
                  <DatePicker
                    value={state.electionDate}
                    onSelect={(value) =>
                      setState({
                        ...state,
                        electionDate: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.electionDate.fieldName]} />
                </FormData>
              </FormTr>
            </FormTable>

            <FormTable>
              <FormTr>
                <FormLabel />
                <FormLabel>{Fields.person.title}</FormLabel>
                <FormLabel required>Mitgliedsbetrieb</FormLabel>
                <FormLabel required>{Fields.chapter.title}</FormLabel>
                <FormLabel required>Rollen</FormLabel>
              </FormTr>
              {state.functionaries.map((functionary, fIndex) => (
                <FormTr key={fIndex}>
                  <FormLabel>
                    <div
                      className={styles.svg}
                      onClick={() =>
                        setState((prevstate) => ({
                          ...prevstate,
                          functionaries: [
                            ...prevstate.functionaries.filter((x) => x !== functionary).map((x) => ({ ...x })),
                          ],
                        }))
                      }
                    >
                      <Cross />
                    </div>
                  </FormLabel>
                  <FormLabel>
                    <div className={styles.functionary}>{getItemTitle(functionary.person)}</div>
                  </FormLabel>
                  <FormData>
                    <div
                      className={classNames({
                        [styles.error]: !!errors[`${Fields.company.fieldName}_${fIndex}`],
                      })}
                    >
                      <Lookup
                        disabled={!!functionary.company}
                        value={functionary.company?.name ?? ""}
                        onChange={(value) => {
                          const newFunctionary = { ...functionary, company: value };
                          update(fIndex, newFunctionary);
                        }}
                        searchItems={searchCompanies}
                        searchDialogTitle={Members.Lists.Companies.Title}
                        searchDialogFields={[
                          CompanyFields.name,
                          CompanyFields.companyNumber,
                          CompanyFields.member,
                          CompanyFields.memberNumber,
                        ]}
                        getTitle={(item) => item.name}
                      />
                    </div>
                  </FormData>
                  <FormData>
                    <div
                      className={classNames({
                        [styles.error]: !!errors[`${Fields.chapter.fieldName}_${fIndex}`],
                      })}
                    >
                      <Lookup
                        value={functionary.chapter?.membersChapter?.name || null}
                        disabled={!!functionary.chapter?.id}
                        onChange={(value) => {
                          const newFunctionary = {
                            ...functionary,
                            chapter: value,
                            area: value?.area || null,
                            district: value?.area?.district || null,
                          };
                          update(fIndex, newFunctionary);
                        }}
                        searchItems={searchChapters}
                        getTitle={(item) => item.membersChapter?.name ?? ""}
                        searchDialogTitle={Functionaries.Lists.Chapters.Title}
                        searchDialogFields={[ChapterFields.name, ChapterFields.area, ChapterFields.district]}
                      />
                    </div>
                  </FormData>
                  <FormData fitContent>
                    <div
                      className={classNames(styles.roles, {
                        [styles.error]: !!errors[`${Fields.role.fieldName}_${fIndex}`],
                      })}
                    >
                      {functionary.roles.map((role, rIndex) => (
                        <CheckBox
                          key={role.name}
                          value={role.selected || false}
                          label={role.name ?? ""}
                          onChange={(value) => {
                            const newRoles = functionary.roles.map((x) => ({ ...x }));
                            const newRole = { ...role, selected: value };
                            newRoles[rIndex] = newRole;
                            const newFunctionary = { ...functionary, roles: newRoles };
                            update(fIndex, newFunctionary);
                          }}
                        />
                      ))}
                    </div>
                  </FormData>
                </FormTr>
              ))}
              <FormTr>
                <FormLabel>
                  <div
                    className={styles.svg}
                    onClick={() => {
                      setShowPersonsDialog(true);
                    }}
                  >
                    <Add />
                  </div>
                </FormLabel>
              </FormTr>
            </FormTable>
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default InsertBatch;

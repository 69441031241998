import { useEffect } from "react";

interface OnKeyDownHandlerOptions {
  altKey: boolean;
  shiftKey: boolean;
  ctrlKey: boolean;
}

type OnKeyDownCallbackFunc = (options: OnKeyDownHandlerOptions) => void;

interface OnKeyDownHandlerProps {
  arrowLeft?: OnKeyDownCallbackFunc;
  arrowRight?: OnKeyDownCallbackFunc;
  arrowDown?: OnKeyDownCallbackFunc;
  arrowUp?: OnKeyDownCallbackFunc;
  pageDown?: OnKeyDownCallbackFunc;
  pageUp?: OnKeyDownCallbackFunc;
  home?: OnKeyDownCallbackFunc;
  end?: OnKeyDownCallbackFunc;
  enter?: OnKeyDownCallbackFunc;
  escape?: OnKeyDownCallbackFunc;
  space?: OnKeyDownCallbackFunc;
}

const useOnKeyDownHandler = (props: OnKeyDownHandlerProps) => {
  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowLeft":
        e.preventDefault();
        e.stopPropagation();
        props.arrowLeft && props.arrowLeft(e);
        break;
      case "ArrowRight":
        e.preventDefault();
        e.stopPropagation();
        props.arrowRight && props.arrowRight(e);
        break;
      case "ArrowDown":
        e.preventDefault();
        e.stopPropagation();
        props.arrowDown && props.arrowDown(e);
        break;
      case "ArrowUp":
        e.preventDefault();
        e.stopPropagation();
        props.arrowUp && props.arrowUp(e);
        break;
      case "PageUp":
        e.preventDefault();
        e.stopPropagation();
        props.pageUp && props.pageUp(e);
        break;
      case "PageDown":
        e.preventDefault();
        e.stopPropagation();
        props.pageDown && props.pageDown(e);
        break;
      case "Home":
        e.preventDefault();
        e.stopPropagation();
        props.home && props.home(e);
        break;
      case "End":
        e.preventDefault();
        e.stopPropagation();
        props.end && props.end(e);
        break;
      case "Enter":
        e.preventDefault();
        e.stopPropagation();
        props.enter && props.enter(e);
        break;
      case "Escape":
        e.preventDefault();
        e.stopPropagation();
        props.escape && props.escape(e);
        break;
      case " ":
        e.preventDefault();
        e.stopPropagation();
        props.space && props.space(e);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [props]);
};

export default useOnKeyDownHandler;

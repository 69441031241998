import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface ClaimsIdentity {
  name: string;
  preferred_username: string;
}

const isValidIdentity = (identity: object | undefined): identity is ClaimsIdentity => {
  if (identity === undefined) return false;

  const claimsIdentity = identity as ClaimsIdentity;
  return [claimsIdentity.name, claimsIdentity.preferred_username].every((key) => typeof key === "string");
};

const isEmployee = (email?: string) => email?.includes("@hgv.it") || email?.includes("@hgvbz.onmicrosoft.com");

// TODO Replace this function with instance.getActiveAccount() after
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4411 is solved
const getMainAccount = (accounts: AccountInfo[]) =>
  accounts.filter((account) => isValidIdentity(account.idTokenClaims))[0];

const getIdentityFromAccount = (account: AccountInfo | undefined) =>
  isValidIdentity(account?.idTokenClaims) ? account?.idTokenClaims : undefined;

const useIdentity = () => {
  const { instance, accounts } = useMsal();
  const account = getMainAccount(accounts);
  const identity = getIdentityFromAccount(account);

  return {
    name: identity?.name,
    email: identity?.preferred_username,
    isEmployee: isEmployee(identity?.preferred_username),
    logout: () => instance.logoutRedirect(),
  };
};

export default useIdentity;

import React, { CSSProperties } from "react";

export const disableMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  event.preventDefault();
  event.stopPropagation();
};

export const getRotateStyle = (degree: number): CSSProperties => {
  return {
    transform: `rotate(${degree}deg)`,
  };
};

export const getInlineRotateStyle = (degree: number): CSSProperties => ({
  transform: `translateX(-50%) rotate(${degree}deg)`,
});

export const getInitialPointerStyle = (height: number, top: number, degree: number): CSSProperties => ({
  height: `${height}px`,
  top: `${top}px`,
  transform: `translateX(-50%) rotate(${degree}deg)`,
});

import { FileItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { ConventionFull } from "../conventions/types";
import * as Fields from "./Fields";

export interface AttachmentFull extends FileItem {
  id: number;
  displayName: string;
  convention: ConventionFull;
}

export interface AttachmentForm {
  displayName: string | null;
  filename: string | null;
  convention: ConventionFull | null;
}

export interface AttachmentUpdatePost {
  displayName: string;
}

export interface AttachmentInsertPost extends AttachmentUpdatePost {
  filename: string;
  conventionId: number;
}

export const mapToPOST = (item: AttachmentForm): AttachmentInsertPost => {
  return {
    displayName: item.displayName || "",
    filename: item.filename || "",
    conventionId: item.convention?.id ?? 0,
  };
};

export const validate = (
  state: AttachmentForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.filename) {
    errors[Fields.file.fieldName] = strings.required;
  }
  if (!state.convention?.id) {
    errors[Fields.convention.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

import { NavigateFunction } from "react-router-dom";
import { MenuOptionValue } from "../../services/types";
import { getStringFromUrl, setParamToUrl } from "../../services/utils";

export const DefaultPagination: Pagination = {
  page: 1,
  itemsPerPage: 30,
  orderBy: "",
  orderByDescending: false,
  filters: [],
};

interface Queries {
  [id: string]: Pagination;
}

export interface Pagination {
  page?: number;
  itemsPerPage?: number;
  orderBy?: string;
  orderByDescending?: boolean;
  filters?: FilterParam[];
  searchKey?: string;
}

interface FilterParamvalue {
  value: MenuOptionValue;
  operator?: "EQ" | "NEQ" | "GT" | "GEQ" | "LT" | "LEQ";
}

export interface FilterParam {
  fieldname: string;
  values?: FilterParamvalue[];
  operator?: "IS_NULL" | "IS_NOT_NULL";
}

const parseQueries = (query: string): Queries => {
  const paginations: string[] = decodeURIComponent(query).split("&");
  const uriComponents = paginations.map((x: string) => decodeURIComponent(x));
  const queries: Queries = {};
  return uriComponents.reduce((rv, x: string) => {
    const [key, value] = x.split("=");
    if (key && value) {
      try {
        rv[key] = JSON.parse(value) as Pagination;
      } catch (ex) {
        console.error(ex);
      }
    }
    return rv;
  }, queries);
};

export const getPagination = (id: string): Pagination | null => {
  const quersiesParam = getStringFromUrl("queries");
  const queries = (quersiesParam && parseQueries(decodeURIComponent(quersiesParam))) || {};
  return queries[id] || null;
};

export const setPagination = (navigateTo: NavigateFunction, listId: string, pagination: Pagination) => {
  const quersiesParam = getStringFromUrl("queries");
  const allQueries = (quersiesParam && parseQueries(decodeURIComponent(quersiesParam))) || {};
  allQueries[listId] = pagination;
  const newQueries = stringifyToUrl(allQueries);
  setParamToUrl(navigateTo, "queries", encodeURIComponent(newQueries));
};

const stringifyToUrl = (queries: Queries): string => {
  const arr: string[] = [];
  Object.keys(queries).reduce((rv, x: string) => {
    const pagination = queries[x];
    if (pagination) {
      const p = JSON.stringify(pagination);
      rv.push(`${x}=${encodeURIComponent(p)}`);
    }
    return rv;
  }, arr);
  return arr.join("&");
};

import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { CategoryFull } from "../categories/types";
import { PartnerFull } from "../partners/types";
import * as Fields from "./Fields";

export interface ConventionFull extends CreationInformationItem {
  id: number;
  title: string;
  description: string | null;
  descriptionHtml: string | null;
  partner: PartnerFull | null;
  category: CategoryFull;
  online: boolean;
}

export interface ConventionForm {
  title: string | null;
  description: string | null;
  descriptionHtml: string | null;
  partner: PartnerFull | null;
  category: CategoryFull | null;
  online: boolean;
}

export interface ConventionPost {
  title: string;
  description: string | null;
  descriptionHtml: string | null;
  partnerId: number | null;
  categoryId: number;
  online: boolean;
}

export const mapToPOST = (item: ConventionForm): ConventionPost => {
  return {
    title: item.title || "",
    categoryId: item.category?.id ?? 0,
    description: item.description,
    descriptionHtml: item.descriptionHtml,
    partnerId: item.partner?.id ?? null,
    online: item.online,
  };
};

export const validate = (
  state: ConventionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.title) {
    errors[Fields.title.fieldName] = strings.required;
  }
  if (!state.category) {
    errors[Fields.category.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IDialogField, Item, Result, SearchResult } from "../../services/types";
import Assignment from "../../svg/Assignment.svg?react";
import Cross from "../../svg/Cross.svg?react";
import Inventory from "../../svg/Inventory.svg?react";
import { SearchDialog } from "../dialogs/SearchDialog";
import styles from "./Lookup.module.css";

interface Props<T extends Item> {
  value: string | null;
  disabled: boolean | null;
  onChange: (x: T | null) => void;
  searchItems: (searchValue: string, signal: AbortSignal) => Promise<Result<SearchResult<T> | null>>;
  getTitle: (item: T) => string;
  searchDialogTitle: string;
  searchDialogFields: IDialogField<T>[];
  error?: boolean;
}

const Lookup = <T extends Item>(props: Props<T>) => {
  const [lookupState, setLookupState] = useState({
    value: "",
    disable: false,
  });

  const [dialogState, setDialogState] = useState({
    show: false,
    startSearch: false,
  });

  useEffect(() => {
    setLookupState({
      value: props.value ?? "",
      disable: !!props.disabled,
    });
  }, [props.disabled, props.value]);

  const keyPressed = (event: React.KeyboardEvent) => {
    if (event.key == "Enter" || event.key == "NumpadEnter") {
      check(lookupState.value);
    }
  };

  const openDialog = (startSearch: boolean) => {
    setDialogState({
      ...dialogState,
      show: true,
      startSearch: startSearch,
    });
  };

  const check = (value: string) => {
    if (value) {
      openDialog(true);
    }
  };

  const update = (value: string) => {
    setLookupState({
      ...lookupState,
      value: value,
    });
  };

  const deleteLookup = () => {
    props.onChange(null);
  };

  const searchItems = (value: string, abortController: AbortController) => {
    return props.searchItems(value, abortController.signal);
  };

  const onCancelClick = () => {
    setDialogState({
      ...dialogState,
      show: false,
    });
  };

  const onItemSelected = (item: T) => {
    setDialogState({
      ...dialogState,
      show: false,
    });
    props.onChange(item);
  };

  return (
    <>
      {dialogState.show && (
        <SearchDialog
          isOpen={dialogState.show}
          defaultSearchKey={dialogState.startSearch ? lookupState.value : undefined}
          startSearchWhenOpen={dialogState.startSearch}
          searchItems={searchItems}
          onItemSelected={onItemSelected}
          cancelClicked={onCancelClick}
          getTitle={props.getTitle}
          title={props.searchDialogTitle}
          fields={props.searchDialogFields}
        />
      )}
      <div className={styles["lookup-container"]}>
        <input
          className={classNames({ [styles.error]: props.error })}
          value={lookupState.value ?? undefined}
          onChange={(event) => update(event.target.value)}
          onKeyDown={keyPressed}
          disabled={lookupState.disable}
        />

        <span title="Überprüfen" onClick={() => lookupState.value && check(lookupState.value)}>
          <Inventory />
        </span>
        <span title="Durchsuchen" onClick={() => openDialog(false)}>
          <Assignment />
        </span>
        <span title="Löschen" onClick={deleteLookup}>
          <Cross />
        </span>
      </div>
    </>
  );
};

export default Lookup;

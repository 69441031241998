import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { LexicalEditor } from "lexical";
import { FC, useEffect, useRef, useState } from "react";
import Button from "../../Button";
import { DialogBorder } from "../../dialogs/DialogBorder";
import { DialogCloseIcon } from "../../dialogs/DialogCloseIcon";
import { DialogContainer } from "../../dialogs/DialogContainer";
import { Draggable } from "../../dialogs/Draggable";
import Modal from "../../dialogs/Modal";
import Title from "../../Title";
import styles from "./InsertTableDialog.module.css";
import TextInput from "./TextInput";

interface InsertTableDialogProps {
  editor: LexicalEditor;
  onClose: () => void;
  toolbarRef: React.RefObject<HTMLDivElement>;
}

const InsertTableDialog: FC<InsertTableDialogProps> = ({ editor, onClose, toolbarRef }) => {
  const [rows, setRows] = useState("5");
  const [columns, setColumns] = useState("5");
  const tableDialogRef = useRef<HTMLDivElement | null>(null);

  const onClick = () => {
    editor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows });
    onClose();
  };

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const tableDialog = tableDialogRef.current;

    if (toolbar && tableDialog) {
      const bounding = toolbar.getBoundingClientRect();
      tableDialog.style.top = `${bounding.top + bounding.height}px`;
      tableDialog.style.left = `${bounding.left}px`;
      tableDialog.style.opacity = "1";
    }
  }, [tableDialogRef, toolbarRef]);

  return (
    <Modal onClose={onClose}>
      <Draggable>
        <DialogContainer>
          <DialogCloseIcon onClose={onClose} />
          <DialogBorder>
            <Title text="Schließen" />
            <div className={styles["dialog-content"]}>
              <TextInput label="Zeilen" onChange={setRows} value={rows} />
              <TextInput label="Spalten" onChange={setColumns} value={columns} />
            </div>
            <Button
              onclick={onClick}
              disabled={
                (Number(rows) < 1 && Number(columns) < 1) ||
                Number(rows) < 0 ||
                Number(columns) < 0 ||
                isNaN(Number(rows)) ||
                isNaN(Number(columns))
              }
            >
              OK
            </Button>
          </DialogBorder>
        </DialogContainer>
      </Draggable>
    </Modal>
  );
};

export default InsertTableDialog;

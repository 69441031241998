import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { CountryFull, CountryPost } from "./types";

const countriesUrl: string = `${administration}/${Administration.Lists.Countries.InternalName}`;
export const insertCountry = async (country: CountryPost, signal: AbortSignal): Promise<Result<CountryFull | null>> =>
  insertItem(countriesUrl, country, signal);

export const updateCountry = async (
  countryId: number,
  country: CountryPost,
  signal: AbortSignal,
): Promise<Result<CountryFull | null>> => updateItem(`${countriesUrl}/${countryId}`, country, signal);

export const deleteCountry = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${countriesUrl}/${id}`, signal);

export const deleteCountries = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(countriesUrl, ids, signal);

export const getCountry = async (id: number, signal: AbortSignal): Promise<Result<CountryFull | null>> =>
  getItem<CountryFull>(`${countriesUrl}/${id}`, signal);

export const getCountries = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<CountryFull> | null>> => getItems<CountryFull>(countriesUrl, pagination, signal);

export const getCountryFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(countriesUrl, fieldName, signal);

import { isValid, parseISO } from "date-fns";
import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { Chapter as MemberChapter } from "../../members/chapters/types";
import { AreaFull } from "../areas/types";
import * as Fields from "./Fields";

export interface ChapterFull extends CreationInformationItem {
  id: number;
  area: AreaFull;
  from: string | null;
  to: string | null;
  logoUrl: string | null;
  taxCode: string | null;
  vatNumber: string | null;
  membersChapter: MemberChapter | null;
  iban: string | null;
}

export interface ChapterForm {
  area: AreaFull | null;
  from: string | null;
  to: string | null;
  taxCode: string | null;
  vatNumber: string | null;
  membersChapter: MemberChapter | null;
  iban: string | null;
}

export interface ChapterPost {
  areaId: number;
  from: string | null;
  to: string | null;
  taxCode: string | null;
  vatNumber: string | null;
  membersChapterId: number;
  iban: string | null;
}

export const mapToPOST = (item: ChapterForm): ChapterPost => {
  return {
    areaId: item.area?.id || 0,
    from: item.from ?? null,
    to: item.to ?? null,
    taxCode: item.taxCode,
    vatNumber: item.vatNumber,
    membersChapterId: item.membersChapter?.id || 0,
    iban: item.iban,
  };
};

export const validate = (
  state: ChapterForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.area) {
    errors[Fields.area.fieldName] = strings.required;
  }
  if (!state.membersChapter) {
    errors[Fields.membersChapterCode.fieldName] = strings.required;
  }
  if (state.from) {
    const date = parseISO(state.from);
    !isValid(date) && (errors[Fields.from.fieldName] = strings.invalidText);
  }
  if (state.to) {
    const date = parseISO(state.to);
    !isValid(date) && (errors[Fields.to.fieldName] = strings.invalidText);
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

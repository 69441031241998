import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import { getCloseAction, getCurrentUrlAsSource, getSelectedItems, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as ChapterFields from "../chapters/Fields";
import { ChapterFull } from "../chapters/types";
import { editForm as editFormChapters, newForm as newFormChapters } from "../chapters/urls";
import { deleteChapters } from "../chapters/webapi";
import * as Fields from "./Fields";
import { AreaFull } from "./types";
import { allItems, editForm } from "./urls";
import { deleteArea, getArea, getAreaChapters, getAreaChaptersFilters } from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<AreaFull>();
  const statusBar = useStatusBarState();
  const [chapters, setChapters] = useState<SearchResult<ChapterFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [chapterPermissions, setChapterPermissions] = useState<UserPermissions>();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Functionaries.Lists.Areas.InternalName,
      Functionaries.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [chapterPermissions, result] = await Promise.all([
          getUserPermissions(
            Functionaries.Lists.Chapters.InternalName,
            Functionaries.InternalName,
            abortController.signal,
          ),
          getArea(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setChapterPermissions(chapterPermissions);
            setState(result.data);
            loadChapters(result.data.id, pagination, abortController);
          }
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteArea(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const listviewId = "area-chapters";

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  const loadChapters = async (areaId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getAreaChapters(areaId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setChapters(result.data);
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  const addChapterAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => navigateTo(`${newFormChapters}?areaId=${state?.id}&${getCurrentUrlAsSource()}`),
    disabled:
      !chapterPermissions?.listPermissions.includes("create") || !permissions?.listPermissions.includes("read-all"),
  };

  const editChapterAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(chapters)[0];
      selectedItem && navigateTo(getSourceFromUrl() ?? `${editFormChapters}/${selectedItem.id}`);
    },
    disabled: getSelectedItems(chapters).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteChapterAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(chapters);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteChapters(
            selectedItems.map((x) => x.id),
            abortController.signal,
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadChapters(state.id, pagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled: getSelectedItems(chapters).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const actions = [addChapterAction, editChapterAction, deleteChapterAction];

  useEffect(() => {
    if (state) {
      setListName(Functionaries.Lists.Areas.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Gebiet"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.district.title}</FormLabel>
              <FormData disp>{state.district?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.order.title}</FormLabel>
              <FormData disp>{state.order}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          <ReferencedItemsView
            itemId={state.id}
            id={listviewId}
            actions={actions}
            title={Functionaries.Lists.Chapters.Title}
            fields={[ChapterFields.name]}
            allowSelectRows
            reload={(areaId, pagination) => {
              const abortController = resetAbortController();
              return loadChapters(areaId, pagination, abortController);
            }}
            searchResult={chapters}
            getFilters={getAreaChaptersFilters}
            pagination={pagination}
            updateItems={(items) =>
              chapters &&
              setChapters({
                ...chapters,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

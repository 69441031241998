import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { CommuneFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<CommuneFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: CommuneFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
};

export const code: IListviewField<CommuneFull> = {
  title: "Kode",
  fieldName: "code",
  isSortable: true,
  renderListValue: (item: CommuneFull) => item.code,
};

export const province: IListviewField<CommuneFull> = {
  title: "Provinz",
  fieldName: "province",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: CommuneFull) => item.province,
};

import { FC } from "react";
import ReactDraggable from "react-draggable";
import styles from "./Draggable.module.css";

/* Dragging over an iframe stops dragging when moving the mouse too fast
   https://github.com/react-grid-layout/react-draggable/issues/613
    */

interface DraggableProps {
  children: React.ReactNode;
}

export const Draggable: FC<DraggableProps> = ({ children }: DraggableProps) => {
  return (
    <ReactDraggable cancel="input, svg, button, table">
      <div className={styles.draggable}>{children}</div>
    </ReactDraggable>
  );
};

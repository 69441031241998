import { FC } from "react";
import Docm from "../assets/file_type_images/ic_docm.png";
import Docx from "../assets/file_type_images/ic_docx.png";
import Dot from "../assets/file_type_images/ic_dot.png";
import Eml from "../assets/file_type_images/ic_eml.png";
import Gen from "../assets/file_type_images/ic_gen.gif";
import Jpe from "../assets/file_type_images/ic_jpe.gif";
import Jpeg from "../assets/file_type_images/ic_jpeg.gif";
import Jpg from "../assets/file_type_images/ic_jpg.gif";
import Msg from "../assets/file_type_images/ic_msg.png";
import Pdf from "../assets/file_type_images/ic_pdf.png";
import Png from "../assets/file_type_images/ic_png.gif";
import Ppt from "../assets/file_type_images/ic_ppt.png";
import Pptm from "../assets/file_type_images/ic_pptm.png";
import Pptx from "../assets/file_type_images/ic_pptx.png";
import Xls from "../assets/file_type_images/ic_xls.png";
import Xlsx from "../assets/file_type_images/ic_xlsx.png";

interface FileIconProps {
  extension: string | null;
}

const FileIcon: FC<FileIconProps> = ({ extension }) => {
  const ext = extension?.toLowerCase().replace(/^\./g, "") ?? "";

  const getSource = () => {
    switch (ext) {
      case "jpe":
        return Jpe;
      case "jpg":
        return Jpg;
      case "png":
        return Png;
      case "jpeg":
        return Jpeg;
      case "msg":
        return Msg;
      case "docm":
        return Docm;
      case "pdf":
        return Pdf;
      case "docx":
        return Docx;
      case "dot":
        return Dot;
      case "eml":
        return Eml;
      case "ppt":
        return Ppt;
      case "pptm":
        return Pptm;
      case "pptx":
        return Pptx;
      case "xls":
        return Xls;
      case "xlsx":
        return Xlsx;
      default:
        return Gen;
    }
  };
  return <img alt={ext} title={`${ext} Datei`} src={getSource()} />;
};

export default FileIcon;

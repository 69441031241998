import React, { FC } from "react";
import styles from "./NumberInput.module.css";

interface NumberInputProps {
  value: number | null;
  onChange: (x: number | null) => void;
}

const NumberInput: FC<NumberInputProps> = (props: NumberInputProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "") {
      props.onChange(null);
    } else {
      const d = Number(value.replace(/\./g, "").replace(/,/g, "."));
      props.onChange(d || null);
    }
  };

  const value = props.value != null && props.value != undefined ? props.value : "";

  return <input type="text" value={value} className={styles.input} onChange={onChange} />;
};

export default NumberInput;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { AreaFull } from "../areas/types";
import { DistrictFull, DistrictPost } from "./types";

const districtsUrl: string = `${functionaries}/${Functionaries.Lists.Districts.InternalName}`;
export const insertDistrict = async (
  district: DistrictPost,
  signal: AbortSignal,
): Promise<Result<DistrictFull | null>> => insertItem(districtsUrl, district, signal);

export const updateDistrict = async (
  districtId: number,
  district: DistrictPost,
  signal: AbortSignal,
): Promise<Result<DistrictFull | null>> => updateItem(`${districtsUrl}/${districtId}`, district, signal);

export const deleteDistrict = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${districtsUrl}/${id}`, signal);

export const deleteDistricts = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(districtsUrl, ids, signal);

export const getDistrict = async (id: number, signal: AbortSignal): Promise<Result<DistrictFull | null>> =>
  getItem<DistrictFull>(`${districtsUrl}/${id}`, signal);

export const getDistricts = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<DistrictFull> | null>> => getItems<DistrictFull>(districtsUrl, pagination, signal);

export const getDistrictFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(districtsUrl, fieldName, signal);

export const getDistrictAreas = async (
  districtId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<AreaFull> | null>> =>
  getItems<AreaFull>(`${districtsUrl}/${districtId}/areas`, pagination, signal);

export const getDistrictAreasFilters = (
  districtId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${districtsUrl}/${districtId}/areas`, fieldname, signal);

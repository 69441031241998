import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import Button from "../../../components/Button";
import Actions from "../../../components/controls/Actions";
import { DialogBorder } from "../../../components/dialogs/DialogBorder";
import { DialogCloseIcon } from "../../../components/dialogs/DialogCloseIcon";
import { DialogContainer } from "../../../components/dialogs/DialogContainer";
import { DialogTitle } from "../../../components/dialogs/DialogTitle";
import { Draggable } from "../../../components/dialogs/Draggable";
import Modal from "../../../components/dialogs/Modal";
import Email from "../../../components/Email";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTd from "../../../components/forms/FormTd";
import FormTr from "../../../components/forms/FormTr";
import { formatGender } from "../../../components/forms/GenderSelectBox";
import { formatLanguage } from "../../../components/forms/LanguageSelectBox";
import { getGenderFromTaxcode, isTaxCodeValid } from "../../../components/forms/TaxCode";
import DispPhoneNumber from "../../../components/phoneNumber/DispPhoneNumber";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Public } from "../../../listsSettings/public";
import { ErrorCode, IAction, UserPermissions } from "../../../services/types";
import {
  formatDateFromISOString,
  getCloseAction,
  getCurrentUrlAsSource,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions, uploadFile } from "../../../services/webapi";
import strings from "../../../strings";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import styles from "./DispForm.module.css";
import * as Fields from "./Fields";
import { PersonFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  createPictureUploadUrl,
  deletePerson,
  deletePicture,
  getPerson,
  getPlaceholderUrl,
  updatePicture,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<PersonFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const [permissions, setPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const [dialogOptions, setDialogOptions] = useState<{
    isOpen: boolean;
    removePicture: boolean;
    picture: string;
  }>({
    isOpen: false,
    removePicture: false,
    picture: state?.pictureUrl ?? "",
  });

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(Public.Persons.InternalName, null, abortController.signal);
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getPerson(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);
          result.data?.taxCode &&
            !isTaxCodeValid(result.data.taxCode) &&
            statusBar.addWarning(`Die Steuernummer <b>${result.data.taxCode}</b> ist ungültig.`);
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deletePerson(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const openEditPictureDialog = () => {
    options.clear();
    setDialogOptions((prevstate) => ({
      ...prevstate,
      isOpen: true,
      removePicture: false,
      picture: state?.pictureUrl ?? "",
    }));
  };

  const closeEditPictureDialog = () => {
    options.clear();
    setDialogOptions((prevstate) => ({ ...prevstate, isOpen: false }));
  };

  const { openFilePicker, ...options } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 2 },
    maxFileSize: 1,
  });

  const savePicture = async () => {
    if (state?.id) {
      const abortController = resetAbortController();
      const file = options.plainFiles[0];
      if (file) {
        const policyResult = await createPictureUploadUrl(abortController.signal, state.id, file.name);

        if (policyResult.error) {
          statusBar.addError(getString(policyResult.error));
          return;
        }
        if (policyResult.data) {
          const uploadResult = await uploadFile(policyResult.data, abortController.signal, file);

          if (uploadResult.error) {
            statusBar.addError(getString(uploadResult.error));
            return;
          }
          if (uploadResult.data) {
            const result = await updatePicture(state.id, file.name, abortController.signal);
            if (!abortController.signal.aborted) {
              result.error && statusBar.addError(getString(result.error));
              result.data &&
                setState(
                  (prevstate) =>
                    prevstate && {
                      ...prevstate,
                      pictureUrl: result.data,
                    },
                );
            }
          }
        }
      } else if (dialogOptions.removePicture) {
        const error = await deletePicture(state.id, abortController.signal);
        if (!abortController.signal.aborted) {
          error && statusBar.addError(getString(error));
          !error &&
            setState(
              (prevstate) =>
                prevstate && {
                  ...prevstate,
                  pictureUrl: dialogOptions.picture,
                },
            );
        }
      }
      closeEditPictureDialog();
    }
  };

  const clear = async () => {
    options.clear();
    const abortController = resetAbortController();
    const placeHolderUrl = await getPlaceholderUrl(abortController.signal, state?.gender ?? "male");
    if (placeHolderUrl.error) {
      statusBar.addError(getString(placeHolderUrl.error));
      return;
    }
    setDialogOptions((prevstate) => ({
      ...prevstate,
      removePicture: prevstate.removePicture || !!prevstate.picture,
      picture: placeHolderUrl.data ?? "",
    }));
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),

    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  useEffect(() => {
    if (state) {
      setListName(Public.Persons.Title);
      setPage(`${state.lastName} ${state.firstName}`);
    }
  }, [state]);

  const isClearDisabled = () => {
    const pic = options.filesContent[0];
    return (!pic && dialogOptions.picture.includes("placeholder")) || (pic && pic.name.includes("placeholder"));
  };

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Person"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />

          {dialogOptions.isOpen && (
            <Modal isOpen={dialogOptions.isOpen} onClose={closeEditPictureDialog}>
              <Draggable>
                <DialogContainer>
                  <DialogCloseIcon onClose={closeEditPictureDialog} />
                  <DialogTitle title="Neues Bild hochladen" />
                  <DialogBorder>
                    <div className={styles["image-container"]}>
                      {options.filesContent[0] ? (
                        <img
                          alt={options.filesContent[0].name}
                          src={options.filesContent[0].content}
                          className={styles.image}
                        />
                      ) : (
                        <img className={styles.image} src={dialogOptions.picture} />
                      )}
                    </div>
                    <div className={styles.buttons}>
                      <Button onclick={closeEditPictureDialog}>Abbrechen</Button>
                      <Button onclick={clear} disabled={isClearDisabled()}>
                        Bild entfernen
                      </Button>
                      <Button onclick={openFilePicker}>Durchsuchen</Button>
                      <Button onclick={savePicture}>Speichern</Button>
                    </div>
                  </DialogBorder>
                </DialogContainer>
              </Draggable>
            </Modal>
          )}

          <FormTable>
            <FormTr>
              <FormLabel>{Fields.salutation.title}</FormLabel>
              <FormData disp>{state?.salutation}</FormData>
              <FormTd rowSpan={12} props={{ position: "relative", width: "364px", height: "364px" }}>
                <div className={styles["image-edit-buttons"]}>
                  <div onClick={openEditPictureDialog}>
                    <Edit />
                  </div>
                </div>
                <div className={styles["image-container"]}>
                  <img className={styles.image} src={`${state.pictureUrl}?${Date.now()}`} />
                </div>
              </FormTd>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.language.title}</FormLabel>
              <FormData disp>{formatLanguage(state.language)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.firstName.title}</FormLabel>
              <FormData disp>{state?.firstName}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.lastName.title}</FormLabel>
              <FormData disp>{state?.lastName}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.taxCode.title}</FormLabel>
              <FormData disp>{state?.taxCode}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.gender.title}</FormLabel>
              <FormData disp>{formatGender(getGenderFromTaxcode(state?.taxCode))}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.birthplace.title}</FormLabel>
              <FormData disp>{state?.birthplace}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.birthdate.title}</FormLabel>
              <FormData disp>{formatDateFromISOString(state.birthdate)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.phone.title}</FormLabel>
              <FormData disp>
                <DispPhoneNumber value={state?.phone} />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.mobile.title}</FormLabel>
              <FormData disp>
                <DispPhoneNumber value={state?.mobile} />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.email.title}</FormLabel>
              <FormData disp>
                <Email value={state?.email} />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.street.title}</FormLabel>
              <FormData disp>{state?.street}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.zip.title}</FormLabel>
              <FormData disp>{state?.zip}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.city.title}</FormLabel>
              <FormData disp>{state?.city}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.province.title}</FormLabel>
              <FormData disp>{state?.province}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.country.title}</FormLabel>
              <FormData disp>{state?.country}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

import { FC, ReactNode } from "react";
import styles from "./Table.module.css";

interface TableProps {
  header?: ReactNode;
  body?: ReactNode;
}

const Table: FC<TableProps> = (props: TableProps) => {
  return (
    <table className={styles.table} cellPadding={0} cellSpacing={0}>
      {props.header && <thead>{props.header}</thead>}
      {props.body && <tbody>{props.body}</tbody>}
    </table>
  );
};

export default Table;

import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface CountryFull extends CreationInformationItem, CountryPost {
  id: number;
}

export interface CountryPost {
  name: string;
  code: string;
  phoneCode: string;
}

export interface CountryForm {
  code: string | null;
  name: string | null;
  phoneCode: string | null;
}

export const mapToPOST = (item: CountryForm): CountryPost => {
  return {
    code: item.code || "",
    name: item.name || "",
    phoneCode: item.phoneCode || "",
  };
};

export const validate = (
  state: CountryForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.code) {
    errors[Fields.code.fieldName] = strings.required;
  }
  if (!state.phoneCode) {
    errors[Fields.phoneCode.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

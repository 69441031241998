import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  getFilters,
  getItem,
  getItems,
  putReference,
  removeReferencedItems,
  searchItems,
} from "../../../services/webapi";
import { GroupFull } from "../groups/types";
import { UserFull } from "./types";

const usersUrl: string = `${administration}/${Administration.Lists.Users.InternalName}`;

export const getUser = async (id: string, signal: AbortSignal): Promise<Result<UserFull | null>> => {
  const url = `${usersUrl}/${id}`;
  return getItem<UserFull>(url, signal);
};

export const getUsers = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(usersUrl, pagination, signal);

export const getUsersBozen = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(`${usersUrl}/bozen`, pagination, signal);

export const getUsersBrixen = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(`${usersUrl}/brixen`, pagination, signal);

export const getUsersBruneck = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(`${usersUrl}/bruneck`, pagination, signal);

export const getUsersMeran = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(`${usersUrl}/meran`, pagination, signal);

export const getUsersSchlanders = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(`${usersUrl}/schlanders`, pagination, signal);

export const getUserFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(usersUrl, fieldName, signal);

export const getUserBozenFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${usersUrl}/bozen`, fieldName, signal);

export const getUserBrixenFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${usersUrl}/brixen`, fieldName, signal);

export const getUserBruneckFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${usersUrl}/bruneck`, fieldName, signal);

export const getUserMeranFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${usersUrl}/meran`, fieldName, signal);

export const getUserSchlandersFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${usersUrl}/schlanders`, fieldName, signal);

export const searchUsers = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => searchItems<UserFull>(usersUrl, searchValue, signal);

export const addGroup = (user: UserFull, group: GroupFull, signal: AbortSignal): Promise<CustomError | null> => {
  const url = `${usersUrl}/${user.id}/group/${group.id}`;
  return putReference(url, signal);
};

export const removeGroups = (
  user: UserFull,
  groups: GroupFull[],
  signal: AbortSignal,
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${usersUrl}/${user.id}/groups`;
  return removeReferencedItems(
    url,
    groups.map((x) => x.id),
    signal,
  );
};

export const getUserGroups = async (
  userId: string,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<GroupFull> | null>> =>
  getItems<GroupFull>(`${usersUrl}/${userId}/groups`, pagination, signal);

export const getUserGroupsFilters = (
  userId: string,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${usersUrl}/${userId}/groups`, fieldname, signal);

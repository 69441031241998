import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import { DialogOptions, SearchDialog } from "../../../components/dialogs/SearchDialog";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import {
  formatBoolean,
  getCloseAction,
  getCurrentUrlAsSource,
  getSelectedItems,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as GroupFields from "../groups/Fields";
import { GroupFull } from "../groups/types";
import { editForm as editFormGroup } from "../groups/urls";
import { searchGroups } from "../groups/webapi";
import * as UserFields from "../users/Fields";
import { UserFull } from "../users/types";
import { searchUsers } from "../users/webapi";
import * as Fields from "./Fields";
import { CurrentNavigationItemFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  addGroup,
  addUser,
  deleteCurrentNavigationItem,
  getCurrentNavigationItem,
  getCurrentNavigationItemGroups,
  getCurrentNavigationItemGroupsFilters,
  getCurrentNavigationItemUsers,
  getCurrentNavigationItemUsersFilters,
  removeGroups,
  removeUsers,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<CurrentNavigationItemFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();
  const [groupPermissions, setGroupPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.CurrentNavigationItems.InternalName,
      Administration.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [userPermissions, groupPermissions, result] = await Promise.all([
          getUserPermissions(
            Administration.Lists.Users.InternalName,
            Administration.InternalName,
            abortController.signal,
          ),
          getUserPermissions(
            Administration.Lists.Groups.InternalName,
            Administration.InternalName,
            abortController.signal,
          ),
          getCurrentNavigationItem(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setState(result.data);
            setUserPermissions(userPermissions);
            setGroupPermissions(groupPermissions);
            userPermissions?.listPermissions.includes("read-all") &&
              (await loadUsers(result.data.id, paginationUsers, abortController));
            groupPermissions?.listPermissions.includes("read-all") &&
              (await loadGroups(result.data.id, paginationGroups, abortController));
          }
        }
      }
    }
  };

  const userFields = [UserFields.name, UserFields.email];
  const groupFields = [GroupFields.name, GroupFields.description];

  const userDialogFields = [UserFields.name, UserFields.email];

  const [showUsersDialog, setShowUsersDialog] = useState(false);
  const usersDialogOptions: DialogOptions<UserFull> = {
    title: Administration.Lists.Users.Title,
    fields: userDialogFields,
    searchItems: (value: string, abortController: AbortController) => searchUsers(value, abortController.signal),
    onItemSelected: async (user: UserFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addUser(state, user, abortController.signal);
        await loadUsers(state.id, paginationUsers, abortController);
      }
    },
    cancelClicked: () => setShowUsersDialog(false),
    isOpen: showUsersDialog,
  };

  const groupDialogFields = [GroupFields.name, GroupFields.description];

  const [showGroupsDialog, setShowGroupsDialog] = useState(false);
  const groupsDialogOptions: DialogOptions<GroupFull> = {
    title: Administration.Lists.Groups.Title,
    fields: groupDialogFields,
    searchItems: (value: string, abortController: AbortController) => searchGroups(value, abortController.signal),
    onItemSelected: async (group: GroupFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addGroup(state, group, abortController.signal);
        await loadGroups(state.id, paginationGroups, abortController);
      }
    },
    cancelClicked: () => setShowGroupsDialog(false),
    isOpen: showGroupsDialog,
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteCurrentNavigationItem(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  const listIdUsers = "gni-users";
  const [users, setUsers] = useState<SearchResult<UserFull> | null>(null);

  const paginationUsers = getPagination(listIdUsers) || {
    ...DefaultPagination,
  };

  const loadUsers = async (
    currentNavigationItemId: number,
    pagination: Pagination,
    abortController: AbortController,
  ) => {
    const result = await getCurrentNavigationItemUsers(currentNavigationItemId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setUsers(result.data);
    }
  };

  const listIdGroups = "gni-groups";
  const [groups, setGroups] = useState<SearchResult<GroupFull> | null>(null);

  const paginationGroups = getPagination(listIdGroups) || {
    ...DefaultPagination,
  };

  const loadGroups = async (
    currentNavigationItemId: number,
    pagination: Pagination,
    abortController: AbortController,
  ) => {
    const result = await getCurrentNavigationItemGroups(currentNavigationItemId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setGroups(result.data);
    }
  };

  const addGroupAction: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowGroupsDialog(true),
    disabled:
      !groupPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const editGroupAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editFormGroup}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: getSelectedItems(groups).length !== 1 || !groupPermissions?.listPermissions.includes("edit-all"),
  };

  const deleteGroupAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(groups);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeGroups(state, selectedItems, abortController.signal);
          await loadGroups(state.id, paginationGroups, abortController);
        }
      }
    },
    disabled: getSelectedItems(groups).length === 0 || !permissions?.listPermissions.includes("edit-all"),
  };

  const groupActions = [addGroupAction, editGroupAction, deleteGroupAction];

  const addUserAction: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowUsersDialog(true),
    disabled:
      !userPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const removeUsersAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(users);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeUsers(state, selectedItems, abortController.signal);
          await loadUsers(state.id, paginationUsers, abortController);
        }
      }
    },
    disabled: getSelectedItems(users).length === 0 || !permissions?.listPermissions.includes("edit-all"),
  };

  const userActions = [addUserAction, removeUsersAction];

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.CurrentNavigationItems.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Eintrag aktuelles Menü"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.url.title}</FormLabel>
              <FormData disp>{state?.url}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.order.title}</FormLabel>
              <FormData disp>{state?.order}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.openNewTab.title}</FormLabel>
              <FormData disp>{formatBoolean(state?.openNewTab)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.parent.title}</FormLabel>
              <FormData disp>{state?.parent?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.schema.title}</FormLabel>
              <FormData disp>{state?.schema?.name}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          {showUsersDialog && (
            <SearchDialog
              title="Suche Benutzer"
              fields={usersDialogOptions.fields}
              searchItems={usersDialogOptions.searchItems}
              onItemSelected={usersDialogOptions.onItemSelected}
              cancelClicked={() => setShowUsersDialog(false)}
              isOpen={showUsersDialog}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdUsers}
            actions={userActions}
            title={Administration.Lists.Users.Title}
            fields={userFields}
            allowSelectRows
            reload={(userId, pagination) => loadUsers(userId, pagination, resetAbortController())}
            searchResult={users}
            getFilters={getCurrentNavigationItemUsersFilters}
            pagination={paginationUsers}
            updateItems={(items) =>
              users &&
              setUsers({
                ...users,
                items: items,
              })
            }
          />
          {showGroupsDialog && (
            <SearchDialog
              title="Suche Gruppe"
              fields={groupsDialogOptions.fields}
              searchItems={groupsDialogOptions.searchItems}
              onItemSelected={groupsDialogOptions.onItemSelected}
              cancelClicked={() => setShowGroupsDialog(false)}
              isOpen={showGroupsDialog}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdGroups}
            actions={groupActions}
            title={Administration.Lists.Groups.Title}
            fields={groupFields}
            allowSelectRows
            reload={(groupId, pagination) => loadGroups(groupId, pagination, resetAbortController())}
            searchResult={groups}
            getFilters={getCurrentNavigationItemGroupsFilters}
            pagination={paginationGroups}
            updateItems={(items) =>
              groups &&
              setGroups({
                ...groups,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Conventions } from "../../../listsSettings/conventions";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  conventions,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { AttachmentFull, AttachmentInsertPost, AttachmentUpdatePost } from "./types";

const attachmentsUrl: string = `${conventions}/${Conventions.Lists.Attachments.InternalName}`;
export const insertAttachment = async (
  attachment: AttachmentInsertPost,
  signal: AbortSignal,
): Promise<Result<AttachmentFull | null>> => insertItem(attachmentsUrl, attachment, signal);

export const updateAttachment = async (
  attachmentId: number,
  attachment: AttachmentUpdatePost,
  signal: AbortSignal,
): Promise<Result<AttachmentFull | null>> => updateItem(`${attachmentsUrl}/${attachmentId}`, attachment, signal);

export const deleteAttachment = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${attachmentsUrl}/${id}`, signal);

export const deleteAttachments = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(attachmentsUrl, ids, signal);

export const getAttachment = async (id: number, signal: AbortSignal): Promise<Result<AttachmentFull | null>> =>
  getItem<AttachmentFull>(`${attachmentsUrl}/${id}`, signal);

export const getAttachments = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<AttachmentFull> | null>> => getItems<AttachmentFull>(attachmentsUrl, pagination, signal);

export const getAttachmentFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(attachmentsUrl, fieldName, signal);

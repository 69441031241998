import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { dispForm as dispFormLists } from "../lists/urls";
import { dispForm as dispFormUsers } from "../users/urls";
import { ListUserPermissionFull } from "./types";

export const list: IListviewField<ListUserPermissionFull> = {
  title: "List",
  fieldName: "List.Title",
  isSortable: true,
  renderListValue: (item: ListUserPermissionFull) => (
    <Link href={`${dispFormLists}/${item.list?.id}`}>{item.list?.name}</Link>
  ),
};

export const user: IListviewField<ListUserPermissionFull> = {
  title: "Benutzer",
  fieldName: "User.Title",
  isSortable: true,
  renderListValue: (item: ListUserPermissionFull) => (
    <Link href={`${dispFormUsers}/${item.user?.id}`}>{item.user?.name}</Link>
  ),
};

export const permission: IListviewField<ListUserPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.name",
  isSortable: true,
  renderListValue: (item: ListUserPermissionFull) => item.permission?.name,
};

export const permissionDescription: IListviewField<ListUserPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.description",
  isSortable: true,
  renderListValue: (item: ListUserPermissionFull) => item.permission?.description,
};

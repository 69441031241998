import { useEffect, useState } from "react";
import CheckBox from "../../../components/forms/CheckBox";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import SelectBox from "../../../components/forms/SelectBox";
import { DefaultPagination } from "../../../components/listview/Pagination";
import CustomLexicalEditor from "../../../components/richTextEditor/CustomLexicalEditor";
import { Conventions } from "../../../listsSettings/conventions";
import { ValidationErrors } from "../../../services/types";
import { CategoryFull } from "../categories/types";
import { getCategories } from "../categories/webapi";
import * as PartnerFields from "../partners/Fields";
import { searchPartners } from "../partners/webapi";
import * as Fields from "./Fields";
import { ConventionForm } from "./types";

const NewEditTable = ({
  state,
  setState,
  errors,
}: {
  state: ConventionForm;
  setState: React.Dispatch<React.SetStateAction<ConventionForm | undefined>>;
  errors: ValidationErrors;
}) => {
  const [categories, setCategories] = useState<CategoryFull[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const categoriesResult = await getCategories(DefaultPagination, abortController.signal);
      categoriesResult.data && setCategories(categoriesResult.data.items);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.title.title}</FormLabel>
        <FormData fitContent>
          <Input
            value={state.title}
            onChange={(value) =>
              setState({
                ...state,
                title: value,
              })
            }
          />
          <FormError error={errors[Fields.title.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.description.title}</FormLabel>
        <FormData fitContent>
          <CustomLexicalEditor
            initialValue={state.description}
            size={{ width: 1000 }}
            alignemnts
            historyButtons
            fontBackgroundColorPicker
            fontColorPicker
            fontSizeSelector
            headings
            moreOptions={{ insertRule: true, insertTable: true }}
            textFormatsInDropDown={["clear", "code", "strikethrough", "subscript", "superscript"]}
            hideFloatingToolbar
            onChange={(json, html) =>
              setState({
                ...state,
                description: json,
                descriptionHtml: html,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.category.title}</FormLabel>
        <FormData fitContent>
          <SelectBox
            value={state.category}
            onChange={(value) =>
              setState({
                ...state,
                category: value,
              })
            }
            options={categories}
            getId={(item) => `${item.id}`}
            getValue={(item) => item.name}
          />
          <FormError error={errors[Fields.category.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.partner.title}</FormLabel>
        <FormData fitContent>
          <Lookup
            disabled={!!state.partner?.id}
            value={state.partner?.name ?? ""}
            onChange={(partner) =>
              setState({
                ...state,
                partner: partner,
              })
            }
            searchItems={searchPartners}
            searchDialogTitle={Conventions.Lists.Partners.Title}
            searchDialogFields={[PartnerFields.name, PartnerFields.city]}
            getTitle={(item) => item.name}
          />
          <FormError error={errors[Fields.partner.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.online.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.online}
            onChange={(value) =>
              setState({
                ...state,
                online: value,
              })
            }
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import { IListviewField } from "../../../services/types";
import { getChapterTitle } from "../../members/chapters/types";
import { ChapterElectionFull } from "./types";

export const legislature: IListviewField<ChapterElectionFull> = {
  title: "Legislatur",
  fieldName: "legislature.year",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterElectionFull) => item.legislature.year,
};

export const chapter: IListviewField<ChapterElectionFull> = {
  title: "Ortsgruppe",
  fieldName: "chapter.title",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterElectionFull) => getChapterTitle(item.chapter.membersChapter),
};

export const electionDate: IListviewField<ChapterElectionFull> = {
  title: "Wahltag",
  fieldName: "electionDate",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ChapterElectionFull) => item.electionDate,
};

import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { Link as ReactLink } from "react-router-dom";
import useStatusBarState from "../hooks/useStatusBar";
import { downloadFileFromUrl } from "../services/webapi";
import { getString } from "./errors/Errors";
import styles from "./Link.module.css";

interface LinkProps {
  href: string;
  openInNewTab?: boolean;
  download?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  external?: boolean;
  underlined?: boolean;
}

const Link: FC<PropsWithChildren<LinkProps>> = (props: PropsWithChildren<LinkProps>) => {
  const statusBar = useStatusBarState();

  const href = props.external && !props.href.startsWith("http") ? `http://${props.href}` : props.href;
  const target = props.openInNewTab ? "_blank" : "_self";

  const onClick = (event: React.MouseEvent) => {
    if (props.onClick || props.download) {
      props.onClick && props.onClick(event);
      if (props.download) {
        const filename = props.href.split("/").pop();
        if (filename) {
          (async () => {
            const result = await downloadFileFromUrl(props.href, filename);
            result.error && statusBar.addError(result.error.message || getString(result.error));
          })();
        }
      }
      event.preventDefault();
    }
    event.stopPropagation();
  };

  const classnames = classNames({ [styles.underlined]: props.underlined });

  return props.external ? (
    <a onClick={onClick} href={href} target={target} className={classnames}>
      {props.children || props.href}
    </a>
  ) : (
    <ReactLink onClick={onClick} to={href} target={target} className={classnames}>
      {props.children || props.href}
    </ReactLink>
  );
};

export default Link;

import { FC, useState } from "react";
import SelectBox, { SelectBoxProps } from "./SelectBox";

const genders = ["male", "female", "entity"] as const;
type Gender = (typeof genders)[number];

interface GenderOption {
  id: Gender;
  display: string;
}

const allGenders: GenderOption[] = [
  {
    id: "male",
    display: "Männlich",
  },
  {
    id: "female",
    display: "Weiblich",
  },
  {
    id: "entity",
    display: "Juristische Person",
  },
];

const allGendersMap = allGenders.reduce((rv: { [key: string]: GenderOption }, x) => {
  rv[x.id] = x;
  return rv;
}, {});

export const formatGender = (gender?: string | null) => (gender ? allGendersMap[gender]?.display : gender);

interface GenderSelectBoxProps {
  value: Gender | null;
  allowEmpty?: true;
  onChange: (x: Gender | null) => void;
  gendersToShow: Gender[];
}

const GenderSelectBox: FC<GenderSelectBoxProps> = (props: GenderSelectBoxProps) => {
  const [showOhne] = useState(props.allowEmpty || !props.value);

  const options: GenderOption[] = allGenders
    .filter((x) => props.gendersToShow.indexOf(x.id) >= 0)
    .sort((a, b) => props.gendersToShow.indexOf(a.id) - props.gendersToShow.indexOf(b.id));
  const optionsMap = options.reduce((rv: { [key: string]: GenderOption }, x) => {
    rv[x.id] = x;
    return rv;
  }, {});

  const selectBoxProps: SelectBoxProps<GenderOption> = {
    options: options,
    value: props.value ? optionsMap[props.value] : null,
    onChange: (x: GenderOption | null) => props.onChange(x?.id ?? null),
    getId: (x: GenderOption) => x.id,
    getValue: (x: GenderOption) => x.display,
    allowEmpty: showOhne,
  };

  return <SelectBox {...selectBoxProps}></SelectBox>;
};

export default GenderSelectBox;

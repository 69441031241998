import { FC, useEffect } from "react";
import useStatusBarState from "../hooks/useStatusBar";
import Error from "../svg/Error.svg?react";
import Info from "../svg/Info.svg?react";
import Success from "../svg/Success.svg?react";
import Warning from "../svg/Warning.svg?react";
import styles from "./StatusBar.module.css";

const renderMessage = (messages: [string, boolean][], Icon: FC, title: string) => {
  return messages.map(([text, _], index) => (
    <div key={`${title}_${index}`} className={styles.message}>
      <span className={styles["message-icon"]}>
        <Icon />
      </span>
      <span className={styles["message-title"]}>{title}:</span>
      <span className={styles["message-text"]} dangerouslySetInnerHTML={{ __html: text }}></span>
    </div>
  ));
};

const StatusBar: FC = () => {
  const context = useStatusBarState();

  useEffect(() => {}, [
    context.errors.length + context.warnings.length + context.successes.length + context.infos.length,
  ]);

  return (
    <div className={styles["status-bar"]}>
      {context.errors.length > 0 && (
        <div className={styles.error}>{renderMessage(context.errors, Error, "Fehler")}</div>
      )}
      {context.warnings.length > 0 && (
        <div className={styles.warning}>{renderMessage(context.warnings, Warning, "Achtung")}</div>
      )}
      {context.successes.length > 0 && (
        <div className={styles.success}>{renderMessage(context.successes, Success, "OK")}</div>
      )}
      {context.infos.length > 0 && <div className={styles.info}>{renderMessage(context.infos, Info, "Info")}</div>}
    </div>
  );
};

export default StatusBar;

import { FC, useEffect } from "react";
import useGlobalState from "../../hooks/useGlobalState";
import { ErrorCode, ErrorType } from "../../services/types";
import strings from "../../strings";
import Forbidden from "./Forbidden";
import Generic from "./Generic";
import NotFound from "./NotFound";

interface ErrorDetail {
  type: ErrorType;
  field: string;
  message: string;
}

export interface CustomError {
  code: ErrorCode;
  message: string;
  details?: ErrorDetail[] | null;
}

export const getTitleString = (errorCode?: ErrorCode) => {
  switch (errorCode) {
    case 403:
      return "403:Zugriff verweigert";
    case 404:
      return "404:Nicht gefunden";
    default:
      return "Fehler";
  }
};

export const isConflict = (customError: CustomError) => customError.code == 409;

export const getString = (customError: CustomError) => {
  switch (customError.code) {
    case 403:
      return strings.forbiddenText;
    case 404:
      return strings.notFoundText;
    default:
      return strings.genericText;
  }
};

interface ErrorProps {
  errorCode: ErrorCode;
}

const Errors: FC<ErrorProps> = ({ errorCode }: ErrorProps) => {
  const setPage = useGlobalState((state) => state.setPage);

  useEffect(() => {
    setPage(getTitleString(errorCode));
  }, []);

  return errorCode == 403 ? <Forbidden /> : errorCode == 404 ? <NotFound /> : <Generic />;
};

export default Errors;

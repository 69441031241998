import Email from "../../../components/Email";
import Link from "../../../components/Link";
import Number from "../../../components/Number";
import DispPhoneNumber from "../../../components/phoneNumber/DispPhoneNumber";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { Company } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<Company> & IDialogField<Company> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: Company) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: Company) => item.name,
};

export const companyNumber: IListviewField<Company> & IDialogField<Company> = {
  title: "MGB-Nummer",
  fieldName: "id",
  isSortable: true,
  renderListValue: (item: Company) => <Number value={item.id} />,
  renderDialogValue: (item: Company) => item.id,
};

export const member: IListviewField<Company> & IDialogField<Company> = {
  title: "Mitglied",
  fieldName: "member.name",
  isSortable: true,
  renderListValue: (item: Company) => item.member.name,
  renderDialogValue: (item: Company) => item.member.name,
};

export const memberNumber: IListviewField<Company> & IDialogField<Company> = {
  title: "MG-Nummer",
  fieldName: "member.id",
  isSortable: true,
  renderListValue: (item: Company) => <Number value={item.member.id} />,
  renderDialogValue: (item: Company) => item.member.id,
};

export const city: IListviewField<Company> = {
  title: "Ortschaft",
  fieldName: "address.city",
  isSortable: true,
  renderListValue: (item: Company) => item.address.city,
};

export const street: IListviewField<Company> = {
  title: "Straße",
  fieldName: "address.street",
  isSortable: true,
  renderListValue: (item: Company) => item.address.street,
};

export const zip: IListviewField<Company> = {
  title: "PLZ",
  fieldName: "address.zip",
  isSortable: true,
  renderListValue: (item: Company) => item.address.zip,
};

export const country: IListviewField<Company> = {
  title: "Land",
  fieldName: "address.country",
  isSortable: true,
  renderListValue: (item: Company) => item.address.country,
};

export const province: IListviewField<Company> = {
  title: "Provinz",
  fieldName: "address.province",
  isSortable: true,
  renderListValue: (item: Company) => item.address.province,
};

export const email: IListviewField<Company> = {
  title: "E-Mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: Company) => <Email value={item.email} />,
};

export const phone: IListviewField<Company> = {
  title: "Telefon",
  fieldName: "phone",
  isSortable: true,
  renderListValue: (item: Company) => <DispPhoneNumber value={item.phone} />,
};

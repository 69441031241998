import Email from "../../../components/Email";
import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { GroupFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<GroupFull> & IDialogField<GroupFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: GroupFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: GroupFull) => item.name,
};

export const description: IListviewField<GroupFull> & IDialogField<GroupFull> = {
  title: "Beschreibung",
  fieldName: "description",
  renderListValue: (item: GroupFull) => item.description,
  renderDialogValue: (item: GroupFull) => item.description,
};

export const email: IListviewField<GroupFull> = {
  title: "E-Mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: GroupFull) => <Email value={item.email} />,
};

export const isActiveDirectoryGroup: IListviewField<GroupFull> = {
  title: "Ist AD-Gruppe",
  fieldName: "isActiveDirectoryGroup",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: GroupFull) => formatBoolean(item.isActiveDirectoryGroup),
};

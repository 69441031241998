import { FC, useEffect, useState } from "react";
import { getString } from "../../../components/errors/Errors";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Lookup from "../../../components/forms/Lookup";
import SelectBox from "../../../components/forms/SelectBox";
import { DefaultPagination } from "../../../components/listview/Pagination";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ValidationErrors } from "../../../services/types";
import * as GroupFields from "../groups/Fields";
import { searchGroups } from "../groups/webapi";
import { PermissionFull } from "../permissions/types";
import { getPermissions } from "../permissions/webapi";
import * as Fields from "./Fields";
import { ListGroupPermissionForm } from "./types";

interface NewEditTableProps {
  state: ListGroupPermissionForm;
  setState: React.Dispatch<React.SetStateAction<ListGroupPermissionForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const result = await getPermissions(DefaultPagination, abortController.signal);
      if (result.error) {
        console.error(result.error);
        statusBar.addError(getString(result.error));
      }
      result.data && setPermissions(result.data.items);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  const statusBar = useStatusBarState();
  const [permissions, setPermissions] = useState<PermissionFull[]>([]);

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.group.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.group?.id}
            value={state.group?.name ?? ""}
            onChange={(group) =>
              setState({
                ...state,
                group: group,
              })
            }
            searchItems={searchGroups}
            searchDialogTitle={Administration.Lists.Groups.Title}
            searchDialogFields={[GroupFields.name, GroupFields.description]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.group.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.permission.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.permission}
            onChange={(permission) =>
              setState({
                ...state,
                permission: permission,
              })
            }
            options={permissions}
            getId={(item) => item.name ?? ""}
            getValue={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.permission.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import { BrowserCacheLocation, Configuration, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "c09036af-fd62-4db6-b27a-682881913a2a",
    authority: "https://login.microsoftonline.com/9c0a3ae0-3f12-4197-a76d-820fd8d9fe2f",
    knownAuthorities: ["https://login.microsoftonline.com"],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export const msalApp = new PublicClientApplication(msalConfig);

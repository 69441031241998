import { Members } from "../../../listsSettings/members";
import { Result, SearchResult } from "../../../services/types";
import { members, searchItems } from "../../../services/webapi";
import { Chapter } from "./types";

const chaptersUrl: string = `${members}/${Members.Lists.Chapters.InternalName}`;

export const searchChapters = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<Chapter> | null>> => searchItems<Chapter>(chaptersUrl, searchValue, signal);

import {
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  getDefaultCodeLanguage,
  getLanguageFriendlyName,
} from "@lexical/code";
import { $isLinkNode } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
//import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getSelectionStyleValueForProperty, $isAtNodeEnd, $patchStyleText } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  CommandListenerPriority,
  ElementNode,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from "lexical";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ColorPicker from "../../colorPicker/ColorPicker";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "../nodes/HorizontalRuleNode";
import { BlockType, TextFormatButtonType, isBlockType } from "../types";
import BlockTypeDropDown from "../ui/BlockTypeDropDown";
import Divider from "../ui/Divider";
import DropDown from "../ui/DropDown";
import DropDownItem from "../ui/DropDownItem";
import EditorHistory from "../ui/EditorHistory";
import FontDropDown from "../ui/FontDropDown";
import InsertTableDialog from "../ui/InsertTableDialog";
import TextAlignment from "../ui/TextAlignment";
import TextFormattingTools from "../ui/TextFormattingTools";
import FloatingLinkEditor from "../utils/FloatingLinkEditor";
import styles from "./ToolbarPlugin.module.css";

export interface ToolbarPluginProps {
  textFormatsInDropDown?: TextFormatButtonType[];
  historyButtons?: boolean;
  headings?: boolean;
  fontFamilySelector?: boolean;
  fontSizeSelector?: boolean;
  fontColorPicker?: boolean;
  fontBackgroundColorPicker?: boolean;
  moreOptions?: {
    insertRule?: boolean;
    insertTable?: boolean;
  };
  alignemnts?: boolean;
}

const ToolbarPlugin: FC<ToolbarPluginProps> = (props) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [showModal, setShowModal] = useState(false);
  const [fontSize, setFontSize] = useState<string | null>(null);
  const [fontColor, setFontColor] = useState<string | null>(null);
  const [bgColor, setBgColor] = useState<string | null>(null);
  const [fontFamily, setFontFamily] = useState<string | null>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [blockType, setBlockType] = useState<BlockType>("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(null);
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const getSelectedNode = (selection: RangeSelection): TextNode | ElementNode => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  };

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor],
  );

  const onFontColorSelect = useCallback(
    (value: string | null) => applyStyleText({ color: value ?? "" }),
    [applyStyleText],
  );

  const onBgColorSelect = useCallback(
    (value: string | null) => applyStyleText({ "background-color": value ?? "" }),
    [applyStyleText],
  );

  const priority: CommandListenerPriority = 1;

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (isBlockType(type)) {
            setBlockType(type);
            if ($isCodeNode(element)) {
              setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
            }
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsSubscript(selection.hasFormat("subscript"));
      setIsSuperscript(selection.hasFormat("superscript"));
      setIsCode(selection.hasFormat("code"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      setIsLink($isLinkNode(parent) || $isLinkNode(node));

      // Handle buttons
      const size = $getSelectionStyleValueForProperty(selection, "font-size");
      setFontSize(size ?? null);

      const color = $getSelectionStyleValueForProperty(selection, "color");
      setFontColor(color ?? null);

      const backgroundColor = $getSelectionStyleValueForProperty(selection, "background-color");
      setBgColor(backgroundColor ?? null);

      const family = $getSelectionStyleValueForProperty(selection, "font-family");
      setFontFamily(family ?? null);
    }
  }, [editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _) => {
          updateToolbar();
          return false;
        },
        priority,
      ),
    );
  }, [editor, updateToolbar]);

  const getCodeLanguageOptions = (): [string, string][] => {
    const options: [string, string][] = [];

    for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
      options.push([lang, friendlyName]);
    }

    return options;
  };

  const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions();

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [editor, selectedElementKey],
  );

  return (
    <div className={styles.toolbar} ref={toolbarRef}>
      {props.historyButtons && (
        <>
          <EditorHistory editor={editor} onUpdate={updateToolbar} />
          <Divider type="toolbar" />
        </>
      )}
      {props.headings && isBlockType(blockType) && (
        <>
          <BlockTypeDropDown editor={editor} blockType={blockType} />
          <Divider type="toolbar" />
        </>
      )}
      {blockType === "code" ? (
        <DropDown label={getLanguageFriendlyName(codeLanguage)}>
          {CODE_LANGUAGE_OPTIONS.map(([value, name]) => (
            <DropDownItem onClick={() => onCodeLanguageSelect(value)} key={value} title={name} />
          ))}
        </DropDown>
      ) : (
        <>
          {props.fontFamilySelector && (
            <FontDropDown type={"font-family"} value={fontFamily || "Schriftart"} editor={editor} />
          )}
          {props.fontSizeSelector && <FontDropDown type={"font-size"} value={fontSize || "Größe"} editor={editor} />}
          <TextFormattingTools
            options={props.textFormatsInDropDown}
            editor={editor}
            isBold={isBold}
            isCode={isCode}
            isItalic={isItalic}
            isLink={isLink}
            isStrikethrough={isStrikethrough}
            isSubscript={isSubscript}
            isSuperscript={isSuperscript}
            isUnderline={isUnderline}
          ></TextFormattingTools>
          {props.fontColorPicker && (
            <DropDown stopCloseOnClickSelf={true} title="Schriftfarbe" buttonIconClassName={styles["font-color"]}>
              <ColorPicker color={fontColor} onChange={onFontColorSelect} title="Schriftfarbe" />
            </DropDown>
          )}
          {props.fontBackgroundColorPicker && (
            <DropDown stopCloseOnClickSelf={true} title="Textvorhebungsfarbe" buttonIconClassName={styles["bg-color"]}>
              <ColorPicker color={bgColor} onChange={onBgColorSelect} title="Textvorhebungsfarbe" />
            </DropDown>
          )}
          {props.textFormatsInDropDown?.length && (
            <DropDown
              stopCloseOnClickSelf
              title="Zusätzliche Textformattierung"
              buttonIconClassName={styles["dropdown-more"]}
            >
              <TextFormattingTools
                options={props.textFormatsInDropDown}
                isDropDown
                editor={editor}
                isBold={isBold}
                isCode={isCode}
                isItalic={isItalic}
                isLink={isLink}
                isStrikethrough={isStrikethrough}
                isSubscript={isSubscript}
                isSuperscript={isSuperscript}
                isUnderline={isUnderline}
              ></TextFormattingTools>
            </DropDown>
          )}
          {isLink && createPortal(<FloatingLinkEditor editor={editor} priority={priority} />, document.body)}
          {props.moreOptions && (
            <>
              <Divider type="toolbar" />
              <DropDown label="Einfügen" title="Komponenten einfügen" buttonIconClassName={styles.plus}>
                <DropDownItem
                  onClick={() => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)}
                  title="Horizontale Linie"
                  icon={{ className: styles["horizontal-rule"], isOpaque: true }}
                />
                <DropDownItem
                  onClick={() => setShowModal(true)}
                  title="Tabelle"
                  icon={{ className: styles.table, isOpaque: true }}
                />
              </DropDown>
            </>
          )}
        </>
      )}

      {props.alignemnts && (
        <>
          <Divider type="toolbar" />
          <TextAlignment editor={editor} />
        </>
      )}
      {showModal && <InsertTableDialog editor={editor} onClose={() => setShowModal(false)} toolbarRef={toolbarRef} />}
    </div>
  );
};

export default ToolbarPlugin;

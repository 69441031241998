import { Item, MenuOptionValue, Result } from "../../services/types";
import Listview, { ListviewBaseProps } from "./Listview";
import { Pagination } from "./Pagination";

interface ReferencedItemsViewProps<T extends Item, V> extends ListviewBaseProps<T> {
  itemId: V;
  reload: (id: V, pagination: Pagination) => void;
  getFilters: (id: V, fieldname: string, abortSignal: AbortSignal) => Promise<Result<MenuOptionValue[] | null>>;
}

const ReferencedItemsView = <T extends Item, V>(props: ReferencedItemsViewProps<T, V>) => {
  return (
    <Listview
      {...props}
      reload={(pagination) => props.reload(props.itemId, pagination)}
      getFilters={(fieldName, abortSignal) => props.getFilters(props.itemId, fieldName, abortSignal)}
    />
  );
};

export default ReferencedItemsView;

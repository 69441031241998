import { ChangeEventHandler, DragEventHandler, FC } from "react";
import useStatusBarState from "../../hooks/useStatusBar";
import styles from "./FileUpload.module.css";

const checkMatchesExtension = (extensions: string[], filename: string) =>
  extensions.some((extension) => filename.endsWith("." + extension));

interface FileUploadProps {
  filename?: string | null;
  onUpload: (file: File) => void;
  acceptFileExtensions?: string[];
  maxSize?: number;
  placeholder?: string;
}

const FileUpload: FC<FileUploadProps> = ({ filename, acceptFileExtensions, onUpload, maxSize, placeholder }) => {
  const statusBar = useStatusBarState();

  const id = Math.random().toString(36).slice(2, 7);

  const handleFile = (file: File | undefined) => {
    if (file) {
      const isAllowedExtension = !acceptFileExtensions || checkMatchesExtension(acceptFileExtensions, file.name);

      if (!isAllowedExtension) {
        statusBar.addError("Falscher Dateityp. Laden Sie einen der erlaubten Dateitypen hoch.");
        return;
      }

      if (maxSize && file.size > maxSize) {
        statusBar.addError(`Die Datei überschreitet die Maximalgröße von ${maxSize / 1024} KiB.`);
        return;
      }

      onUpload(file);
    }
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = ({ target: { files } }) => files && handleFile(files[0]);

  const onDrop: DragEventHandler<HTMLDivElement> = (event) => {
    const file = event.dataTransfer.files[0];
    if (file) {
      event.preventDefault();
      handleFile(file);
    }
  };

  return (
    <div className={styles.container} onDragOver={(event) => event.preventDefault()} onDrop={onDrop}>
      <input
        id={id}
        className={styles.input}
        onChange={onFileChange}
        accept={acceptFileExtensions?.map((value) => "." + value).join(",")}
        type="file"
      />
      <label className={styles.label} htmlFor={id}>
        {filename ?? <i>{placeholder ?? "Datei einfügen"}</i>}
      </label>
    </div>
  );
};

export default FileUpload;

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Conventions } from "../../../listsSettings/conventions";
import { CreationInformation, ErrorCode, IAction, ValidationErrors } from "../../../services/types";
import { getCancelAction, getSaveAction, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import * as Fields from "./Fields";
import { AttachmentForm, validate } from "./types";
import { dispForm } from "./urls";
import { getAttachment, updateAttachment } from "./webapi";

import { UserPermissions } from "../../../services/types";

const EditForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const statusBar = useStatusBarState();
  const [state, setState] = useState<AttachmentForm>();
  const [crationInformation, setCreationInformation] = useState<CreationInformation>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Conventions.Lists.Attachments.InternalName,
      Conventions.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canEdit = permissions.schemaPermission && permissions.listPermissions.includes("edit-all");
      !canEdit && setErrorCode(403);
      if (canEdit) {
        const result = await getAttachment(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          if (result.data) {
            setCreationInformation(result.data);
            setState(result.data);
          }
        }
      }
    }
  };

  const save = async () => {
    if (id && state && validate(state, setErrors) && state.displayName) {
      const abortController = resetAbortController();
      const result = await updateAttachment(Number(id), { displayName: state.displayName }, abortController.signal);

      if (!abortController.signal.aborted) {
        if (result.error) {
          if (result.error.code == 409 || result.error.code == 422) {
            const newErrors = result.error.details?.reduce((rv: ValidationErrors, d) => {
              rv[d.field] = strings.getMessageFromTypeError(d.type);
              return rv;
            }, {});

            newErrors && setErrors(newErrors);
          } else {
            statusBar.addError(getString(result.error));
          }
        }
        result.data && navigateTo(getSourceFromUrl() ?? `${dispForm}/${result.data.id}`);
      }
    }
  };

  const actions: IAction[] = [getSaveAction(save), getCancelAction(navigateTo, `${dispForm}/${id}`)];

  useEffect(() => {
    if (permissions) {
      setListName(Conventions.Lists.Attachments.Title);
      setPage(state?.displayName ?? null);
    }
  }, [permissions]);

  return (
    <>
      {permissions &&
        (state && !errorCode ? (
          <>
            <Title text={"Anhang"} />
            <Actions actions={actions} />
            <FormTable>
              <FormTr>
                <FormLabel>{Fields.file.title}</FormLabel>
                <FormData disp>{state.filename}</FormData>
              </FormTr>
              <FormTr>
                <FormLabel>{Fields.displayname.title}</FormLabel>
                <FormData>
                  <Input
                    value={state.displayName}
                    onChange={(value) =>
                      setState({
                        ...state,
                        displayName: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.displayname.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel>{Fields.convention.title}</FormLabel>
                <FormData disp>{state.convention?.title}</FormData>
              </FormTr>
            </FormTable>
            <CreatedModifiedInfo info={crationInformation} />
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default EditForm;

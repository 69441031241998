import { FC, useEffect } from "react";
import useGlobalState from "../../hooks/useGlobalState";
import strings from "../../strings";
import { getTitleString } from "./Errors";

const Generic: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);

  useEffect(() => {
    setPage(getTitleString());
  }, []);

  return (
    <>
      <div>{strings.genericText}</div>
      <div>&nbsp;</div>
      <div>Bitte wenden Sie sich an den Administrator dieser Website.</div>
    </>
  );
};

export default Generic;

import { FORMAT_ELEMENT_COMMAND, INDENT_CONTENT_COMMAND, LexicalEditor, OUTDENT_CONTENT_COMMAND } from "lexical";
import { FC } from "react";
import Divider from "./Divider";
import DropDown from "./DropDown";
import DropDownItem from "./DropDownItem";
import styles from "./TextAlignment.module.css";

interface TextAlignmentProps {
  editor: LexicalEditor;
}

const TextAlignment: FC<TextAlignmentProps> = ({ editor }) => {
  return (
    <DropDown label="Text ausrichten" buttonIconClassName={styles["left-align"]} title="Textausrichtungen">
      <DropDownItem
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")}
        title="Text linksbündig ausrichten"
        icon={{ className: styles["left-align"] }}
      />
      <DropDownItem
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")}
        title="Text zentrieren"
        icon={{ className: styles["center-align"] }}
      />
      <DropDownItem
        icon={{ className: styles["right-align"] }}
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")}
        title="Text rechtsbündig ausrichten"
      />
      <DropDownItem
        icon={{ className: styles["justify-align"] }}
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")}
        title="Blocksatz"
      />
      <Divider type="dropdown" />
      <DropDownItem
        icon={{ className: styles["indent"] }}
        onClick={() => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)}
        title="Einzug vergößern"
      />
      <DropDownItem
        icon={{ className: styles["outdent"] }}
        onClick={() => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)}
        title="Einzug verkleinern"
      />
    </DropDown>
  );
};

export default TextAlignment;

import { isValid, parseISO } from "date-fns";
import { isValidPhoneNumber } from "libphonenumber-js";
import { isTaxCodeValid } from "../../../components/forms/TaxCode";
import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import { isEmailValid } from "../../../services/utils";
import strings from "../../../strings";
import * as Fields from "./Fields";

export const getItemTitle = (item?: PersonFull | null): string =>
  item ? `${item?.lastName} ${item?.firstName}`.trim() : "";

export interface PersonFull extends CreationInformationItem {
  id: number;
  lastName: string;
  firstName: string;
  taxCode: string;
  salutation: string | null;
  email: string | null;
  mobile: string | null;
  phone: string | null;
  zip: string | null;
  street: string | null;
  city: string | null;
  country: string | null;
  province: string | null;
  birthplace: string | null;
  birthdate: string | null;
  language: string | null;
  gender: "male" | "female" | null;
  pictureUrl: string | null;
}

export interface PersonForm {
  lastName: string | null;
  firstName: string | null;
  taxCode: string | null;
  salutation: string | null;
  email: string | null;
  mobile: string | null;
  phone: string | null;
  zip: string | null;
  street: string | null;
  city: string | null;
  country: string | null;
  province: string | null;
  birthplace: string | null;
  birthdate: string | null;
  gender: "male" | "female" | null;
  language: string | null;
}

export interface PersonPost {
  lastName: string;
  firstName: string;
  taxCode: string;
  salutation: string | null;
  email: string | null;
  mobile: string | null;
  phone: string | null;
  zip: string | null;
  street: string | null;
  city: string | null;
  country: string | null;
  province: string | null;
  birthplace: string | null;
  birthdate: string | null;
  gender: "male" | "female" | null;
  language: string | null;
}

export const mapToPOST = (item: PersonForm): PersonPost => {
  return {
    firstName: item.firstName ?? "",
    lastName: item.lastName ?? "",
    taxCode: item.taxCode ?? "",
    salutation: item.salutation,
    email: item.email,
    phone: item.phone,
    mobile: item.mobile,
    street: item.street,
    zip: item.zip,
    city: item.city,
    country: item.country,
    province: item.province,
    birthplace: item.birthplace,
    birthdate: item.birthdate ?? null,
    gender: item.gender,
    language: item.language,
  };
};

export const validate = (
  state: PersonForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.lastName) {
    errors[Fields.lastName.fieldName] = strings.required;
  }
  if (!state.firstName) {
    errors[Fields.firstName.fieldName] = strings.required;
  }
  if (state.email && !isEmailValid(state.email)) {
    errors[Fields.email.fieldName] = strings.invalidText;
  }
  if (state.phone && !isValidPhoneNumber(state.phone)) {
    errors[Fields.phone.fieldName] = strings.invalidText;
  }
  if (state.mobile && !isValidPhoneNumber(state.mobile)) {
    errors[Fields.mobile.fieldName] = strings.invalidText;
  }
  if (state.birthdate) {
    const date = parseISO(state.birthdate);
    !isValid(date) && (errors[Fields.birthdate.fieldName] = strings.invalidText);
  }
  if (!state.taxCode) {
    errors[Fields.taxCode.fieldName] = strings.required;
  } else if (!isTaxCodeValid(state.taxCode)) {
    errors[Fields.taxCode.fieldName] = "Die Angegebene Struernummer ist ungültig";
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

import { FC, useEffect, useState } from "react";
import Cross from "../../svg/Cross.svg?react";
import Search from "../../svg/Search.svg?react";
import styles from "./ListviewSearchBox.module.css";

interface ListviewSearchBoxProps {
  defaultValue?: string;
  search: (value: string) => void;
  clearSearch: () => void;
}

const ListviewSearchBox: FC<ListviewSearchBoxProps> = (props: ListviewSearchBoxProps) => {
  const [searchConsumed, setSearchConsumed] = useState(!!props.defaultValue);
  const [searchKey, setSearchKey] = useState(props.defaultValue ?? "");

  useEffect(() => {
    setSearchKey(props.defaultValue ?? "");
  }, [props.defaultValue]);

  const search = () => {
    setSearchConsumed(!!searchKey);
    (searchKey || (!searchKey && props.defaultValue)) && props.search(searchKey);
  };

  const clear = () => {
    setSearchConsumed(false);
    props.defaultValue && props.search("");
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      searchKey ? search() : clear();
    } else if (searchConsumed) {
      setSearchConsumed(false);
    }
  };

  return (
    <div className={styles["search-box-container"]}>
      <input
        type="text"
        className={styles["search-box"]}
        value={searchKey}
        onChange={(event) => setSearchKey(event.target.value)}
        onKeyPress={onKeyPress}
      />

      {!searchConsumed && (
        <div className={styles["search-button"]} onClick={search}>
          <Search />
        </div>
      )}

      {searchConsumed && (
        <div className={styles["search-button"]} onClick={clear}>
          <Cross />
        </div>
      )}
    </div>
  );
};

export default ListviewSearchBox;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Api } from "../../../listsSettings/api";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  api,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { UserFull, UserPost } from "./types";

const usersUrl: string = `${api}/${Api.Lists.Users.InternalName}`;
export const insertUser = async (user: UserPost, signal: AbortSignal): Promise<Result<UserFull | null>> =>
  insertItem(usersUrl, user, signal);

export const updateUser = async (
  userId: number,
  user: UserPost,
  signal: AbortSignal,
): Promise<Result<UserFull | null>> => updateItem(`${usersUrl}/${userId}`, user, signal);

export const deleteUser = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${usersUrl}/${id}`, signal);

export const deleteUsers = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(usersUrl, ids, signal);

export const getUser = async (id: number, signal: AbortSignal): Promise<Result<UserFull | null>> =>
  getItem<UserFull>(`${usersUrl}/${id}`, signal);

export const getUsers = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => getItems<UserFull>(usersUrl, pagination, signal);

export const getUserFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(usersUrl, fieldName, signal);

export const searchUsers = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserFull> | null>> => searchItems<UserFull>(usersUrl, searchValue, signal);

import { FC } from "react";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import NumberInput from "../../../components/forms/NumberInput";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { DistrictForm } from "./types";

interface NewEditTableProps {
  state: DistrictForm;
  setState: React.Dispatch<React.SetStateAction<DistrictForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.order.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.order}
            onChange={(value) =>
              setState({
                ...state,
                order: value,
              })
            }
          />
          <FormError error={errors[Fields.order.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { dispForm as dispFormLists } from "../lists/urls";
import { dispForm as dispFormUsers } from "../users/urls";
import { SchemaUserPermissionFull } from "./types";

export const schema: IListviewField<SchemaUserPermissionFull> = {
  title: "Schema",
  fieldName: "s.name",
  isSortable: true,
  renderListValue: (item: SchemaUserPermissionFull) => (
    <Link href={`${dispFormLists}/${item.schema?.id}`}>{item.schema?.name}</Link>
  ),
};

export const user: IListviewField<SchemaUserPermissionFull> = {
  title: "Benutzer",
  fieldName: "u.name",
  isSortable: true,
  renderListValue: (item: SchemaUserPermissionFull) => (
    <Link href={`${dispFormUsers}/${item.user?.id}`}>{item.user?.name}</Link>
  ),
};

export const permission: IListviewField<SchemaUserPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.name",
  isSortable: true,
  renderListValue: (item: SchemaUserPermissionFull) => item.permission?.name,
};

export const permissionDescription: IListviewField<SchemaUserPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.description",
  isSortable: true,
  renderListValue: (item: SchemaUserPermissionFull) => item.permission?.description,
};

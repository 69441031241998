import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import Button from "../../../components/Button";
import Actions from "../../../components/controls/Actions";
import { DialogBorder } from "../../../components/dialogs/DialogBorder";
import { DialogCloseIcon } from "../../../components/dialogs/DialogCloseIcon";
import { DialogContainer } from "../../../components/dialogs/DialogContainer";
import { DialogTitle } from "../../../components/dialogs/DialogTitle";
import Modal from "../../../components/dialogs/Modal";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTd from "../../../components/forms/FormTd";
import FormTr from "../../../components/forms/FormTr";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, UserPermissions } from "../../../services/types";
import {
  formatDateFromISOString,
  getCloseAction,
  getCurrentUrlAsSource,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions, uploadFile } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import { getChapterTitle } from "../../members/chapters/types";
import { newForm } from "../chapterElections/urls";
import styles from "./DispForm.module.css";
import * as Fields from "./Fields";
import { ChapterFull } from "./types";
import { allItems, editForm } from "./urls";
import { createLogoUploadUrl, deleteChapter, deleteLogo, getChapter, updateLogo } from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<ChapterFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [chapterPermissions, setChapterPermissions] = useState<UserPermissions>();
  const [functionaryPermissions, setFunctionaryPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const [dialogOptions, setDialogOptions] = useState<{
    isOpen: boolean;
    removeLogo: boolean;
    logo?: string | null;
  }>({
    isOpen: false,
    removeLogo: false,
  });

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const [chapterPermissions, functionaryPermissions] = await Promise.all([
      getUserPermissions(Functionaries.Lists.Chapters.InternalName, Functionaries.InternalName, abortController.signal),
      getUserPermissions(
        Functionaries.Lists.Functionaries.InternalName,
        Functionaries.InternalName,
        abortController.signal,
      ),
    ]);
    if (!abortController.signal.aborted) {
      setChapterPermissions(chapterPermissions);
      setFunctionaryPermissions(functionaryPermissions);
      const canRead = chapterPermissions.schemaPermission && chapterPermissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getChapter(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteChapter(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !chapterPermissions || !chapterPermissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !chapterPermissions || !chapterPermissions.listPermissions.includes("delete-all"),
  };

  const newElectionAction: IAction = {
    title: "Neuwahlen",
    icon: Add,
    onClick: () => navigateTo(newForm),
    disabled:
      !chapterPermissions ||
      !chapterPermissions.listPermissions.includes("edit-all") ||
      !functionaryPermissions ||
      !functionaryPermissions.listPermissions.includes("create"),
  };

  const openEditLogoDialog = () => {
    options.clear();
    setDialogOptions((prevstate) => ({
      ...prevstate,
      isOpen: true,
      removeLogo: false,
      logo: state?.logoUrl,
    }));
  };

  const closeEditLogoDialog = () => {
    options.clear();
    setDialogOptions((prevstate) => ({ ...prevstate, isOpen: false }));
  };

  const { openFilePicker, ...options } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 2 },
    maxFileSize: 1,
  });

  const saveLogo = async () => {
    if (state?.id) {
      const abortController = resetAbortController();
      const file = options.plainFiles[0];
      if (file) {
        const policyResult = await createLogoUploadUrl(abortController.signal, state.id, file.name);

        if (policyResult.error) {
          statusBar.addError(getString(policyResult.error));
          return;
        }
        if (policyResult.data) {
          const uploadResult = await uploadFile(policyResult.data, abortController.signal, file);

          if (uploadResult.error) {
            statusBar.addError(getString(uploadResult.error));
            return;
          }
          if (uploadResult.data) {
            const result = await updateLogo(state.id, file.name, abortController.signal);
            if (!abortController.signal.aborted) {
              result.error && statusBar.addError(getString(result.error));
              result.data &&
                setState(
                  (prevstate) =>
                    prevstate && {
                      ...prevstate,
                      logoUrl: result.data,
                    },
                );
            }
          }
        }
      } else if (dialogOptions.removeLogo) {
        const error = await deleteLogo(state.id, abortController.signal);
        if (!abortController.signal.aborted) {
          error && statusBar.addError(getString(error));
          !error &&
            setState(
              (prevstate) =>
                prevstate && {
                  ...prevstate,
                  logoUrl: null,
                },
            );
        }
      }
      closeEditLogoDialog();
    }
  };

  const clear = () => {
    options.clear();
    setDialogOptions((prevstate) => ({
      ...prevstate,
      removeLogo: prevstate.removeLogo || !!prevstate.logo,
      logo: undefined,
    }));
  };

  useEffect(() => {
    if (state) {
      setListName(Functionaries.Lists.Chapters.Title);
      setPage(state.membersChapter?.name ?? null);
    }
  }, [state]);

  const isClearDisabled = (): boolean => {
    const pic = options.filesContent[0];
    return (!pic && !dialogOptions.logo) || (!!pic && !pic?.name);
  };

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Ortsgruppe"} />
          <Actions actions={[editAction, closeAction, deleteAction]} otherActions={[newElectionAction]} />

          {dialogOptions.isOpen && (
            <Modal isOpen={dialogOptions.isOpen} onClose={closeEditLogoDialog}>
              <DialogContainer>
                <DialogCloseIcon onClose={closeEditLogoDialog} />
                <DialogTitle title="Neues Bild hochladen" />
                <DialogBorder>
                  <div className={styles["image-container"]}>
                    {options.filesContent[0] ? (
                      <img
                        alt={options.filesContent[0].name}
                        src={options.filesContent[0].content}
                        className={styles.image}
                      />
                    ) : dialogOptions.logo ? (
                      <img className={styles.image} src={dialogOptions.logo} />
                    ) : null}
                  </div>
                  <div className={styles.buttons}>
                    <Button onclick={closeEditLogoDialog}>Abbrechen</Button>
                    <Button onclick={clear} disabled={isClearDisabled()}>
                      Bild entfernen
                    </Button>
                    <Button onclick={openFilePicker}>Durchsuchen</Button>
                    <Button onclick={saveLogo}>Speichern</Button>
                  </div>
                </DialogBorder>
              </DialogContainer>
            </Modal>
          )}

          <FormTable>
            <FormTr>
              <FormLabel>{Fields.area.title}</FormLabel>
              <FormData disp>{state?.area?.name}</FormData>
              <FormTd rowSpan={8} props={{ position: "relative", width: "364px", height: "188px" }}>
                <div className={styles["image-edit-buttons"]}>
                  <div onClick={openEditLogoDialog}>
                    <Edit />
                  </div>
                </div>
                {state?.logoUrl && (
                  <div className={styles["image-container"]}>
                    <img className={styles.image} src={`${state.logoUrl}?${Date.now()}`} />
                  </div>
                )}
              </FormTd>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.district.title}</FormLabel>
              <FormData disp>{state?.area?.district?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.membersChapterCode.title}</FormLabel>
              <FormData disp>{getChapterTitle(state.membersChapter)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.from.title}</FormLabel>
              <FormData disp>{formatDateFromISOString(state?.from)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.to.title}</FormLabel>
              <FormData disp>{formatDateFromISOString(state?.to)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.taxCode.title}</FormLabel>
              <FormData disp>{state?.taxCode}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.vatNumber.title}</FormLabel>
              <FormData disp>{state?.vatNumber}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.iban.title}</FormLabel>
              <FormData disp>{state?.iban}</FormData>
            </FormTr>
            <tr>
              <td rowSpan={2}></td>
            </tr>
          </FormTable>
          <CreatedModifiedInfo info={state} />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};
export default DispForm;

import { FC } from "react";
import Title from "../Title";
import styles from "./DialogTitle.module.css";

interface DialogTitleProps {
  title: string;
}

export const DialogTitle: FC<DialogTitleProps> = (props: DialogTitleProps) => {
  return (
    <div className={styles.title}>
      <Title text={props.title} />
    </div>
  );
};

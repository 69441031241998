import { useState } from "react";
import styles from "./SelectBox.module.css";

export interface SelectBoxProps<T> {
  value: T | null | undefined;
  options: T[];
  allowEmpty?: boolean;
  onChange: (x: T | null) => void;
  disabled?: boolean;
  getId: (item: T) => string;
  getValue: (item: T) => string;
}

const getOptions = <T,>(options: T[], value: T | null | undefined, getItemId: (item: T) => string): T[] => {
  if (!value) return options;

  const o = options.filter((x) => getItemId(x) == getItemId(value));
  if (!o) {
    return [...options, o].sort((x, y) => getItemId(x).toLowerCase().localeCompare(getItemId(y).toLowerCase()));
  }

  return options;
};

const SelectBox = <T,>(props: SelectBoxProps<T>) => {
  const [showOhne] = useState(props.allowEmpty || !props.value);

  const options = getOptions(props.options, props.value, props.getId);

  const map = props.options.reduce((rv, x) => {
    rv.set(props.getId(x), x);
    return rv;
  }, new Map<string, T>());

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const v = map.get(event.target.value) || null;
    (props.allowEmpty || v) && props.onChange(v);
  };

  return (
    <select
      value={props.value ? props.getId(props.value) : ""}
      className={styles.container}
      onChange={onChange}
      disabled={props.disabled}
    >
      {showOhne && <option>(Ohne)</option>}
      {options.map((x) => (
        <option key={props.getId(x)} value={props.getId(x)}>
          {props.getValue(x)}
        </option>
      ))}
    </select>
  );
};

export default SelectBox;

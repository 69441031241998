import { useState } from "react";

const useAbortController = (): [AbortController | undefined, () => AbortController] => {
  const [abortController, setAbortController] = useState<AbortController | undefined>();

  const resetAbortController = (): AbortController => {
    if (abortController && !abortController.signal.aborted) {
      abortController.abort();
    }
    const ac = new AbortController();
    setAbortController(ac);
    return ac;
  };

  return [abortController, resetAbortController];
};

export default useAbortController;

import { FC } from "react";
import styles from "./EmptyCell.module.css";

interface EmptyCellProps {
  width?: number;
}

const EmptyCell: FC<EmptyCellProps> = ({ width = 100 }: EmptyCellProps) => {
  return <td className={styles.cell} width={`${width}%`}></td>;
};

export default EmptyCell;

import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { UserFull } from "../users/types";
import * as Fields from "./Fields";

export interface UserFunctionaryPermissionFull extends CreationInformationItem {
  user: UserFull;
}

export interface UserFunctionaryPermissionForm {
  user: UserFull | null;
}

export interface UserFunctionaryPermissionPost {
  userId: number;
}

export const mapToPOST = (item: UserFunctionaryPermissionForm): UserFunctionaryPermissionPost => {
  return {
    userId: item.user?.id ?? 0,
  };
};

export const validate = (
  state: UserFunctionaryPermissionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.user?.id) {
    errors[Fields.userName.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Api } from "../../../listsSettings/api";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  api,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { UserConventionPermissionFull, UserConventionPermissionPost as UserConventionPermissionPost } from "./types";

const userConventionPermissionUrl: string = `${api}/${Api.Lists.UserConventionPermissions.InternalName}`;
export const insertUserConventionPermission = async (
  userConventionPermission: UserConventionPermissionPost,
  signal: AbortSignal,
): Promise<Result<UserConventionPermissionFull | null>> =>
  insertItem(userConventionPermissionUrl, userConventionPermission, signal);

export const updateUserConventionPermission = async (
  userConventionPermissionId: number,
  userConventionPermission: UserConventionPermissionPost,
  signal: AbortSignal,
): Promise<Result<UserConventionPermissionFull | null>> =>
  updateItem(`${userConventionPermissionUrl}/${userConventionPermissionId}`, userConventionPermission, signal);

export const deleteUserConventionPermission = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${userConventionPermissionUrl}/${id}`, signal);

export const deleteUserConventionPermissions = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(userConventionPermissionUrl, ids, signal);

export const getUserConventionPermission = async (
  id: number,
  signal: AbortSignal,
): Promise<Result<UserConventionPermissionFull | null>> =>
  getItem<UserConventionPermissionFull>(`${userConventionPermissionUrl}/${id}`, signal);

export const getUserConventionPermissions = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<UserConventionPermissionFull> | null>> =>
  getItems<UserConventionPermissionFull>(userConventionPermissionUrl, pagination, signal);

export const getUserConventionPermissionFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(userConventionPermissionUrl, fieldName, signal);

import { Pagination } from "../../../components/listview/Pagination";
import { Members } from "../../../listsSettings/members";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { getFilters, getItem, getItems, members } from "../../../services/webapi";
import { LegalRepresentative } from "./types";

const legalRepresentativeUrl: string = `${members}/${Members.Lists.LegalRepresentatives.InternalName}`;

export const getLegalRepresentative = async (
  id: number,
  signal: AbortSignal,
): Promise<Result<LegalRepresentative | null>> => {
  const url = `${legalRepresentativeUrl}/${id}`;
  return getItem<LegalRepresentative>(url, signal);
};

export const getLegalRepresentatives = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<LegalRepresentative> | null>> =>
  getItems<LegalRepresentative>(legalRepresentativeUrl, pagination, signal);

export const getLegalRepresentativeFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(legalRepresentativeUrl, fieldName, signal);

import { Pagination } from "../../../components/listview/Pagination";
import { Members } from "../../../listsSettings/members";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { getFilters, getItem, getItems, members } from "../../../services/webapi";
import { AccountingItem } from "./types";

const accountingItemUrl: string = `${members}/${Members.Lists.AccountingItems.InternalName}`;

export const getAccountingItem = async (id: number, signal: AbortSignal): Promise<Result<AccountingItem | null>> => {
  const url = `${accountingItemUrl}/${id}`;
  return getItem<AccountingItem>(url, signal);
};

export const getAccountingItems = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<AccountingItem> | null>> =>
  getItems<AccountingItem>(accountingItemUrl, pagination, signal);

export const getAccountingOpenItems = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<AccountingItem> | null>> =>
  getItems<AccountingItem>(`${accountingItemUrl}/open_items`, pagination, signal);

export const getMemberAccountingOpenItems = async (
  memberId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<AccountingItem> | null>> =>
  getItems<AccountingItem>(
    `${members}/${Members.Lists.Members.InternalName}/${memberId}/open_items`,
    pagination,
    signal,
  );

export const getAccountingItemFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(accountingItemUrl, fieldName, signal);

export const getAccountingOpenItemFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${accountingItemUrl}/open_items`, fieldName, signal);

export const getMemberAccountingOpenItemFilters = (
  memberId: number,
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${members}/${Members.Lists.Members.InternalName}/${memberId}/open_items`, fieldName, signal);

import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import {
  HOURS,
  MAX_ABSOLUTE_POSITION,
  MINUTES,
  MIN_ABSOLUTE_POSITION,
  PICKER_RADIUS,
  POINTER_RADIUS,
  PickerType,
  TimeMode,
} from "./constants";
import styles from "./DialPlate.module.css";
import PickerDragHandler from "./PickerDragHandler";
import PickerPointGenerator from "./PickerPointGenerator";

interface DialPlateProps {
  type: PickerType;
  minutes?: number | null;
  onChange: (value: number, close: boolean) => void;
  autoClose?: boolean;
  timeMode: TimeMode;
  draggable?: boolean;
  limitDrag?: boolean;
  minuteStep: number;
  autoMode?: boolean;
}

const DialPlate: FC<DialPlateProps> = (props: DialPlateProps) => {
  const [state, setState] = useState<{
    top?: number;
    height?: number;
    pointerRotate: number;
    minutes: number;
  }>({
    pointerRotate: 0,
    minutes: props.minutes ?? new Date().getHours() * 60 + new Date().getMinutes(),
  });

  const resetHourDegree = (): number => {
    const h = Math.floor(state.minutes / 60);
    const found = HOURS.filter((_, index) => h == index + 1)[0];
    return found ? (h - 1 < 12 ? (360 * h) / 12 : (360 * (h - 12)) / 12) : 0;
  };

  const getStateHours = () => Math.floor(state.minutes / 60);

  const getStateMinutes = () => {
    const h = Math.floor(state.minutes / 60) * 60;
    return state.minutes - h;
  };

  const resetMinuteDegree = (): number => {
    const m = getStateMinutes();
    const found = MINUTES.filter((_, index) => m === index)[0];
    return found ? (360 * m) / 60 : 0;
  };

  const [type, setType] = useState<PickerType>(props.type);

  const getTopAndHeight = () => {
    const time = type === "hour" ? getStateHours() : getStateMinutes();
    const splitNum = type === "hour" ? 12 : 60;
    const minLength = type === "hour" ? MIN_ABSOLUTE_POSITION : MAX_ABSOLUTE_POSITION;
    const height = time < splitNum ? minLength - POINTER_RADIUS : MAX_ABSOLUTE_POSITION - POINTER_RADIUS;
    const top =
      time < splitNum
        ? PICKER_RADIUS - minLength + POINTER_RADIUS
        : PICKER_RADIUS - MAX_ABSOLUTE_POSITION + POINTER_RADIUS;
    return [top, height];
  };

  useEffect(() => {
    const [top, height] = getTopAndHeight();
    const pr = type == "hour" ? resetHourDegree() : resetMinuteDegree();
    setState({
      ...state,
      top,
      height,
      pointerRotate: pr,
    });
  }, [type]);

  const handleStepChange = (newStep: "hour" | "minute") => {
    type !== newStep && setType(newStep);
  };

  const handleTimeChange = (value: number, autoMode?: boolean) => {
    const auto = autoMode === true || props.autoMode === true;
    if (type === "hour") {
      const m = getStateMinutes();
      setState({
        ...state,
        minutes: value * 60 + m,
      });
      handleStepChange("minute");
    } else {
      const h = getStateHours();
      const time = h * 60 + value;
      setState({
        ...state,
        minutes: h * 60 + value,
      });
      props.onChange(time, auto);
      handleStepChange("minute");
    }
  };

  const handleTimePointerClick = (value: number, pointerRotate: number) => {
    setState({ ...state, pointerRotate: pointerRotate });
    handleTimeChange(value);
  };

  const handleTimePointerClickAutoMode = (options: { value: number; autoMode?: boolean; pointerRotate?: number }) => {
    setState({ ...state, pointerRotate: options.pointerRotate ?? 0 });
    handleTimeChange(options.value, options.autoMode);
  };

  const classes = classNames(styles["time-picker-header"]);

  const activeHourClass = classNames(classes, {
    [styles.active]: type === "hour",
  });
  const activeMinuteClass = classNames(classes, {
    [styles.active]: type === "minute",
  });

  const onOkClick = () => {
    props.onChange(state.minutes, true);
  };

  return (
    state && (
      <>
        <div className={styles["time-picker-modal-header"]}>
          <span className={activeHourClass} onClick={() => handleStepChange("hour")}>
            {getStateHours().toString().padStart(2, "0")}
          </span>
          <span className={styles["time-picker-header-delivery"]}>:</span>
          <span className={activeMinuteClass} onClick={() => handleStepChange("minute")}>
            {getStateMinutes().toString().padStart(2, "0")}
          </span>
        </div>
        <div className={styles["picker-container"]}>
          <PickerPointGenerator
            type={type}
            handleTimePointerClick={handleTimePointerClick}
            pointerRotate={state.pointerRotate}
          />
          <PickerDragHandler
            type={type}
            timeMode={props.timeMode}
            limitDrag={props.limitDrag}
            minuteStep={props.minuteStep}
            draggable={props.draggable}
            rotateState={state}
            time={type === "hour" ? getStateHours() : getStateMinutes()}
            minLength={type === "hour" ? MIN_ABSOLUTE_POSITION : MAX_ABSOLUTE_POSITION}
            handleTimePointerClick={handleTimePointerClickAutoMode}
          />
          <div className={styles["ok-button"]} onClick={onOkClick}>
            OK
          </div>
        </div>
      </>
    )
  );
};
export default DialPlate;

import React, { FC } from "react";
import styles from "./Input.module.css";

interface InputProps {
  value: string | null;
  onChange?: (x: string) => void;
  autoFocus?: boolean;
  disabled?: boolean;
}

const Input: FC<InputProps> = (props: InputProps) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <input
      autoFocus={props.autoFocus}
      type="text"
      value={props.value ?? ""}
      className={styles.input}
      onChange={update}
      disabled={props.disabled}
    />
  );
};

export default Input;

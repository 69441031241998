import Email from "../../../components/Email";
import DispPhoneNumber from "../../../components/phoneNumber/DispPhoneNumber";
import { IDialogField, IListviewField } from "../../../services/types";
import { LegalRepresentative } from "./types";

export const firstName: IListviewField<LegalRepresentative> & IDialogField<LegalRepresentative> = {
  title: "Vorname",
  fieldName: "firstName",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.firstName,
  renderDialogValue: (item: LegalRepresentative) => item.firstName,
};

export const lastName: IListviewField<LegalRepresentative> & IDialogField<LegalRepresentative> = {
  title: "Nachname",
  fieldName: "lastName",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.lastName,
  renderDialogValue: (item: LegalRepresentative) => item.lastName,
};

export const taxCode: IListviewField<LegalRepresentative> & IDialogField<LegalRepresentative> = {
  title: "Steuernummer",
  fieldName: "taxCode",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.taxCode,
  renderDialogValue: (item: LegalRepresentative) => item.taxCode,
};

export const zip: IListviewField<LegalRepresentative> = {
  title: "PLZ",
  fieldName: "address.zip",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.address.zip,
};

export const city: IListviewField<LegalRepresentative> = {
  title: "Ortschaft",
  fieldName: "address.city",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.address.city,
};

export const province: IListviewField<LegalRepresentative> = {
  title: "Provinz",
  fieldName: "address.province",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.address.province,
};

export const phone: IListviewField<LegalRepresentative> = {
  title: "Telefon",
  fieldName: "phone",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => <DispPhoneNumber value={item.phone} />,
};

export const mobile: IListviewField<LegalRepresentative> = {
  title: "Mobil",
  fieldName: "mobile",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => <DispPhoneNumber value={item.mobile} />,
};

export const email: IListviewField<LegalRepresentative> = {
  title: "E-Mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => <Email value={item.email} />,
};

export const member: IListviewField<LegalRepresentative> = {
  title: "Mitglied",
  fieldName: "member.name",
  isSortable: true,
  renderListValue: (item: LegalRepresentative) => item.member.name,
};

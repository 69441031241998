import { FC } from "react";
import Close from "../../svg/Cross.svg?react";
import styles from "./DialogCloseIcon.module.css";

interface DialogCloseIconProps {
  onClose: () => void;
}

export const DialogCloseIcon: FC<DialogCloseIconProps> = (props: DialogCloseIconProps) => {
  return (
    <div className={styles["close-button-container"]}>
      <div className={styles["close-button"]} onClick={props.onClose}>
        <Close />
      </div>
    </div>
  );
};

import classNames from "classnames";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from "./DropDown.module.css";
import DropDownItems from "./DropDownItems";

interface DropDownProps {
  title?: string;
  buttonClassName?: string;
  buttonIconClassName?: string;
  label?: string;
  stopCloseOnClickSelf?: boolean;
}

const DropDown: FC<React.PropsWithChildren<DropDownProps>> = ({
  label,
  title,
  buttonClassName,
  buttonIconClassName,
  children,
  stopCloseOnClickSelf,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);

  const handleClose = () => {
    setShowDropDown(false);
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  useEffect(() => {
    const button = buttonRef.current;
    const dropDown = dropDownRef.current;

    if (showDropDown && button && dropDown) {
      const bounding = button.getBoundingClientRect();
      dropDown.style.top = `${bounding.top + bounding.height + 1}px`;
      dropDown.style.left = `${Math.min(bounding.left, window.innerWidth - dropDown.offsetWidth - 20)}px`;
    }
  }, [dropDownRef, buttonRef, showDropDown]);

  useEffect(() => {
    const button = buttonRef.current;

    if (button && showDropDown) {
      const handle = (event: MouseEvent) => {
        const target = event.target;
        if (stopCloseOnClickSelf) {
          if (dropDownRef.current && dropDownRef.current.contains(target as Node)) return;
        }
        if (!button.contains(target as Node)) {
          setShowDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
    return () => {};
  }, [dropDownRef, buttonRef, showDropDown, stopCloseOnClickSelf]);

  return (
    <>
      <button
        title={title || label}
        className={buttonClassName}
        onClick={() => setShowDropDown(!showDropDown)}
        ref={buttonRef}
      >
        {buttonIconClassName && <span className={classNames(styles.icon, buttonIconClassName)} />}
        {label && <span className={classNames(styles.text)}>{label}</span>}
        <i className={styles["chevron-down"]} />
      </button>

      {showDropDown &&
        createPortal(
          <DropDownItems dropDownRef={dropDownRef} onClose={handleClose}>
            {children}
          </DropDownItems>,
          document.body,
        )}
    </>
  );
};
export default DropDown;

import LoadingSpinner from "./LoadingSpinner";
import styles from "./Spinner.module.css";

const Spinner = () => (
  <div className={styles.spinner}>
    <LoadingSpinner />
  </div>
);

export default Spinner;

import "./EditorTheme.css";

const exampleTheme = {
  code: "code",
  codeHighlight: {
    atrule: "code-highlight-token-attr",
    attr: "code-highlight-token-attr",
    boolean: "code-highlight-token-property",
    builtin: "code-highlight-token-selector",
    cdata: "code-highlight-token-comment",
    char: "code-highlight-token-selector",
    class: "code-highlight-token-function",
    "class-name": "code-highlight-token-function",
    comment: "code-highlight-token-comment",
    constant: "code-highlight-token-property",
    deleted: "code-highlight-token-property",
    doctype: "code-highlight-token-comment",
    entity: "code-highlight-token-operator",
    function: "code-highlight-token-function",
    important: "code-highlight-token-variable",
    inserted: "code-highlight-token-selector",
    keyword: "code-highlight-token-attr",
    namespace: "code-highlight-token-variable",
    number: "code-highlight-token-property",
    operator: "code-highlight-token-operator",
    prolog: "code-highlight-token-comment",
    property: "code-highlight-token-property",
    punctuation: "code-highlight-token-punctuation",
    regex: "code-highlight-token-variable",
    selector: "code-highlight-token-selector",
    string: "code-highlight-token-selector",
    symbol: "code-highlight-token-property",
    tag: "code-highlight-token-property",
    url: "code-highlight-token-operator",
    variable: "code-highlight-token-variable",
  },
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
  },
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  table: "editor-table",
  tableCell: "editor-table-cell",
  tableCellHeader: "editor-table-cell-header",
};

export default exampleTheme;

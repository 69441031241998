import classNames from "classnames";
import React, { useEffect, useState } from "react";
import useAbortController from "../../hooks/useAbortController";
import useStatusBarState from "../../hooks/useStatusBar";
import { IDialogField, Item, RemoteState, Result, SearchResult } from "../../services/types";
import Search from "../../svg/Search.svg?react";
import Button from "../Button";
import DataDiv from "../DataDiv";
import { getString } from "../errors/Errors";
import LoadingSpinner from "../LoadingSpinner";
import Title from "../Title";
import { DialogBorder } from "./DialogBorder";
import { DialogCloseIcon } from "./DialogCloseIcon";
import { DialogContainer } from "./DialogContainer";
import { Draggable } from "./Draggable";
import Modal from "./Modal";
import styles from "./SearchDialog.module.css";

export interface DialogOptions<T extends Item> {
  title: string;
  fields: IDialogField<T>[];
  searchItems: (searchValue: string, abortController: AbortController) => Promise<Result<SearchResult<T> | null>>;
  onItemSelected: (item: T) => void;
  cancelClicked: () => void;
  defaultSearchKey?: string;
  startSearchWhenOpen?: boolean;
  isOpen: boolean;
  getTitle?: (item: T) => string;
}

interface SearchState<T extends Item> {
  selectedItem: T | null;
  searchKey: string;
  remoteState: RemoteState | "notAsked";
  result: SearchResult<T> | null;
}

export const SearchDialog = <T extends Item>(props: DialogOptions<T>) => {
  const statusBar = useStatusBarState();
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<SearchState<T>>({
    selectedItem: null,
    searchKey: props.defaultSearchKey ?? "",
    remoteState: "notAsked",
    result: null,
  });

  const okClick = () => {
    if (state.selectedItem) {
      props.onItemSelected(state.selectedItem);
    }
  };

  useEffect(() => {
    state.searchKey && search(state.searchKey);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const search = async (searchKey: string) => {
    const abortController = resetAbortController();
    setState((prevState) => {
      return {
        ...prevState,
        remoteState: "loading",
      };
    });
    const result = await props.searchItems(searchKey, abortController);
    if (result.error) {
      console.error(result.error);
      statusBar.addError(getString(result.error));
      props.cancelClicked();
    }
    result.data &&
      setState((prevState) => {
        return {
          ...prevState,
          result: result.data,
          remoteState: "success",
        };
      });
  };

  const searchOnClick = async () => {
    if (state.searchKey) {
      search(state.searchKey);
    }
  };

  const searchOnKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && state.searchKey) {
      search(state.searchKey);
    }
  };

  const updateSearchKey = (value: string) =>
    setState((prevState) => {
      return {
        ...prevState,
        searchKey: value,
      };
    });

  const selectItemOnClick = (item: T) =>
    setState((prevState) => {
      return {
        ...prevState,
        selectedItem: item,
      };
    });

  return (
    <>
      {props.isOpen && (
        <Modal isOpen={props.isOpen} onClose={props.cancelClicked}>
          <Draggable>
            <DialogContainer>
              <DialogCloseIcon onClose={props.cancelClicked} />
              <DialogBorder>
                <Title text={props.title} />
                <div className={styles.container}>
                  <input
                    className={styles["search-field"]}
                    type="text"
                    value={state.searchKey}
                    onChange={(event) => updateSearchKey(event.target.value)}
                    onKeyPress={searchOnKeyPress}
                  />
                  <div className={styles["search-button"]} onClick={searchOnClick}>
                    <Search />
                  </div>
                </div>
                <div className={styles["result-table-container"]}>
                  <table
                    className={classNames(styles["result-table"], {
                      [styles["full-heigth"]]: state.remoteState === "loading",
                    })}
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <thead>
                      <tr className={styles["result-table-headers"]}>
                        {props.fields.map((header) => (
                          <th key={header.fieldName}>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {state.remoteState === "notAsked" && (
                        <tr>
                          <td className={styles["description-text"]} colSpan={props.fields.length}>
                            Geben Sie den Text in das Suchfeld oben ein, und drücken Sie die EINGABETASTE, um die Suche
                            zu starten.
                          </td>
                        </tr>
                      )}
                      {state.result?.items.length === 0 && (
                        <tr>
                          <td className={styles["description-text"]} colSpan={props.fields.length}>
                            Es wurde keine exakte Übereinstimmung gefunden.
                          </td>
                        </tr>
                      )}
                      {state.remoteState === "loading" && (
                        <tr>
                          <td className={styles["loading-image-container"]} colSpan={props.fields.length}>
                            <LoadingSpinner />
                          </td>
                        </tr>
                      )}
                      {state.remoteState === "success" &&
                        state.result &&
                        state.result.items.map((item, outerIndex) => (
                          <tr
                            className={classNames({ [styles["selected-item"]]: state.selectedItem === item })}
                            key={outerIndex}
                            onClick={() => selectItemOnClick(item)}
                            onDoubleClick={() => props.onItemSelected(item)}
                            title={props.getTitle && props.getTitle(item)}
                          >
                            {props.fields.map((header, innerIndex) => (
                              <td key={innerIndex}>
                                <DataDiv>{header.renderDialogValue(item)}</DataDiv>
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className={styles.buttons}>
                  <Button onclick={props.cancelClicked}>Abbrechen</Button>
                  <Button onclick={okClick} disabled={!state.selectedItem}>
                    OK
                  </Button>
                </div>
              </DialogBorder>
            </DialogContainer>
          </Draggable>
        </Modal>
      )}
    </>
  );
};

import classNames from "classnames";
import { FC, useState } from "react";
import useOnKeyDownHandler from "../../hooks/useOnKeyDownHandler";
import Caption from "./Caption";
import styles from "./MonthPicker.module.css";
import Table from "./Table";

interface MonthPickerProps {
  month: number;
  year: number;
  onSelect: (value: number) => void;
  onMove: (key: keyof Duration, value: number) => void;
  onCancel: () => void;
  setType: (returnTo: "cal" | "months" | "years") => void;
}

const MonthPicker: FC<MonthPickerProps> = (props: MonthPickerProps) => {
  const [initialValue] = useState(props.month);

  const months = Array.from({ length: 12 }).reduce((rv: number[][], _, i) => {
    const row = Math.floor(i / 4);
    const column = i - row * 4;
    (rv[row] = rv[row] || [])[column] = i;
    return rv;
  }, []);

  useOnKeyDownHandler({
    arrowUp: (options) => (options.shiftKey ? props.onMove("years", -1) : props.onMove("months", -4)),
    arrowDown: (options) => (options.shiftKey ? props.onMove("years", 1) : props.onMove("months", 4)),
    arrowLeft: (options) => (options.shiftKey ? props.onMove("years", -1) : props.onMove("months", -1)),
    arrowRight: (options) => (options.shiftKey ? props.onMove("years", 1) : props.onMove("months", 1)),
    pageUp: () => props.onMove("years", -1),
    pageDown: () => props.onMove("years", 1),
    home: () => props.onMove("months", -props.month),
    end: () => props.onMove("months", 12 - props.month - 1),
    enter: () => props.onSelect(props.month),
    escape: () => props.onSelect(initialValue),
  });

  return (
    <>
      <Caption
        captions={[
          {
            title: props.year.toString(),
            onclick: () => props.setType("years"),
          },
        ]}
        navigateBack={() => props.onMove("years", -1)}
        navigateForward={() => props.onMove("years", 1)}
      />
      <Table
        body={
          <>
            {months.map((cells, rowIndex) => (
              <tr key={`month_picker_row_${rowIndex}`}>
                {cells.map((month, cellIndex) => (
                  <td
                    className={classNames(styles.cell, {
                      [styles.selected]: rowIndex * 4 + cellIndex == props.month,
                    })}
                    key={`month_picker_row_${rowIndex}_cell_${cellIndex}`}
                    onClick={() => props.onSelect(rowIndex * 4 + cellIndex)}
                  >
                    <div className={styles.month}>
                      {new Date(1970, month, 1).toLocaleDateString("de", { month: "short" })}.
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </>
        }
      ></Table>
    </>
  );
};

export default MonthPicker;

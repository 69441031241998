import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface CommuneFull extends CreationInformationItem, CommunePost {
  id: number;
}

export interface CommunePost {
  code: string;
  name: string;
  province: string | null;
}

export interface CommuneForm {
  code: string | null;
  name: string | null;
  province: string | null;
}

export const mapToPOST = (item: CommuneForm): CommunePost => {
  return {
    code: item.code || "",
    name: item.name || "",
    province: item.province,
  };
};

export const validate = (
  state: CommuneForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.code) {
    errors[Fields.code.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

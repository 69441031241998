import { Item } from "../../../services/types";
import { Member } from "../members/types";

export interface AccountingItem extends Item {
  id: string;
  notes: string | null;
  amount: number | null;
  dunningDate: string | null;
  dunningLevel: string | null;
  documentNumber: string | null;
  documentDate: string | null;
  dueDate: string | null;
  debited: number | null;
  balance: number | null;
  paymentMethod: string | null;
  salesInvoiceId: number | null;
  member: Member | null;
  company: string;
  isOpen: boolean;
}

const hgv = "hgv";
const hgv_service = "hgv_service";
export const formatCompany = (company?: string | null): string => {
  switch (company) {
    case hgv: {
      return "HGV";
    }
    case hgv_service: {
      return "HGV Service";
    }
    default: {
      return company ?? "";
    }
  }
};

import Euro from "../../../components/Euro";
import Link from "../../../components/Link";
import { IListviewField, MenuOptionValue } from "../../../services/types";
import { formatBoolean, formatDateFromISOString, getCurrentUrlAsSource } from "../../../services/utils";
import { AccountingItem, formatCompany } from "./types";
import { dispForm } from "./urls";

export const documentNumber: IListviewField<AccountingItem> = {
  title: "Dokument Nummer",
  fieldName: "documentNumber",
  isSortable: true,
  renderListValue: (item: AccountingItem) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.documentNumber}</Link>
  ),
};

export const documentDate: IListviewField<AccountingItem> = {
  title: "Dokument Datum",
  fieldName: "documentDate",
  isSortable: true,
  renderListValue: (item: AccountingItem) => formatDateFromISOString(item.documentDate),
};

export const amount: IListviewField<AccountingItem> = {
  title: "Betrag",
  fieldName: "amount",
  isSortable: true,
  renderListValue: (item: AccountingItem) => <Euro value={item.amount} />,
};

export const debited: IListviewField<AccountingItem> = {
  title: "Abgebucht",
  fieldName: "debited",
  isSortable: true,
  renderListValue: (item: AccountingItem) => <Euro value={item.debited} />,
};

export const balance: IListviewField<AccountingItem> = {
  title: "Saldo",
  fieldName: "balance",
  isSortable: true,
  renderListValue: (item: AccountingItem) => <Euro value={item.balance} />,
};

export const dunningDate: IListviewField<AccountingItem> = {
  title: "Mahndatum",
  fieldName: "dunningDate",
  isSortable: true,
  renderListValue: (item: AccountingItem) => formatDateFromISOString(item.dunningDate),
};

export const dunningLevel: IListviewField<AccountingItem> = {
  title: "Mahnstufe",
  fieldName: "dunningLevel",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: AccountingItem) => item.dunningLevel,
};

export const dueDate: IListviewField<AccountingItem> = {
  title: "Fälligkeit",
  fieldName: "dueDate",
  isSortable: true,
  renderListValue: (item: AccountingItem) => formatDateFromISOString(item.dueDate),
};

export const member: IListviewField<AccountingItem> = {
  title: "Mitglied",
  fieldName: "member",
  isSortable: true,
  renderListValue: (item: AccountingItem) => item.member?.name,
};

export const company: IListviewField<AccountingItem> = {
  title: "Quelle",
  fieldName: "company",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: AccountingItem) => formatCompany(item.company),
  renderMenuOptionValue: (value: MenuOptionValue) => formatCompany(value as string) || "(Leer)",
};

export const paymentMethod: IListviewField<AccountingItem> = {
  title: "Zahlungsart",
  fieldName: "paymentMethod",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: AccountingItem) => item.paymentMethod,
};

export const isOpen: IListviewField<AccountingItem> = {
  title: "Ist Offen",
  fieldName: "isOpen",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: AccountingItem) => formatBoolean(item.isOpen),
};

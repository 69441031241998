import { FC } from "react";
import styles from "./FormTable.module.css";

interface FormTableProps {
  children: React.ReactNode;
}

const FormTable: FC<FormTableProps> = ({ children }: FormTableProps) => {
  return (
    <div className={styles.form}>
      <table cellSpacing="0" cellPadding="0" className={styles.table}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default FormTable;

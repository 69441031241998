import Link from "../../../components/Link";
import Number from "../../../components/Number";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { DistrictFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<DistrictFull> & IDialogField<DistrictFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: DistrictFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: DistrictFull) => item.name,
};

export const order: IListviewField<DistrictFull> = {
  title: "Reihenfolge",
  fieldName: "order",
  isSortable: true,
  renderListValue: (item: DistrictFull) => <Number value={item.order} />,
};

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import classNames from "classnames";
import { EditorState, LexicalEditor } from "lexical";
import styles from "./CustomLexicalEditor.module.css";
import { HorizontalRuleNode } from "./nodes/HorizontalRuleNode";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import HorizontalRulePlugin from "./plugins/HorizontalRulePlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import { OnChangePlugin } from "./plugins/OnChangePlugin";
import TableCellActionMenuPlugin from "./plugins/TableCellActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import TextFormatFloatingToolbarPlugin from "./plugins/TextFormatFloatingToolbarPlugin";
import ToolbarPlugin, { ToolbarPluginProps } from "./plugins/ToolbarPlugin";
import EditorTheme from "./themes/EditorTheme";

interface CustomLexicalEditorProps extends ToolbarPluginProps {
  initialValue: string | null;
  size: {
    width: number;
    heigth?: number;
  };
  hideFloatingToolbar?: boolean;
  onChange?: (json: string, html: string) => void;
  disp?: boolean;
}

const HEIGHT: number = 300;

const CustomLexicalEditor = ({
  initialValue,
  size,
  hideFloatingToolbar,
  onChange,
  disp,
  ...props
}: CustomLexicalEditorProps) => {
  const editorConfig = {
    editorState: initialValue,
    theme: EditorTheme,
    namespace: "hgv.it",
    onError(error: Error, _: LexicalEditor) {
      throw error;
    },
    editable: !disp,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      HorizontalRuleNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  const update = (editorState: EditorState, editor: LexicalEditor) => {
    if (onChange) {
      const json = JSON.stringify(editorState.toJSON());
      if (json !== initialValue) {
        const html = $generateHtmlFromNodes(editor, null);

        const div = document.createElement("div");
        div.innerHTML = html;

        const tds = div.querySelectorAll("td");
        for (var td of tds) {
          td.removeAttribute("style");
        }

        const elementsWithClass = div.querySelectorAll("*[class]");
        for (var e of elementsWithClass) {
          e.removeAttribute("class");
        }

        const cleanHtml = div.innerHTML;

        onChange(json, cleanHtml);
      }
    }
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div
        className={styles["editor-container"]}
        style={{
          width: size.width,
        }}
      >
        {!disp && <ToolbarPlugin {...props} />}
        <div
          className={classNames(styles["editor-inner"], {
            [styles["editor-inner-edit"]]: !disp,
          })}
          style={{
            height: size.heigth ?? HEIGHT,
          }}
        >
          <AutoLinkPlugin />
          <HistoryPlugin />
          <RichTextPlugin
            contentEditable={<ContentEditable className={styles["editor-input"]} />}
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <CodeHighlightPlugin />
          {/* <TextNodeTransformer />
          <LinkNodeTransformer /> */}
          <ListPlugin />
          <LinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <TablePlugin />
          {!disp ? (
            <>
              <TableCellActionMenuPlugin />
              <TableCellResizer />
            </>
          ) : undefined}
          <HorizontalRulePlugin />
          {!disp && !hideFloatingToolbar && <TextFormatFloatingToolbarPlugin />}
          <OnChangePlugin ignoreInitialChange onChange={update} />
        </div>
      </div>
    </LexicalComposer>
  );
};
export default CustomLexicalEditor;

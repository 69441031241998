import { mergeRegister } from "@lexical/utils";
import classNames from "classnames";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  CommandListenerPriority,
  LexicalEditor,
  REDO_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { FC, useEffect, useState } from "react";
import styles from "./EditorHistory.module.css";

interface EditorHistoryProps {
  editor: LexicalEditor;
  onUpdate: () => void;
}

const EditorHistory: FC<EditorHistoryProps> = ({ editor, onUpdate }) => {
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const priority: CommandListenerPriority = 1;

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: boolean) => {
          setCanUndo(payload);
          return false;
        },
        priority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload: boolean) => {
          setCanRedo(payload);
          return false;
        },
        priority,
      ),
    );
  }, [editor, onUpdate]);

  return (
    <>
      <button disabled={!canUndo} onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)} title="Rückgängig">
        <i className={classNames(styles.format, styles.undo)} />
      </button>
      <button disabled={!canRedo} onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)} title="Wiederholen">
        <i className={classNames(styles.format, styles.redo)} />
      </button>
    </>
  );
};

export default EditorHistory;

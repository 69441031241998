import Email from "../../../components/Email";
import Link from "../../../components/Link";
import DispPhoneNumber from "../../../components/phoneNumber/DispPhoneNumber";
import { IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { ContactFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<ContactFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: ContactFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
};

export const phone: IListviewField<ContactFull> = {
  title: "Telefon",
  fieldName: "phone",
  isSortable: true,
  renderListValue: (item: ContactFull) => <DispPhoneNumber value={item.phone} />,
};

export const mobile: IListviewField<ContactFull> = {
  title: "Mobil",
  fieldName: "mobile",
  isSortable: true,
  renderListValue: (item: ContactFull) => <DispPhoneNumber value={item.mobile} />,
};

export const email: IListviewField<ContactFull> = {
  title: "E-Mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: ContactFull) => <Email value={item.email} />,
};

export const website: IListviewField<ContactFull> = {
  title: "Webseite",
  fieldName: "website",
  isSortable: true,
  renderListValue: (item: ContactFull) => (
    <Link href={item.website ?? ""} openInNewTab external>
      {item.website}
    </Link>
  ),
};

export const convention: IListviewField<ContactFull> = {
  title: "Konvention",
  fieldName: "convention.title",
  isSortable: true,
  renderListValue: (item: ContactFull) => item.convention.title,
};

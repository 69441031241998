import { FC } from "react";
import CheckBox from "../../../components/forms/CheckBox";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import MultiCheckBox from "../../../components/forms/MultiCheckBox";
import { Api } from "../../../listsSettings/api";
import { ValidationErrors } from "../../../services/types";
import * as UserFields from "../users/Fields";
import { searchUsers } from "../users/webapi";
import * as Fields from "./Fields";
import { memberTypes, renderMemberType, UserMemberPermissionForm } from "./types";

interface NewEditTableProps {
  state: UserMemberPermissionForm;
  setState: React.Dispatch<React.SetStateAction<UserMemberPermissionForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.userName.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.user?.id}
            value={state.user?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                user: value,
              })
            }
            searchItems={searchUsers}
            searchDialogTitle={Api.Lists.UserMemberPermissions.Title}
            searchDialogFields={[UserFields.name, UserFields.key]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.userName.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.memberTypes.title}</FormLabel>
        <FormData>
          <MultiCheckBox
            values={memberTypes.map((x) => ({
              value: x,
              label: renderMemberType(x),
              selected: state.memberTypes.indexOf(x) >= 0,
            }))}
            isDisabled={(value) => (value != "all" && state.memberTypes.indexOf("all") >= 0) || state.customEndpoint}
            onChange={(value) =>
              setState({
                ...state,
                memberTypes: value,
              })
            }
          />
          <FormError error={errors[Fields.memberTypes.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.canceledMembers.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.canceledMembers}
            onChange={(value) =>
              setState({
                ...state,
                canceledMembers: value,
              })
            }
            disabled={state.customEndpoint}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.companies.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.companies}
            onChange={(value) =>
              setState({
                ...state,
                companies: value,
              })
            }
            disabled={state.customEndpoint}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.canceledCompanies.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.canceledCompanies}
            onChange={(value) =>
              setState({
                ...state,
                canceledCompanies: value,
              })
            }
            disabled={state.customEndpoint}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.legalRepresentative.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.legalRepresentative}
            onChange={(value) =>
              setState({
                ...state,
                legalRepresentative: value,
              })
            }
            disabled={state.customEndpoint}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.departmentData.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.departmentData}
            onChange={(value) =>
              setState({
                ...state,
                departmentData: value,
              })
            }
            disabled={state.customEndpoint}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.customEndpoint.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.customEndpoint}
            onChange={(value) =>
              setState({
                ...state,
                customEndpoint: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.endpoint.title}</FormLabel>
        <FormData>
          <Input
            value={state.endpoint}
            onChange={(value) =>
              setState({
                ...state,
                endpoint: value,
              })
            }
            disabled={!state.customEndpoint}
          />
          <FormError error={errors[Fields.endpoint.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import styles from "./LoadingSpinnerSmall.module.css";

const LoadingSpinnerSmall = () => (
  <div className={styles["lds-default"]}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoadingSpinnerSmall;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { SchemaGroupPermissionFull } from "../schemaGroupPermissions/types";
import { SchemaUserPermissionFull } from "../schemaUserPermissions/types";
import { SchemaFull, SchemaPost } from "./types";

const schemasUrl: string = `${administration}/${Administration.Lists.Schemas.InternalName}`;
export const insertSchema = async (schema: SchemaPost, signal: AbortSignal): Promise<Result<SchemaFull | null>> =>
  insertItem(schemasUrl, schema, signal);

export const updateSchema = async (
  schemaId: number,
  schema: SchemaPost,
  signal: AbortSignal,
): Promise<Result<SchemaFull | null>> => updateItem(`${schemasUrl}/${schemaId}`, schema, signal);

export const deleteSchema = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${schemasUrl}/${id}`, signal);

export const deleteSchemas = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(schemasUrl, ids, signal);

export const getSchema = async (id: number, signal: AbortSignal): Promise<Result<SchemaFull | null>> =>
  getItem<SchemaFull>(`${schemasUrl}/${id}`, signal);

export const getSchemas = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<SchemaFull> | null>> => getItems<SchemaFull>(schemasUrl, pagination, signal);

export const getSchemaFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(schemasUrl, fieldName, signal);

export const searchSchemas = async (
  searchValue: string,
  signal: AbortSignal,
): Promise<Result<SearchResult<SchemaFull> | null>> => searchItems<SchemaFull>(schemasUrl, searchValue, signal);

export const getSchemaGroupsPermissions = async (
  schemaId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<SchemaGroupPermissionFull> | null>> =>
  getItems<SchemaGroupPermissionFull>(`${schemasUrl}/${schemaId}/group_permissions`, pagination, signal);

export const getSchemaGroupsPermissionsFilters = (
  schemaId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${schemasUrl}/${schemaId}/group_permissions`, fieldname, signal);

export const getSchemaUsersPermissions = async (
  schemaId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<SchemaUserPermissionFull> | null>> =>
  getItems<SchemaUserPermissionFull>(`${schemasUrl}/${schemaId}/user_permissions`, pagination, signal);

export const getSchemaUsersPermissionsFilters = (
  schemaId: number,
  fieldname: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${schemasUrl}/${schemaId}/user_permissions`, fieldname, signal);

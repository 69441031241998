import FileIcon from "../../../components/FileIcon";
import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { AttachmentFull } from "./types";

export const file: IListviewField<AttachmentFull> = {
  title: "Datei",
  fieldName: "filename",
  isSortable: true,
  renderListValue: (item: AttachmentFull) => (
    <Link href={item.url} download external>
      {item.filename}
    </Link>
  ),
};

export const extension: IListviewField<AttachmentFull> = {
  title: "Typ",
  fieldName: "extension",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: AttachmentFull) => <FileIcon extension={item.extension}></FileIcon>,
};

export const displayname: IListviewField<AttachmentFull> = {
  title: "Anzeigename",
  fieldName: "displayName",
  isSortable: true,
  renderListValue: (item: AttachmentFull) => item.displayName,
};

export const convention: IListviewField<AttachmentFull> = {
  title: "Konvention",
  fieldName: "convention",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: AttachmentFull) => item.convention.title,
};

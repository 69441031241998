import { FC } from "react";
import styles from "./Number.module.css";

interface NumberProps {
  value?: number | null;
  fractionalDigits?: number;
}

const Number: FC<NumberProps> = ({ value, fractionalDigits }: NumberProps) => (
  <div className={styles.alignRight}>{value || value === 0 ? value.toFixed(fractionalDigits || 0) : ""}</div>
);
export default Number;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { RoleFull, RolePost } from "./types";

const rolesUrl: string = `${functionaries}/${Functionaries.Lists.Roles.InternalName}`;
export const insertRole = async (role: RolePost, signal: AbortSignal): Promise<Result<RoleFull | null>> =>
  insertItem(rolesUrl, role, signal);

export const updateRole = async (
  roleId: number,
  role: RolePost,
  signal: AbortSignal,
): Promise<Result<RoleFull | null>> => updateItem(`${rolesUrl}/${roleId}`, role, signal);

export const deleteRole = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${rolesUrl}/${id}`, signal);

export const deleteRoles = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(rolesUrl, ids, signal);

export const getRole = async (id: number, signal: AbortSignal): Promise<Result<RoleFull | null>> =>
  getItem<RoleFull>(`${rolesUrl}/${id}`, signal);

export const getRoles = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<RoleFull> | null>> => getItems<RoleFull>(rolesUrl, pagination, signal);

export const getRoleFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(rolesUrl, fieldName, signal);

import { FC, useState } from "react";
import styles from "../../../components/forms/SelectBox.module.css";
import { getItemTitle, LegislatureFull } from "./types";

interface SelectBoxLegislatureProps {
  value: LegislatureFull | null;
  options: LegislatureFull[];
  allowEmpty?: true;
  onChange: (x: LegislatureFull | null) => void;
  disabled?: boolean;
}

const getOptions = (options: LegislatureFull[], value: LegislatureFull | null): LegislatureFull[] => {
  if (!value) return options;

  const o = options.filter((x) => x.id == value?.id);
  if (!o) {
    return [...options, o].sort((x, y) => getItemTitle(x).toLowerCase().localeCompare(getItemTitle(y).toLowerCase()));
  }

  return options;
};

const SelectBoxLegislature: FC<SelectBoxLegislatureProps> = (props: SelectBoxLegislatureProps) => {
  const [showOhne] = useState(props.allowEmpty || !props.value);

  const options = getOptions(props.options, props.value);

  const map = props.options.reduce((rv, x) => {
    rv.set(`${x.id}`, x);
    return rv;
  }, new Map<string, LegislatureFull>());

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const v = map.get(event.target.value) || null;
    props.onChange(v);
  };

  return (
    <select
      value={props.value?.id || undefined}
      className={styles.container}
      onChange={onChange}
      disabled={props.disabled}
    >
      {showOhne && <option>(Ohne)</option>}
      {options.map((x) => (
        <option key={x.id} value={`${x.id}`}>
          {getItemTitle(x)}
        </option>
      ))}
    </select>
  );
};

export default SelectBoxLegislature;

import { useEffect, useState } from "react";
import { getString } from "../../../components/errors/Errors";
import Email from "../../../components/forms/Email";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import SelectBox from "../../../components/forms/SelectBox";
import { DefaultPagination } from "../../../components/listview/Pagination";
import EditPhoneNumber, { PhoneCountry } from "../../../components/phoneNumber/EditPhoneNumber";
import useStatusBarState from "../../../hooks/useStatusBar";
import { ValidationErrors } from "../../../services/types";
import { getCountries } from "../../administration/countries/webapi";
import { ConventionFull } from "../conventions/types";
import { getConventions } from "../conventions/webapi";
import * as Fields from "./Fields";
import { ContactForm } from "./types";

const NewEditTable = ({
  state,
  setState,
  errors,
}: {
  state: ContactForm;
  setState: React.Dispatch<React.SetStateAction<ContactForm | undefined>>;
  errors: ValidationErrors;
}) => {
  const statusBar = useStatusBarState();
  const [countries, setCountries] = useState<PhoneCountry[]>([]);
  const [conventions, setConventions] = useState<ConventionFull[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const [conventionsResult, countriesResult] = await Promise.all([
        getConventions(DefaultPagination, abortController.signal),
        getCountries({}, abortController.signal),
      ]);
      conventionsResult.data && setConventions(conventionsResult.data.items);
      conventionsResult.error && statusBar.addError(getString(conventionsResult.error));

      countriesResult.error && statusBar.addError(getString(countriesResult.error));
      countriesResult.data && setCountries(countriesResult.data.items.filter((x) => x.phoneCode));
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.phone.title}</FormLabel>
        <FormData>
          <EditPhoneNumber
            countries={countries}
            value={state.phone}
            onChange={(value) =>
              setState({
                ...state,
                phone: value,
              })
            }
          />
          <FormError error={errors[Fields.phone.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.mobile.title}</FormLabel>
        <FormData>
          <EditPhoneNumber
            countries={countries}
            value={state.mobile}
            onChange={(value) =>
              setState({
                ...state,
                mobile: value,
              })
            }
          />
          <FormError error={errors[Fields.mobile.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.email.title}</FormLabel>
        <FormData>
          <Email
            value={state.email}
            onChange={(value) =>
              setState({
                ...state,
                email: value,
              })
            }
          />
          <FormError error={errors[Fields.email.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.website.title}</FormLabel>
        <FormData>
          <Input
            value={state.website}
            onChange={(value) =>
              setState({
                ...state,
                website: value,
              })
            }
          />
          <FormError error={errors[Fields.website.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.convention.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.convention}
            onChange={(value) =>
              setState({
                ...state,
                convention: value,
              })
            }
            options={conventions}
            getId={(item) => `${item.id}`}
            getValue={(item) => item.title}
          />
          <FormError error={errors[Fields.convention.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

import { FC } from "react";
import styles from "./DialogContainer.module.css";

interface DialogContainerProps {
  children: React.ReactNode;
}

export const DialogContainer: FC<DialogContainerProps> = ({ children }: DialogContainerProps) => (
  <div className={styles.container}>{children}</div>
);

import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface ChapterEventCategoryFull extends CreationInformationItem {
  id: number;
  name: string;
}

export interface ChapterEventCategoryForm {
  name: string | null;
}

export interface ChapterEventCategoryPost {
  name: string;
}

export const mapToPOST = (item: ChapterEventCategoryForm): ChapterEventCategoryPost => {
  return {
    name: item.name || "",
  };
};

export const validate = (
  state: ChapterEventCategoryForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

import { FC, useEffect, useState } from "react";
import { getString } from "../../../components/errors/Errors";
import CheckBox from "../../../components/forms/CheckBox";
import DatePicker from "../../../components/forms/DatePickerWithInput";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import SelectBox from "../../../components/forms/SelectBox";
import TimeRangePickerWithInput from "../../../components/forms/TimeRangeWithInputPicker";
import { DefaultPagination, Pagination } from "../../../components/listview/Pagination";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ValidationErrors } from "../../../services/types";
import { ChapterEventCategoryFull } from "../chapterEventsCategories/types";
import { getChapterEventsCategories } from "../chapterEventsCategories/webapi";
import * as ChapterFields from "../chapters/Fields";
import { searchChapters } from "../chapters/webapi";
import { DistrictFull } from "../districts/types";
import { getDistricts } from "../districts/webapi";
import * as Fields from "./Fields";
import { ChapterEventForm } from "./types";

interface NewEditTableProps {
  state: ChapterEventForm;
  setState: React.Dispatch<React.SetStateAction<ChapterEventForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  const statusBar = useStatusBarState();
  const [categories, setCategories] = useState<ChapterEventCategoryFull[]>([]);
  const [districts, setDistricts] = useState<DistrictFull[]>([]);

  const paging: Pagination = {
    ...DefaultPagination,
    itemsPerPage: 100,
  };

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const [districtsResult, categoriesResult] = await Promise.all([
        getDistricts(paging, abortController.signal),
        getChapterEventsCategories(paging, abortController.signal),
      ]);
      districtsResult.data && setDistricts(districtsResult.data?.items);
      districtsResult.error && statusBar.addError(getString(districtsResult.error));
      categoriesResult.data && setCategories(categoriesResult.data?.items);
      categoriesResult.error && statusBar.addError(getString(categoriesResult.error));
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.title.title}</FormLabel>
        <FormData>
          <Input
            value={state.title}
            onChange={(value) =>
              setState({
                ...state,
                title: value,
              })
            }
          />
          <FormError error={errors[Fields.title.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.description.title}</FormLabel>
        <FormData>
          <Input
            value={state.description}
            onChange={(value) =>
              setState({
                ...state,
                description: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.location.title}</FormLabel>
        <FormData>
          <Input
            value={state.location}
            onChange={(value) =>
              setState({
                ...state,
                location: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.date.title}</FormLabel>
        <FormData>
          <DatePicker
            value={state.date}
            onSelect={(value) =>
              setState({
                ...state,
                date: value,
              })
            }
          />
          <FormError error={errors[Fields.date.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>Von-Bis</FormLabel>
        <FormData>
          <TimeRangePickerWithInput
            autoMode
            from={{
              value: state.from,
              onSelect: (value) =>
                setState({
                  ...state,
                  from: value,
                }),
            }}
            to={{
              value: state.to,
              onSelect: (value) =>
                setState({
                  ...state,
                  to: value,
                }),
            }}
          ></TimeRangePickerWithInput>
          <FormError error={errors[Fields.from.fieldName] ?? errors[Fields.to.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.visibleOnHgvIt.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.visibleOnHgvIt}
            onChange={(value) =>
              setState({
                ...state,
                visibleOnHgvIt: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.visibleOnChaptersIt.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.visibleOnChaptersIt}
            onChange={(value) =>
              setState({
                ...state,
                visibleOnChaptersIt: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.district.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.district}
            options={districts}
            onChange={(value) =>
              setState({
                ...state,
                district: value,
              })
            }
            getId={(item) => `${item.id}`}
            getValue={(item) => item.name}
            allowEmpty
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.chapter.title}</FormLabel>
        <FormData>
          <Lookup
            value={state.chapter?.membersChapter?.name || null}
            disabled={!!state.chapter?.id}
            onChange={(value) =>
              setState({
                ...state,
                chapter: value,
              })
            }
            searchItems={searchChapters}
            getTitle={(item) => item.membersChapter?.name ?? ""}
            searchDialogTitle={Functionaries.Lists.Chapters.Title}
            searchDialogFields={[ChapterFields.name, ChapterFields.area, ChapterFields.district]}
          />
          <FormError error={errors[Fields.chapter.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.category.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.category}
            options={categories}
            onChange={(value) =>
              setState({
                ...state,
                category: value,
              })
            }
            getId={(item) => `${item.id}`}
            getValue={(item) => item.name}
          />
          <FormError error={errors[Fields.category.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.signUpLink.title}</FormLabel>
        <FormData>
          <Input
            value={state.signUpLink}
            onChange={(value) =>
              setState({
                ...state,
                signUpLink: value,
              })
            }
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;

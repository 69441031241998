import { mergeRegister } from "@lexical/utils";
import classNames from "classnames";
import { $getSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from "lexical";
import { FC, useCallback, useEffect, useRef } from "react";
import styles from "./FloatingTextFormattingTools.module.css";
import TextFormattingTools, { TextFormattingToolsProps } from "./TextFormattingTools";

const FloatingTextFormattingTools: FC<TextFormattingToolsProps> = (props) => {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

  const setPopupPosition = (editor: HTMLElement, rect: DOMRect, rootElementRect: DOMRect): void => {
    let top = rect.bottom + window.scrollY;
    let left = rect.left;
    if (left + editor.offsetWidth > rootElementRect.right) {
      left = rect.right - editor.offsetWidth;
      top = rect.top - 50 + window.scrollY;
    }
    if (left < 0) {
      left = rect.left;
      top = rect.bottom + 20;
    }
    if (rect.width >= rootElementRect.width - 25) {
      left = rect.left;
      top = rect.top - 50 + window.scrollY;
    }
    if (top < rootElementRect.top) {
      top = rect.bottom + 20;
    }
    editor.style.opacity = "1";
    editor.style.top = `${top}px`;
    editor.style.left = `${left}px`;
  };

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (!popupCharStylesEditorElem) {
      return;
    }

    const rootElement = props.editor.getRootElement();
    if (
      selection &&
      nativeSelection &&
      !nativeSelection.isCollapsed &&
      rootElement &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      const rootElementRect = rootElement.getBoundingClientRect();
      let rect;

      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      setPopupPosition(popupCharStylesEditorElem, rect, rootElementRect);
    }
  }, [props.editor]);

  useEffect(() => {
    const onResize = () => {
      props.editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [props.editor, updateTextFormatFloatingToolbar]);

  useEffect(() => {
    props.editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      props.editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),

      props.editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [props.editor, updateTextFormatFloatingToolbar]);

  return (
    <div ref={popupCharStylesEditorRef} className={classNames(styles["floating-text"])}>
      <TextFormattingTools {...props}></TextFormattingTools>
    </div>
  );
};

export default FloatingTextFormattingTools;

import classNames from "classnames";
import { format } from "date-fns";
import { FC } from "react";
import { getCurrentUrlAsSource } from "../../services/utils";
import Link from "../Link";
import Cell from "./Cell";
import styles from "./MonthlyCell.module.css";
import { CalendarItem } from "./types";

interface MonthlyCellProps {
  item: CalendarItem;
  dispUrl: string;
  onClick?: () => void;
  single?: boolean;
}

const MonthlyCell: FC<MonthlyCellProps> = ({ item, dispUrl, onClick, single }: MonthlyCellProps) => {
  const time = format(item.from, "HH:mm") + (single ? ` - ${format(item.to, "HH:mm")}` : " ");
  const link = (
    <Link href={`${dispUrl}?${getCurrentUrlAsSource()}`} onClick={onClick}>
      {item.title}
    </Link>
  );
  return (
    <table cellPadding="0" cellSpacing="0" width={"100%"}>
      <tbody>
        <tr>
          <Cell classNames={classNames({ [styles.single]: single })} item={item}>
            <div>
              <span>{time}</span>
              {!single && <span>{link}</span>}
            </div>
            {single && <div>{link}</div>}
          </Cell>
        </tr>
      </tbody>
    </table>
  );
};

export default MonthlyCell;

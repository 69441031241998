import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Link from "../../../components/Link";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import {
  formatBoolean,
  getCloseAction,
  getCurrentUrlAsSource,
  getSelectedItems,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import EditListGroupPermissionDialogForm from "../listGroupPermissions/EditDialogForm";
import * as GroupFields from "../listGroupPermissions/Fields";
import NewListGroupPermissionDialogForm from "../listGroupPermissions/NewDialogForm";
import { ListGroupPermissionFull } from "../listGroupPermissions/types";
import { deleteListGroupPermissions } from "../listGroupPermissions/webapi";
import EditListUserPermissionDialogForm from "../listUserPermissions/EditDialogForm";
import * as UserFields from "../listUserPermissions/Fields";
import NewListUserPermissionDialogForm from "../listUserPermissions/NewDialogForm";
import { ListUserPermissionFull } from "../listUserPermissions/types";
import { deleteListUserPermissions } from "../listUserPermissions/webapi";
import * as ViewFields from "../views/Fields";
import { ViewFull } from "../views/types";
import { newForm as newFormView } from "../views/urls";
import { deleteViews } from "../views/webapi";
import * as Fields from "./Fields";
import { dispUrl, ListFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  deleteList,
  getList,
  getListGroupsPermissions,
  getListGroupsPermissionsFilters,
  getListUsersPermissions,
  getListUsersPermissionsFilters,
  getListviews,
  getListviewsFilters,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<ListFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [viewPermissions, setViewPermissions] = useState<UserPermissions>();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();
  const [groupPermissions, setGroupPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Lists.InternalName,
      Administration.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [viewPermissions, userPermissions, groupPermissions, result] = await Promise.all([
          getUserPermissions(
            Administration.Lists.Views.InternalName,
            Administration.InternalName,
            abortController.signal,
          ),
          getUserPermissions(
            Administration.Lists.Users.InternalName,
            Administration.InternalName,
            abortController.signal,
          ),
          getUserPermissions(
            Administration.Lists.Groups.InternalName,
            Administration.InternalName,
            abortController.signal,
          ),
          getList(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setState(result.data);
            setViewPermissions(viewPermissions);
            setUserPermissions(userPermissions);
            setGroupPermissions(groupPermissions);
            viewPermissions?.listPermissions.includes("read-all") &&
              (await loadViews(result.data.id, viewsPgination, abortController));
            userPermissions?.listPermissions.includes("read-all") &&
              (await loadListGroupPermissions(result.data.id, groupPermissionPagination, abortController));
            groupPermissions?.listPermissions.includes("read-all") &&
              (await loadListUserPermissions(result.data.id, userPermissionPagination, abortController));
          }
        }
      }
    }
  };

  const viewFields = [ViewFields.name, ViewFields.internalName, ViewFields.showSearchBox, ViewFields.itemsPerPage];
  const userFields = [UserFields.user, UserFields.permission, UserFields.permissionDescription];
  const groupFields = [GroupFields.group, GroupFields.permission, GroupFields.permissionDescription];

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteList(state.id, abortController.signal);
      error && statusBar.addError(getString(error));
      !error && navigateTo(getSourceFromUrl() ?? allItems);
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  const listIdViews = "list-views";
  const [views, setViews] = useState<SearchResult<ViewFull> | null>(null);

  const viewsPgination = getPagination(listIdViews) || {
    ...DefaultPagination,
  };

  const loadViews = async (listId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getListviews(listId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setViews(result.data);
    }
  };

  const listIdUserPermissions = "list-users-permissions";
  const [listUserPermissions, setListUserPermissions] = useState<SearchResult<ListUserPermissionFull> | null>(null);

  const userPermissionPagination = getPagination(listIdUserPermissions) || {
    ...DefaultPagination,
  };

  const loadListUserPermissions = async (listId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getListUsersPermissions(listId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setListUserPermissions(result.data);
    }
  };

  const listIdGroupPermissions = "list-groups-permissions";
  const [listGroupPermissions, setListGroupPermissions] = useState<SearchResult<ListGroupPermissionFull> | null>(null);

  const groupPermissionPagination = getPagination(listIdGroupPermissions) || {
    ...DefaultPagination,
  };

  const loadListGroupPermissions = async (listId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getListGroupsPermissions(listId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setListGroupPermissions(result.data);
    }
  };

  const addViewAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => navigateTo(`${newFormView}?listId=${state?.id}&${getCurrentUrlAsSource()}`),
    disabled:
      !viewPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteViewAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(views);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteViews(
            selectedItems.map((x) => x.id),
            abortController.signal,
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadViews(state.id, viewsPgination, abortController);
              }
            }
          }
        }
      }
    },
    disabled: getSelectedItems(views).length === 0 || !viewPermissions?.listPermissions.includes("delete-all"),
  };

  const viewActions = [addViewAction, deleteViewAction];

  const [showNewUserPermissionDialog, setShowNewUserPermissionDialog] = useState(false);
  const [editListUserPermission, setEditListUserPermission] = useState<ListUserPermissionFull>();
  const addUserPermissionAction: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowNewUserPermissionDialog(true),
    disabled:
      !userPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteUserPermissionAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(listUserPermissions);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteListUserPermissions(
            selectedItems.map((x) => x.id),
            abortController.signal,
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadListUserPermissions(state.id, userPermissionPagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled:
      getSelectedItems(listUserPermissions).length === 0 ||
      !permissions ||
      !permissions.listPermissions.includes("edit-all"),
  };

  const userPermissionActions = [addUserPermissionAction, deleteUserPermissionAction];

  const [showNewGroupPermissionDialog, setShowNewGroupPermissionDialog] = useState(false);
  const [editListGroupPermission, setEditListGroupPermission] = useState<ListGroupPermissionFull>();
  const addGroupPermissionAction: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowNewGroupPermissionDialog(true),
    disabled:
      !groupPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const editGroupPermissionsAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const item = getSelectedItems(listGroupPermissions)[0];
      item && setEditListGroupPermission(item);
    },
    disabled:
      getSelectedItems(listGroupPermissions).length !== 1 || !groupPermissions?.listPermissions.includes("edit-all"),
  };

  const deleteGroupPermissionAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(listGroupPermissions);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteListGroupPermissions(
            selectedItems.map((x) => x.id),
            abortController.signal,
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadListGroupPermissions(state.id, groupPermissionPagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled:
      getSelectedItems(listGroupPermissions).length === 0 ||
      !permissions ||
      !permissions.listPermissions.includes("edit-all"),
  };

  const groupPermissionActions = [addGroupPermissionAction, editGroupPermissionsAction, deleteGroupPermissionAction];

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.Lists.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Liste"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.internalName.title}</FormLabel>
              <FormData disp>{state?.internalName}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.schema.title}</FormLabel>
              <FormData disp>{state?.schema?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.inheritPermissions.title}</FormLabel>
              <FormData disp>{formatBoolean(state?.inheritPermissions)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.url.title}</FormLabel>
              <FormData disp>
                <Link href={dispUrl(state)}></Link>
              </FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          <ReferencedItemsView
            itemId={state.id}
            id={listIdViews}
            actions={viewActions}
            title={Administration.Lists.Views.Title}
            fields={viewFields}
            allowSelectRows
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadViews(id, pagination, abortController);
            }}
            searchResult={views}
            getFilters={getListviewsFilters}
            pagination={viewsPgination}
            updateItems={(items) =>
              listUserPermissions &&
              setViews({
                ...listUserPermissions,
                items: items,
              })
            }
          />

          {showNewUserPermissionDialog && (
            <NewListUserPermissionDialogForm
              list={state}
              isOpen={showNewUserPermissionDialog}
              onCancel={() => setShowNewUserPermissionDialog(false)}
              onSave={() => {
                const abortController = resetAbortController();
                setShowNewUserPermissionDialog(false);
                loadListUserPermissions(state.id, userPermissionPagination, abortController);
              }}
            />
          )}

          {editListUserPermission && (
            <EditListUserPermissionDialogForm
              item={editListUserPermission}
              isOpen={!!editListUserPermission}
              onCancel={() => setEditListUserPermission(undefined)}
              onSave={() => {
                const abortController = resetAbortController();
                setEditListUserPermission(undefined);
                loadListUserPermissions(state.id, userPermissionPagination, abortController);
              }}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdUserPermissions}
            actions={userPermissionActions}
            title="Benutzer-Berechtigungen"
            fields={userFields}
            allowSelectRows
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadListUserPermissions(id, pagination, abortController);
            }}
            searchResult={listUserPermissions}
            getFilters={getListUsersPermissionsFilters}
            pagination={userPermissionPagination}
            updateItems={(items) =>
              listUserPermissions &&
              setListUserPermissions({
                ...listUserPermissions,
                items: items,
              })
            }
          />

          {showNewGroupPermissionDialog && (
            <NewListGroupPermissionDialogForm
              list={state}
              isOpen={showNewGroupPermissionDialog}
              onCancel={() => setShowNewGroupPermissionDialog(false)}
              onSave={() => {
                const abortController = resetAbortController();
                setShowNewGroupPermissionDialog(false);
                loadListGroupPermissions(state.id, groupPermissionPagination, abortController);
              }}
            />
          )}

          {editListGroupPermission && (
            <EditListGroupPermissionDialogForm
              item={editListGroupPermission}
              isOpen={!!editListGroupPermission}
              onCancel={() => setEditListGroupPermission(undefined)}
              onSave={() => {
                const abortController = resetAbortController();
                setEditListGroupPermission(undefined);
                loadListGroupPermissions(state.id, groupPermissionPagination, abortController);
              }}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdGroupPermissions}
            actions={groupPermissionActions}
            title="Gruppen-Berechtigungen"
            fields={groupFields}
            allowSelectRows
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadListGroupPermissions(id, pagination, abortController);
            }}
            searchResult={listGroupPermissions}
            getFilters={getListGroupsPermissionsFilters}
            pagination={groupPermissionPagination}
            updateItems={(items) =>
              listGroupPermissions &&
              setListGroupPermissions({
                ...listGroupPermissions,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

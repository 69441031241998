import classNames from "classnames";
import { FC } from "react";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import styles from "./Caption.module.css";

interface CaptionProps {
  captions: {
    title: string;
    onclick?: () => void;
  }[];
  navigateBack: () => void;
  navigateForward: () => void;
}

const Caption: FC<CaptionProps> = (props: CaptionProps) => {
  return (
    <div className={styles.caption}>
      {props.captions.map((x, i) => (
        <div
          className={classNames(styles["caption-label"], { [styles.pointer]: x.onclick })}
          onClick={x.onclick}
          key={`caption_${i}`}
        >
          {x.title}
        </div>
      ))}
      <div className={styles.navigation}>
        <div className={styles["nav-button"]} onClick={props.navigateBack}>
          <ArrowLeft />
        </div>
        <div className={styles["nav-button"]} onClick={props.navigateForward}>
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

export default Caption;

const Areas = {
  InternalName: "areas",
  Title: "Gebiete",
};

const ChapterEvents = {
  InternalName: "chapter_events",
  Title: "Veranstaltung",
};

const ChapterEventsCategories = {
  InternalName: "chapter_event_categories",
  Title: "Kategorien",
};

const Districts = {
  InternalName: "districts",
  Title: "Bezirke",
};

const Legislatures = {
  InternalName: "legislatures",
  Title: "Legislaturen",
};

const Chapters = {
  InternalName: "chapters",
  Title: "Ortsgruppen",
};

const ChapterElections = {
  InternalName: "chapter_elections",
  Title: "Neuwahlen",
};

const Functionaries_ = {
  InternalName: "functionaries",
  Title: "Funktionäre",
};

const Roles = {
  InternalName: "roles",
  Title: "Rollen",
};

export const Functionaries = {
  InternalName: "functionaries",
  Title: "Funktionäre",
  Lists: {
    ChapterEvents: ChapterEvents,
    Areas: Areas,
    ChapterEventsCategories: ChapterEventsCategories,
    Chapters: Chapters,
    ChapterElections: ChapterElections,
    Districts: Districts,
    Functionaries: Functionaries_,
    Legislatures: Legislatures,
    Roles: Roles,
  },
};

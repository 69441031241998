import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors from "../../../components/errors/Errors";
import Data from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import { Members } from "../../../listsSettings/members";
import { ErrorCode, IAction } from "../../../services/types";
import { formatBoolean, formatDateFromISOString, formatEuro, getCloseAction } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { AccountingItem, formatCompany } from "./types";
import { allItems } from "./urls";
import { getAccountingItem } from "./webapi";

const DispForm: FC = () => {
  const navigateTo = useNavigate();
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<AccountingItem>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Members.Lists.AccountingItems.InternalName,
      Members.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getAccountingItem(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);
        }
      }
    }
  };

  const closeAction: IAction = getCloseAction(navigateTo, allItems);

  useEffect(() => {
    if (state) {
      setListName(Members.Lists.AccountingItems.Title);
      setPage(`${state.documentNumber} - ${formatDateFromISOString(state.documentDate)}`);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Offener Posten"} />
          <Actions actions={[closeAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.documentNumber.title}</FormLabel>
              <Data disp>{state.documentNumber}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.documentDate.title}</FormLabel>
              <Data disp>{formatDateFromISOString(state.documentDate)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.member.title}</FormLabel>
              <Data disp>{state.member?.name}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.dunningDate.title}</FormLabel>
              <Data disp>{formatDateFromISOString(state.dunningDate)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.dunningLevel.title}</FormLabel>
              <Data disp>{state.dunningLevel}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.company.title}</FormLabel>
              <Data disp>{formatCompany(state.company)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.dueDate.title}</FormLabel>
              <Data disp>{formatDateFromISOString(state.dueDate)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.amount.title}</FormLabel>
              <Data disp>{formatEuro(state.amount)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.debited.title}</FormLabel>
              <Data disp>{formatEuro(state.debited)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.balance.title}</FormLabel>
              <Data disp>{formatEuro(state.balance)}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.paymentMethod.title}</FormLabel>
              <Data disp>{state.paymentMethod}</Data>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.isOpen.title}</FormLabel>
              <Data disp>{formatBoolean(state.isOpen)}</Data>
            </FormTr>
          </FormTable>
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

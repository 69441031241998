import { Administration } from "../../../listsSettings/administration";
import { Result } from "../../../services/types";
import { administration, deleteItems, insertItem, updateItem } from "../../../services/webapi";
import { SchemaGroupPermissionFull, SchemaGroupPermissionPost } from "./types";

const schemaGroupPermissionsUrl: string = `${administration}/${Administration.Lists.SchemaGroupPermissions.InternalName}`;

export const insertSchemaGroupPermission = async (
  schemaGroupPermission: SchemaGroupPermissionPost,
  signal: AbortSignal,
): Promise<Result<SchemaGroupPermissionFull | null>> =>
  insertItem(schemaGroupPermissionsUrl, schemaGroupPermission, signal);

export const updateSchemaGroupPermission = async (
  schemaGroupPermissionId: number,
  schemaGroupPermission: SchemaGroupPermissionPost,
  signal: AbortSignal,
): Promise<Result<SchemaGroupPermissionFull | null>> =>
  updateItem(`${schemaGroupPermissionsUrl}/${schemaGroupPermissionId}`, schemaGroupPermission, signal);

export const deleteSchemaGroupPermissions = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(schemaGroupPermissionsUrl, ids, signal);

import { FC, useEffect } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NotFound from "../../components/errors/NotFound";
import useGlobalState from "../../hooks/useGlobalState";
import { Api } from "../../listsSettings/api";
import { getSchemaByInternalName } from "../../services/webapi";
import AllItemsUserConventionPermissions from "./userConventionsPermissions/AllItems";
import DispFormUserConventionPermissions from "./userConventionsPermissions/DispForm";
import EditFormUserConventionPermissions from "./userConventionsPermissions/EditForm";
import NewFormUserConventionPermissions from "./userConventionsPermissions/NewForm";
import AllItemsUserFunctionaryPermissions from "./userFunctionariesPermissions/AllItems";
import DispFormUserFunctionaryPermissions from "./userFunctionariesPermissions/DispForm";
import EditFormUserFunctionaryPermissions from "./userFunctionariesPermissions/EditForm";
import NewFormUserFunctionaryPermissions from "./userFunctionariesPermissions/NewForm";
import AllItemsUserMemberPermissions from "./userMembersPermissions/AllItems";
import DispFormUserMemberPermissions from "./userMembersPermissions/DispForm";
import EditFormUserMemberPermissions from "./userMembersPermissions/EditForm";
import NewFormUserMemberPermissions from "./userMembersPermissions/NewForm";
import AllItemsUsers from "./users/AllItems";
import DispFormUsers from "./users/DispForm";
import EditFormUsers from "./users/EditForm";
import NewFormUsers from "./users/NewForm";

const Home: FC = () => {
  const setCurrentSchema = useGlobalState((state) => state.setCurrentSchema);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const schema = await getSchemaByInternalName(Api.InternalName, abortController.signal);
      schema.data && setCurrentSchema(schema.data);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AllItemsUsers />} />

      <Route path={`_lists/${Api.Lists.Users.InternalName}`}>
        <Route index element={<AllItemsUsers />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsUsers />} />
          <Route path="new" element={<NewFormUsers />} />
          <Route path="disp/:id" element={<DispFormUsers />} />
          <Route path="edit/:id" element={<EditFormUsers />} />
        </Route>
      </Route>

      <Route path={`_lists/${Api.Lists.UserMemberPermissions.InternalName}`}>
        <Route index element={<AllItemsUserMemberPermissions />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsUserMemberPermissions />} />
          <Route path="new" element={<NewFormUserMemberPermissions />} />
          <Route path="disp/:id" element={<DispFormUserMemberPermissions />} />
          <Route path="edit/:id" element={<EditFormUserMemberPermissions />} />
        </Route>
      </Route>

      <Route path={`_lists/${Api.Lists.UserConventionPermissions.InternalName}`}>
        <Route index element={<AllItemsUserConventionPermissions />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsUserConventionPermissions />} />
          <Route path="new" element={<NewFormUserConventionPermissions />} />
          <Route path="disp/:id" element={<DispFormUserConventionPermissions />} />
          <Route path="edit/:id" element={<EditFormUserConventionPermissions />} />
        </Route>
      </Route>

      <Route path={`_lists/${Api.Lists.UserFunctionaryPermissions.InternalName}`}>
        <Route index element={<AllItemsUserFunctionaryPermissions />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsUserFunctionaryPermissions />} />
          <Route path="new" element={<NewFormUserFunctionaryPermissions />} />
          <Route path="disp/:id" element={<DispFormUserFunctionaryPermissions />} />
          <Route path="edit/:id" element={<EditFormUserFunctionaryPermissions />} />
        </Route>
      </Route>

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default Home;

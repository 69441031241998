import { FC, useState } from "react";
import { MenuOptionValue, Result } from "../../services/types";
import ArrowDown from "../../svg/ArrowDown.svg?react";
import ArrowDropDown from "../../svg/ArrowDropDown.svg?react";
import ArrowUp from "../../svg/ArrowUp.svg?react";
import Filter from "../../svg/Filter.svg?react";
import HeaderMenu from "./HeaderMenu";
import styles from "./TH.module.css";

interface THProps {
  title: string;
  icon?: React.ReactNode;
  fieldName: string;
  isFilterable?: boolean;
  isSortable?: boolean;
  order?: string;
  orderByDescending?: boolean;
  selectedOptions: MenuOptionValue[];
  setOrder?: (fieldName: string, desc: boolean) => void;
  getFilters?: (fieldName: string, abortSignal: AbortSignal) => Promise<Result<MenuOptionValue[] | null>>;
  setFilters?: (fieldName: string, options: MenuOptionValue[]) => void;
  formatter?: (value: MenuOptionValue) => string;
}

const TH: FC<THProps> = (props) => {
  const [show, setShow] = useState(false);

  const setOrder = (event: React.MouseEvent) => {
    if (props.isSortable) {
      const desc = props.order == props.fieldName ? !props.orderByDescending : false;
      props.setOrder && props.setOrder(props.fieldName, desc);
      event.stopPropagation();
    }
  };

  const setOrderByDescending = (orderByDescending: boolean) => {
    props.setOrder && props.setOrder(props.fieldName, orderByDescending);
  };

  const onClose = () => {
    setShow(false);
  };

  const openMenu = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    if (props.isSortable || props.isFilterable) {
      event.stopPropagation();
      setShow(true);
    }
  };

  return (
    <th className={styles.th} onClick={openMenu}>
      <div className={styles.content}>
        {!props.icon && (props.isSortable || props.isFilterable) ? (
          <span className={styles.text} onClick={setOrder}>
            {props.title}
          </span>
        ) : props.icon ? (
          <div>{props.icon}</div>
        ) : (
          <span className={styles.text}>{props.title}</span>
        )}
        {props.isSortable && (
          <>
            {props.order == props.fieldName && !props.orderByDescending && <ArrowUp />}
            {props.order == props.fieldName && props.orderByDescending && <ArrowDown />}
          </>
        )}
        {props.isFilterable && props.selectedOptions.length > 0 && <Filter />}

        <div className={styles["menu-container"]}>
          {!props.icon && (props.isFilterable || props.isSortable) && (
            <div className={styles.menu}>
              <ArrowDropDown />
            </div>
          )}
        </div>
      </div>

      {show && (
        <HeaderMenu
          isSortable={props.isSortable}
          isFilterable={props.isFilterable}
          fieldName={props.fieldName}
          title={props.title}
          onClose={onClose}
          setOrderByDescending={setOrderByDescending}
          getFilters={props.getFilters}
          updateFilter={props.setFilters}
          selectedOptions={props.selectedOptions}
          formatter={props.formatter}
        ></HeaderMenu>
      )}
    </th>
  );
};

export default TH;

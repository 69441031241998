import { $createCodeNode } from "@lexical/code";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from "@lexical/list";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isRangeSelection, LexicalEditor } from "lexical";
import { FC } from "react";
import { BlockType, BlockTypeMap } from "../types";
import styles from "./BlockTypeDropDown.module.css";
import DropDown from "./DropDown";
import DropDownItem from "./DropDownItem";

interface BlockTypeDropDownProps {
  editor: LexicalEditor;
  blockType: BlockType;
}

const BlockTypeDropDown: FC<BlockTypeDropDownProps> = ({ editor, blockType }) => {
  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatHeading = (heading: "h1" | "h2" | "h3") => {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $wrapNodes(selection, () => $createHeadingNode(heading));
      }
    });
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
  };

  return (
    <>
      <DropDown label={BlockTypeMap.get(blockType)} buttonIconClassName={styles[blockType]}>
        <DropDownItem
          onClick={formatParagraph}
          icon={{ className: styles.paragraph }}
          title={BlockTypeMap.get("paragraph")}
        />
        <DropDownItem
          onClick={() => formatHeading("h1")}
          icon={{ className: styles.h1, isOpaque: true }}
          title={BlockTypeMap.get("h1")}
        />
        <DropDownItem
          onClick={() => formatHeading("h2")}
          icon={{ className: styles.h2, isOpaque: true }}
          title={BlockTypeMap.get("h2")}
        />
        <DropDownItem
          onClick={() => formatHeading("h3")}
          icon={{ className: styles.h3, isOpaque: true }}
          title={BlockTypeMap.get("h3")}
        />
        <DropDownItem
          onClick={formatBulletList}
          icon={{ className: styles.ul, isOpaque: true }}
          title={BlockTypeMap.get("ul")}
        />
        <DropDownItem
          onClick={formatNumberedList}
          icon={{ className: styles.ol, isOpaque: true }}
          title={BlockTypeMap.get("ol")}
        />
        <DropDownItem
          onClick={formatQuote}
          icon={{ className: styles.quote, isOpaque: true }}
          title={BlockTypeMap.get("quote")}
        />
        <DropDownItem
          onClick={formatCode}
          icon={{ className: styles.code, isOpaque: true }}
          title={BlockTypeMap.get("code")}
        />
      </DropDown>
    </>
  );
};
export default BlockTypeDropDown;

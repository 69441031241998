import create from "zustand";

export interface StatusBarState {
  errors: [string, boolean][];
  addError: (text: string, sticky?: boolean) => void;
  warnings: [string, boolean][];
  addWarning: (text: string, sticky?: boolean) => void;
  successes: [string, boolean][];
  addSuccess: (text: string, sticky?: boolean) => void;
  infos: [string, boolean][];
  addInfo: (text: string, sticky?: boolean) => void;
  clear: (removeSticky?: boolean) => void;
}

const useStatusBarState = create<StatusBarState>((set) => ({
  errors: [],
  addError: (text: string, sticky?: boolean) => set((state) => ({ errors: [...state.errors, [text, !!sticky]] })),
  warnings: [],
  addWarning: (text: string, sticky?: boolean) => set((state) => ({ warnings: [...state.warnings, [text, !!sticky]] })),
  successes: [],
  addSuccess: (text: string, sticky?: boolean) =>
    set((state) => ({ successes: [...state.successes, [text, !!sticky]] })),
  infos: [],
  addInfo: (text: string, sticky?: boolean) => set((state) => ({ infos: [...state.infos, [text, !!sticky]] })),
  clear: (removeSticky?: boolean) => {
    set((state) => ({
      errors: state.errors.filter(([_, sticky]) => sticky && !removeSticky),
      warnings: state.warnings.filter(([_, sticky]) => sticky && !removeSticky),
      successes: state.successes.filter(([_, sticky]) => sticky && !removeSticky),
      infos: state.infos.filter(([_, sticky]) => sticky && !removeSticky),
    }));
  },
}));

export default useStatusBarState;

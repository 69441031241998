import classNames from "classnames";
import { addDays, addWeeks, endOfWeek, format, isAfter, isToday, startOfWeek } from "date-fns";
import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  dateToString,
  getCurrentUrlAsSource,
  getDateFromUrlParams,
  setCalendarParamsToUrl,
} from "../../services/utils";
import ArrowLeft from "../../svg/ArrowLeft.svg?react";
import ArrowRight from "../../svg/ArrowRight.svg?react";
import Link from "../Link";
import DailyCell from "./DailyCell";
import { CalendarCell, CalendarItem, CalendarListData, CalendarProps, CalendarWeek, CalenderDataItem } from "./types";
import { getFractors, getWeeklyTitle, setItem, setWidths, sortItems } from "./utils";
import styles from "./WeeklyCalendar.module.css";

interface WeeklyCalendarProps extends CalendarProps {
  defaultWeek?: Date;
  denySelectDay?: true;
  dayStart?: number;
  dayEnd?: number;
}

const WeeklyCalendar: FC<WeeklyCalendarProps> = (props: WeeklyCalendarProps) => {
  const navigateTo = useNavigate();
  const aDate = getDateFromUrlParams();
  const [currentWeek, setCurrentWeek] = useState<Date>(aDate ?? props.defaultWeek ?? new Date());
  const [weeklyItems, setWeeklyItems] = useState<CalendarWeek>();
  const [time, setTime] = useState<{ days: Date[][]; start: number; end: number }>({
    days: [],
    start: 7,
    end: 19,
  });

  const firstDayOfWeek = startOfWeek(currentWeek);
  const lastDayOfWeek = endOfWeek(currentWeek);

  const update = (date: Date) => {
    setCalendarParamsToUrl(navigateTo, date);
    props.updateCalendar(getWeeklyTitle(date), "week");
  };

  const prevWeek = () => {
    const prevWeek = addWeeks(currentWeek, -1);
    setCurrentWeek(prevWeek);
    update(prevWeek);
  };

  const nextWeek = () => {
    const nextWeek = addWeeks(currentWeek, 1);
    setCurrentWeek(nextWeek);
    update(nextWeek);
  };

  useEffect(() => {
    (async () => {
      update(currentWeek);

      const data: { items: { [key: string]: [CalendarListData, CalendarItem][] }; start: number; end: number } = {
        items: {},
        start: props.dayStart ?? 7,
        end: props.dayEnd ?? 19,
      };

      const allData = await props.data.reduce(async (rv: Promise<CalenderDataItem[]>, d) => {
        const [rvResult, items] = await Promise.all([rv, d.getItems(firstDayOfWeek, lastDayOfWeek)]);
        const itemsResult = items.map((x) => ({ data: d, item: x }));
        items.forEach((item) => {
          const min = Math.floor((item.from.getMinutes() * 2) / 60) * 30;
          const key = format(item.from, `yyyy-MM-dd-${item.from.getHours()}-${min}`);
          (data.items[key] = data.items[key] || []).push([d, item]);

          if (item.from.getHours() < data.start) {
            data.start = item.from.getHours();
          }

          const maxDay = Math.ceil(item.to.getMinutes() / 60) + item.to.getHours();
          if (maxDay > data.end) {
            data.end = maxDay;
          }
        });
        return [...rvResult, ...itemsResult];
      }, Promise.resolve<CalenderDataItem[]>([]));

      const groupedByDay: { [key: string]: CalenderDataItem[] } = allData.reduce(
        (rv: { [key: string]: CalenderDataItem[] }, value) => {
          const key = format(value.item.from, "yyyy-MM-dd");
          (rv[key] = rv[key] || []).push(value);
          return rv;
        },
        {},
      );

      const weeklyItems: CalendarWeek = Object.keys(groupedByDay).reduce((week: CalendarWeek, date) => {
        const values = groupedByDay[date];
        if (values) {
          values.sort(sortItems);
          if (!week[date]) {
            week[date] = {};
          }

          const day = week[date];
          if (day) {
            values.forEach((value) => setItem(day, value));
            const dayWithFractors: { [key: string]: [CalendarCell, number][] } = getFractors(day);
            setWidths(dayWithFractors);
          }
        }
        return week;
      }, {});

      setWeeklyItems(weeklyItems);

      let tmpDate = new Date(firstDayOfWeek);
      const allDays: Date[][] = [];
      while (!isAfter(tmpDate, lastDayOfWeek)) {
        let hour = data.start;
        while (hour < data.end) {
          (allDays[hour] = allDays[hour] || [])?.push(tmpDate);
          hour++;
        }
        tmpDate = addDays(tmpDate, 1);
      }
      setTime({ days: allDays, start: data.start, end: data.end });
    })();
  }, [format(currentWeek, "yyyy-MM-dd")]);

  const getLinkToDay = (date: Date) => {
    const title = format(date, "dd. EEEE");
    return !props.denySelectDay ? (
      <div
        className={styles["cal-week-day-link"]}
        onClick={() => {
          setCalendarParamsToUrl(navigateTo, date);
          props.updateCalendar(getWeeklyTitle(date), "day");
        }}
      >
        {title}
      </div>
    ) : (
      title
    );
  };

  const renderHour = (date: Date, time: number, items?: CalendarCell[]) => {
    return (
      <>
        {items && items.length > 0 ? <DailyCell data={items} /> : null}
        {(props.onAddItem || props.addHref) && (
          <div
            onClick={() => !props.addHref && props.onAddItem && props.onAddItem(date)}
            className={styles["cal-week-add-item"]}
          >
            {props.addHref ? (
              <Link href={`${props.addHref}?date=${dateToString(date)}&from=${time}&${getCurrentUrlAsSource()}`}>
                +
              </Link>
            ) : (
              "+"
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <table className={styles["cal-week"]} cellPadding={0} cellSpacing={0}>
      <tbody>
        <tr>
          <th></th>
          <th></th>
          {time.days[time.start]?.map((x) => (
            <th
              className={classNames(styles["cal-week-top"], styles["cal-week-day"])}
              key={`week-cal-header_${x.getDate()}`}
            >
              {getLinkToDay(x)}
            </th>
          ))}
        </tr>
        {weeklyItems &&
          time.days.map((week, i) => (
            <Fragment key={`week-cal-row_${i}`}>
              <tr className={styles["cal-week-hour00"]}>
                {i == time.start && (
                  <td
                    onClick={prevWeek}
                    rowSpan={time.days.length * 2}
                    title={getWeeklyTitle(addWeeks(currentWeek, -1))}
                    className={styles["cal-week-weeksel"]}
                  >
                    <div>
                      <ArrowLeft />
                    </div>
                  </td>
                )}
                <td className={styles["cal-week-time00"]}>{i}</td>
                {week.map((day) => (
                  <td
                    key={`week-cal-row-${i}-time00-${day.getDate()}`}
                    className={classNames(styles["cal-week-cell"], {
                      [styles["cal-week-today"]]: isToday(day),
                    })}
                  >
                    <div>
                      {renderHour(
                        day,
                        i,
                        weeklyItems?.[format(day, `yyyy-MM-dd`)]?.[`${i * 60}`.toString().padStart(3, "0")],
                      )}
                    </div>
                  </td>
                ))}
                {i == time.start && (
                  <td
                    onClick={nextWeek}
                    rowSpan={time.days.length * 2}
                    title={getWeeklyTitle(addWeeks(currentWeek, 1))}
                    className={styles["cal-week-weeksel"]}
                  >
                    <div>
                      <ArrowRight />
                    </div>
                  </td>
                )}
              </tr>
              <tr className={styles["cal-week-hour30"]}>
                <td className={styles["cal-week-time30"]}></td>
                {week.map((day) => (
                  <td
                    key={`week-cal-row_${i}-time30-${day.getDate()}`}
                    className={classNames(styles["cal-week-cell"], {
                      [styles["cal-week-today"]]: isToday(day),
                    })}
                  >
                    <div>
                      {renderHour(
                        day,
                        i + 0.5,
                        weeklyItems?.[format(day, `yyyy-MM-dd`)]?.[`${i * 60 + 30}`.toString().padStart(3, "0")],
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            </Fragment>
          ))}
      </tbody>
    </table>
  );
};

export default WeeklyCalendar;

import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { PickerType, TimeMode } from "./constants";
import styles from "./PickerDragHandler.module.css";
import { getInitialPointerStyle, getRotateStyle } from "./utils";

interface PickerDragHandlerProps {
  timeMode: TimeMode;
  time: number;
  type: PickerType;
  draggable?: boolean;
  minLength: number;
  maxLength?: number;
  minuteStep: number;
  limitDrag?: boolean;
  rotateState: {
    top?: number;
    height?: number;
    pointerRotate: number;
  };
  handleTimePointerClick: (options: { value: number; autoMode?: boolean; pointerRotate?: number }) => void;
}

interface State {
  top: number;
  height: number;
  pointerRotate: number;
  startX: number;
  startY: number;
  originX: number;
  originY: number;
  dragCenterX: number;
  dragCenterY: number;
  offsetDragCenterX: number;
  offsetDragCenterY: number;
}

const PickerDragHandler: FC<PickerDragHandlerProps> = (props: PickerDragHandlerProps) => {
  const dragCenter = useRef<HTMLDivElement>(null);
  const pickerCenter = useRef<HTMLDivElement>(null);

  const initialRotationAndLength = (): State => {
    return {
      top: props.rotateState.top ?? 0,
      height: props.rotateState.height ?? 0,
      pointerRotate: props.rotateState.pointerRotate,
      startX: 0,
      startY: 0,
      originX: 0,
      originY: 0,
      dragCenterX: 0,
      dragCenterY: 0,
      offsetDragCenterX: 0,
      offsetDragCenterY: 0,
    };
  };

  const [state, setState] = useState<State>(initialRotationAndLength());

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialRotationAndLength(),
    }));
  }, [props.type, props.time, props.rotateState]);

  const pickerPointerClass = classNames(styles["picker-pointer"], { [styles.animation]: false });

  const initialStyle = getInitialPointerStyle(state.height, state.top, state.pointerRotate);

  return (
    <div>
      <div className={pickerPointerClass} style={initialStyle}>
        <div
          ref={dragCenter}
          className={classNames(styles["pointer-drag"], { [styles.draggable]: props.draggable })}
          style={getRotateStyle(-state.pointerRotate)}
        >
          {Math.round(props.time)}
        </div>
      </div>
      <div className={styles["picker-center"]} ref={pickerCenter} />
    </div>
  );
};

export default PickerDragHandler;

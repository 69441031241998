import classNames, { Argument } from "classnames";
import { FC } from "react";

interface NavigationItemProps {
  classNamesArgs: Argument[];
  indentLevel: number;
  title: string;
  url?: string;
}

const NavigationItem: FC<NavigationItemProps> = (props: NavigationItemProps) => (
  <div className={classNames(props.classNamesArgs)}>
    <span style={{ paddingLeft: props.indentLevel * 20 }}>
      {props.url == location.pathname ? <b>{props.title}</b> : <>{props.title}</>}
    </span>
  </div>
);

export default NavigationItem;

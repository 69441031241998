import classNames from "classnames";
import { FC } from "react";
import styles from "./Action.module.css";

interface ActionProps {
  icon?: FC;
  title?: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const Action: FC<ActionProps> = ({ icon: Icon, title, disabled, active, onClick }: ActionProps) => {
  const clazz = classNames(styles.action, { [styles.disabled]: disabled, [styles.active]: active });

  return (
    <div className={clazz} onClick={() => !disabled && onClick && onClick()} title={title}>
      {Icon && <Icon />}
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default Action;

import classNames from "classnames";
import { FC } from "react";
import Check from "../../svg/Check.svg?react";
import styles from "./TH.module.css";

interface ToggleAllHeaderProps {
  toggleAll: () => void;
}

const ToggleAllHeader: FC<ToggleAllHeaderProps> = ({ toggleAll }: ToggleAllHeaderProps) => {
  return (
    <th className={classNames(styles.th, styles.text)} onClick={toggleAll}>
      <div className={styles.check}>
        <Check />
      </div>
    </th>
  );
};

export default ToggleAllHeader;

import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { PartnerFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<PartnerFull> & IDialogField<PartnerFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: PartnerFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: PartnerFull) => item.name,
};

export const street: IListviewField<PartnerFull> = {
  title: "Straße",
  fieldName: "street",
  isSortable: true,
  renderListValue: (item: PartnerFull) => item.street,
};

export const zip: IListviewField<PartnerFull> = {
  title: "PLZ",
  fieldName: "zip",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: PartnerFull) => item.zip,
};

export const city: IListviewField<PartnerFull> & IDialogField<PartnerFull> = {
  title: "Stadt",
  fieldName: "city",
  isSortable: true,
  renderListValue: (item: PartnerFull) => item.city,
  renderDialogValue: (item: PartnerFull) => item.city,
};

export const province: IListviewField<PartnerFull> = {
  title: "Provinz",
  fieldName: "province",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: PartnerFull) => item.province,
};

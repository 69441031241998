import { FC } from "react";
import TimePickerWithInput, { TimePickerWithInputProps } from "./TimePickerWithInput";
import styles from "./TimeRangeWithInputPicker.module.css";

interface TimeRangePickerWithInputProps {
  from: TimePickerWithInputProps;
  to: TimePickerWithInputProps;
  autoMode?: boolean;
}

const TimeRangePickerWithInput: FC<TimeRangePickerWithInputProps> = (props: TimeRangePickerWithInputProps) => {
  return (
    <div className={styles.container}>
      <TimePickerWithInput onSelect={props.from.onSelect} value={props.from.value} autoMode={props.autoMode} />
      <span>&nbsp;-&nbsp;</span>
      <TimePickerWithInput onSelect={props.to.onSelect} value={props.to.value} autoMode={props.autoMode} borderLeft />
    </div>
  );
};

export default TimeRangePickerWithInput;

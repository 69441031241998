import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { DistrictFull } from "../districts/types";
import * as Fields from "./Fields";

export interface AreaFull extends CreationInformationItem {
  id: number;
  name: string;
  order: number;
  district: DistrictFull;
}

export interface AreaForm {
  name: string | null;
  order: number | null;
  district: DistrictFull | null;
}

export interface AreaPost {
  name: string;
  order: number;
  districtId: number;
}

export const mapToPOST = (item: AreaForm): AreaPost => {
  return {
    name: item.name || "",
    order: item.order || 0,
    districtId: item.district?.id || 0,
  };
};

export const validate = (
  state: AreaForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.order) {
    errors[Fields.order.fieldName] = strings.required;
  }
  if (!state.district) {
    errors[Fields.district.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

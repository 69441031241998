import Link from "../../../components/Link";
import { IListviewField, MenuOptionValue } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { memberTypes as allMemberTypes, renderMemberType, UserMemberPermissionFull } from "./types";
import { dispForm } from "./urls";

export const userName: IListviewField<UserMemberPermissionFull> = {
  title: "Benutzer",
  fieldName: "userName",
  isSortable: true,
  renderListValue: (item: UserMemberPermissionFull) => (
    <Link href={`${dispForm}/${item.user.id}?${getCurrentUrlAsSource()}`}>{item.user.name}</Link>
  ),
};

export const userKey: IListviewField<UserMemberPermissionFull> = {
  title: "Schlüssel",
  fieldName: "userKey",
  isSortable: true,
  renderListValue: (item: UserMemberPermissionFull) => item.user.key,
};

export const memberTypes: IListviewField<UserMemberPermissionFull> = {
  title: "Mitglieder",
  fieldName: "memberTypes",
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) =>
    allMemberTypes
      .filter((x) => item.memberTypes.indexOf(x) >= 0)
      .map((x) => `${renderMemberType(x)};`)
      .sort((a, b) => a.localeCompare(b))
      .join(" "),
  renderMenuOptionValue: (value: MenuOptionValue) => {
    const text = allMemberTypes.filter((x) => x === value)[0];
    return value ? renderMemberType(text) : "(Leer)";
  },
};

export const canceledMembers: IListviewField<UserMemberPermissionFull> = {
  title: "Annullierte Mitglieder",
  fieldName: "canceledMembers",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => formatBoolean(item.canceledMembers),
};

export const companies: IListviewField<UserMemberPermissionFull> = {
  title: "Mitgliedsbetriebe",
  fieldName: "companies",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => formatBoolean(item.companies),
};

export const canceledCompanies: IListviewField<UserMemberPermissionFull> = {
  title: "Annullierte Mitgliedsbetriebe",
  fieldName: "canceledCompanies",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => formatBoolean(item.canceledCompanies),
};

export const legalRepresentative: IListviewField<UserMemberPermissionFull> = {
  title: "Gesetzlicher Vertreter",
  fieldName: "legalRepresentative",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => formatBoolean(item.legalRepresentative),
};

export const departmentData: IListviewField<UserMemberPermissionFull> = {
  title: "Abteilungsdaten",
  fieldName: "departmentData",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => formatBoolean(item.departmentData),
};

export const customEndpoint: IListviewField<UserMemberPermissionFull> = {
  title: "Custom Endpoint",
  fieldName: "customEndpoint",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => formatBoolean(!!item.endpoint),
};

export const endpoint: IListviewField<UserMemberPermissionFull> = {
  title: "Endpoint",
  fieldName: "endpoint",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: UserMemberPermissionFull) => item.endpoint,
};

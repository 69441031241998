import classNames from "classnames";
import { FC } from "react";
import { HOURS, MINUTES, PickerType, TimeMode, TWELVE_HOURS } from "./constants";
import PickerPoint from "./PickerPoint";
import styles from "./PickerPointGenerator.module.css";

interface PickerPointGeneratorProps {
  type: PickerType;
  mode?: TimeMode;
  pointerRotate: number;
  handleTimePointerClick: (value: number, pointerRotate: number) => void;
}

const PickerPointGenerator: FC<PickerPointGeneratorProps> = (props: PickerPointGeneratorProps) => {
  const renderHourPointes = () => {
    const hours = (props.mode ?? 24) === 24 ? HOURS : TWELVE_HOURS;
    return hours.map((_, index) => {
      const pointClass = classNames(styles["picker-point"], {
        [styles["point-inner"]]: index < 12,
        [styles["point-outter"]]: index >= 12,
      });
      const angle = index < 12 ? (360 * index) / 12 : (360 * (index - 12)) / 12;
      return (
        <PickerPoint
          key={index}
          angle={angle}
          index={index}
          pointClass={pointClass}
          pointerRotate={props.pointerRotate}
          onClick={props.handleTimePointerClick}
        />
      );
    });
  };
  const renderMinutePointes = () => {
    const pointClass = classNames(styles["picker-point"], styles["point-outter"]);
    return MINUTES.map((_, index) => (360 * index) / 60)
      .filter((angle) => ((angle * 60) / 360) % 5 === 0)
      .map((angle) => {
        const index = (angle * 60) / 360;
        return (
          <PickerPoint
            key={index}
            angle={angle}
            index={index}
            pointClass={pointClass}
            pointerRotate={props.pointerRotate}
            onClick={props.handleTimePointerClick}
          />
        );
      });
  };
  return (
    <div className={styles["picker-pointer_container"]}>
      {props.type === "hour" ? renderHourPointes() : renderMinutePointes()}
    </div>
  );
};
export default PickerPointGenerator;

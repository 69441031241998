import { FocusScope } from "@react-aria/focus";
import { AriaOverlayProps, OverlayContainer, useOverlay, usePreventScroll } from "@react-aria/overlays";
import { FC, PropsWithChildren, useRef } from "react";
import styles from "./Modal.module.css";

const Modal: FC<PropsWithChildren<AriaOverlayProps>> = (props: PropsWithChildren<AriaOverlayProps>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  usePreventScroll();

  return (
    <OverlayContainer>
      <div className={styles.overlay} {...underlayProps}>
        <FocusScope contain restoreFocus autoFocus>
          <div ref={ref} className={styles.dialog} {...overlayProps}>
            {props.children}
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  );
};

export default Modal;

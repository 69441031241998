import { format, getWeek, parseISO, setWeek } from "date-fns";
import { NavigateFunction } from "react-router-dom";
import strings from "../strings";
import Cross from "../svg/Cross.svg?react";
import Save from "../svg/Save.svg?react";
import { IAction, Item, SearchResult } from "./types";

export const getStringFromUrl = (param: string): string | null => {
  var params = new URLSearchParams(location.search);
  return params.get(param);
};

export const getNumberFromUrl = (param: string): number | null => {
  var p = Number(getStringFromUrl(param));
  return p && !isNaN(p) ? p : null;
};

export const getParamFromUrl = (param: string): string | null => {
  return getStringFromUrl(param);
};

export const setParamToUrl = (navigateTo: NavigateFunction, key: string, value: string | number) => {
  const params = new URLSearchParams(location.search);
  params.set(key, encodeURIComponent(value));
  navigateTo(`${location.pathname}?${params.toString()}`, { replace: true });
};

export const camalize = (str: string) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());

export const standarizeUrl = (url: string): string => (url.startsWith("http") ? url : `http://${url}`);

export const formatBoolean = (value?: boolean | null) => (value ? "Ja" : "Nein");

export const formatEuro = (value?: number | null, fractionalDigits?: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: fractionalDigits ?? 2,
    maximumFractionDigits: fractionalDigits ?? 2,
  }).format(value ?? 0);
};
const formatDate = (date?: Date | null, withTime?: boolean): string => {
  if (!date) return "";

  const pattern = withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";

  return format(date, pattern);
};

export const formatDateFromISOString = (value?: string | null, withTime?: boolean) => {
  if (!value) return "";

  return formatDate(parseISO(value), withTime);
};

export const formatTimeFromMinutes = (time?: number | null): string => {
  const hour = time !== null && time !== undefined ? Math.floor(time / 60) : null;
  const min = time !== null && time !== undefined && hour !== null && hour !== undefined ? time - hour * 60 : null;
  return hour !== null && hour !== undefined && min !== null && min !== undefined
    ? `${hour.toString().padStart(2, "0")}:${min.toString().padStart(2, "0")}`
    : "";
};

export const getSelectedItems = <T extends Item>(searchResult: SearchResult<T> | null): T[] =>
  searchResult?.items.filter((x) => x.selected) || [];

export const downloadFileFromData = (data: string, fileName: string, contentType?: string) => {
  const toString = function (a: object) {
    return String(a);
  };
  const myBlob = (window.Blob || toString).bind(window);

  const anchor = document.createElement("a");

  const d = window.atob(data);

  const tType = contentType || "application/octet-stream";

  if (/([\x80-\xff])/.test(d)) {
    const tempUiArr = new Uint8Array(d.length);
    const mx = tempUiArr.length;
    for (var i = 0; i < mx; ++i) {
      tempUiArr[i] = d.charCodeAt(i);
    }
    const o = new myBlob([tempUiArr], { type: tType });
    anchor.href = window.URL.createObjectURL(o);
  } else {
    const o = new myBlob([d || ""], { type: tType });
    anchor.href = window.URL.createObjectURL(o);
  }

  anchor.setAttribute("download", fileName);
  anchor.className = "download-js-link";
  anchor.innerHTML = "downloading...";
  anchor.style.display = "none";
  document.body.appendChild(anchor);
  setTimeout(function () {
    anchor.click();
    document.body.removeChild(anchor);
    setTimeout(function () {
      window.URL.revokeObjectURL(anchor.href);
    }, 250);
  }, 66);
  return true;
};

export const getCloseAction = (navigateTo: NavigateFunction, defaultUrl: string): IAction => ({
  title: strings.close,
  icon: Cross,
  onClick: () => navigateTo(getSourceFromUrl() ?? defaultUrl),
});

export const getCancelAction = (navigateTo: NavigateFunction, defaultUrl: string): IAction => ({
  title: strings.cancel,
  icon: Cross,
  onClick: () => navigateTo(getSourceFromUrl() ?? defaultUrl),
});

export const getSaveAction = (save: () => Promise<void | undefined>): IAction => ({
  title: strings.save,
  icon: Save,
  onClick: save,
});

export const getSourceFromUrl = (): string | null => getParamFromUrl("source");

export const getCurrentUrlAsSource = (): string => {
  const path = location.pathname;
  const query = location.search;
  const source = `${path}${query}`;
  return `source=${encodeURIComponent(source)}`;
};

export const getEncodedSourceFromUrl = (): string => {
  const source = getParamFromUrl("source");
  return source ? `source=${encodeURIComponent(source)}` : "";
};

export const dateToString = (date: Date): string =>
  `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}T00:00:00.000Z`;

export const setCalendarParamsToUrl = (navigateTo: NavigateFunction, date: Date) => {
  setParamToUrl(navigateTo, "d", date.getDate());
  setParamToUrl(navigateTo, "m", date.getMonth() + 1);
  setParamToUrl(navigateTo, "y", date.getFullYear());
  setParamToUrl(navigateTo, "w", getWeek(date));
};

export const getDateFromUrlParams = () => {
  const y = getNumberFromUrl("y");
  const m = getNumberFromUrl("m");
  const w = getNumberFromUrl("w");
  const d = getNumberFromUrl("d");
  if (!y && !m && !w && !d) {
    return null;
  }

  let date = new Date();
  if (y) {
    date.setFullYear(y);
  }
  if (m) {
    date.setMonth(m - 1);
  }
  if (w) {
    setWeek(date, w);
  }
  if (d) {
    date.setDate(d);
  }
  return date;
};

export const isEmailValid = (text: string): boolean => {
  if (!text) {
    return false;
  }

  return !!text.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
};

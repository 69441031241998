import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { FC } from "react";
import { Route } from "react-router";
import { BrowserRouter, Routes } from "react-router-dom";
import { msalApp } from "../auth/auth";
import Home from "../home/Home";
import HomeAdministration from "../home/administration/Home";
import HomeApi from "../home/api/Home";
import HomeConventions from "../home/conventions/Home";
import HomeFunctionaries from "../home/functionaries/Home";
import HomeMembers from "../home/members/Home";
import { Administration } from "../listsSettings/administration";
import { Api } from "../listsSettings/api";
import { Conventions } from "../listsSettings/conventions";
import { Functionaries } from "../listsSettings/functionaries";
import { Members } from "../listsSettings/members";
import MainLayout from "./MainLayout";
import Ribbon from "./Ribbon";
import Spinner from "./Spinner";
import StatusBar from "./StatusBar";
import EditGlobalNavigation from "./menus/global/EditGlobalNavigation";

const App: FC = () => {
  return (
    <MsalProvider instance={msalApp}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} loadingComponent={Spinner}>
        <BrowserRouter>
          <Ribbon />
          <StatusBar></StatusBar>
          <Routes>
            <Route
              path={"/_settings/edit_menu"}
              element={
                <MainLayout hideCurrentNavigation>
                  <EditGlobalNavigation />
                </MainLayout>
              }
            />
            <Route
              path={"/" + Administration.InternalName + "/*"}
              element={
                <MainLayout>
                  <HomeAdministration />
                </MainLayout>
              }
            />
            <Route
              path={"/" + Functionaries.InternalName + "/*"}
              element={
                <MainLayout>
                  <HomeFunctionaries />
                </MainLayout>
              }
            />
            <Route
              path={"/" + Members.InternalName + "/*"}
              element={
                <MainLayout>
                  <HomeMembers />
                </MainLayout>
              }
            />
            <Route
              path={"/" + Conventions.InternalName + "/*"}
              element={
                <MainLayout>
                  <HomeConventions />
                </MainLayout>
              }
            />
            <Route
              path={"/" + Api.InternalName + "/*"}
              element={
                <MainLayout>
                  <HomeApi />
                </MainLayout>
              }
            />
            <Route
              path="*"
              element={
                <MainLayout>
                  <Home />
                </MainLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default App;

import classNames from "classnames";
import { addDays, format } from "date-fns";
import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  dateToString,
  getCurrentUrlAsSource,
  getDateFromUrlParams,
  setCalendarParamsToUrl,
} from "../../services/utils";
import ArrowLeft from "../../svg/ArrowLeft.svg?react";
import ArrowRight from "../../svg/ArrowRight.svg?react";
import Link from "../Link";
import styles from "./DailyCalendar.module.css";
import DailyCell from "./DailyCell";
import { CalendarCell, CalendarDay, CalendarItem, CalendarListData, CalendarProps, CalenderDataItem } from "./types";
import { getDailyTitle, getFractors, setItem, setWidths, sortItems } from "./utils";

interface DailyCalendarProps extends CalendarProps {
  defaultDay?: Date;
  dayStart?: number;
  dayEnd?: number;
}

const DailyCalendar: FC<DailyCalendarProps> = (props: DailyCalendarProps) => {
  const navigateTo = useNavigate();
  const aDate = getDateFromUrlParams();
  const [currentDay, setCurrentDay] = useState<Date>(aDate ?? props.defaultDay ?? new Date());
  const [dailyItems, setDailyItems] = useState<CalendarDay>();
  const [time, setTime] = useState<{ allHours: number[]; start: number; end: number }>({
    allHours: [],
    start: 7,
    end: 19,
  });

  const update = (date: Date) => {
    setCalendarParamsToUrl(navigateTo, date);
    props.updateCalendar(getDailyTitle(date), "day");
  };

  const prevDay = () => {
    const prevDay = addDays(currentDay, -1);
    setCurrentDay(prevDay);
    update(prevDay);
  };

  const nextDay = () => {
    const nextDay = addDays(currentDay, 1);
    setCurrentDay(nextDay);
    update(nextDay);
  };

  useEffect(() => {
    (async () => {
      update(currentDay);

      const data: { items: { [key: string]: [CalendarListData, CalendarItem][] }; start: number; end: number } = {
        items: {},
        start: props.dayStart ?? 7,
        end: props.dayEnd ?? 19,
      };

      const allData = await props.data.reduce(async (rv: Promise<CalenderDataItem[]>, d) => {
        const [rvResult, items] = await Promise.all([rv, d.getItems(currentDay, currentDay)]);
        const itemsResult = items.map((x) => ({ data: d, item: x }));
        items.forEach((item) => {
          const min = Math.floor((item.from.getMinutes() * 2) / 60) * 30;
          const key = format(item.from, `yyyy-MM-dd-${item.from.getHours()}-${min}`);
          (data.items[key] = data.items[key] || []).push([d, item]);

          if (item.from.getHours() < data.start) {
            data.start = item.from.getHours();
          }

          const maxDay = Math.ceil(item.to.getMinutes() / 60) + item.to.getHours();
          if (maxDay > data.end) {
            data.end = maxDay;
          }
        });
        return [...rvResult, ...itemsResult];
      }, Promise.resolve<CalenderDataItem[]>([]));

      const dailyItems: CalendarDay = allData.sort(sortItems).reduce((day: CalendarDay, value) => {
        setItem(day, value, 0);
        return day;
      }, {});

      const dayWithFractors: { [key: string]: [CalendarCell, number][] } = getFractors(dailyItems);
      setWidths(dayWithFractors);

      setDailyItems(dailyItems);

      let tmpHour = data.start;
      const allHours: number[] = [];
      while (tmpHour < data.end) {
        allHours.push(tmpHour);
        tmpHour++;
      }
      setTime({ allHours: allHours, start: data.start, end: data.end });
    })();
  }, [format(currentDay, "yyyy-MM-dd")]);

  const renderHour = (date: Date, time: number, dailyData?: CalendarCell[]) => {
    return (
      <>
        {dailyData && dailyData.length > 0 && <DailyCell data={dailyData} />}
        {(props.onAddItem || props.addHref) && (
          <div
            onClick={() => !props.addHref && props.onAddItem && props.onAddItem(date)}
            className={styles["cal-day-add-item"]}
          >
            {props.addHref ? (
              <Link href={`${props.addHref}?date=${dateToString(date)}&from=${time}&${getCurrentUrlAsSource()}`}>
                +
              </Link>
            ) : (
              "+"
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <table className={styles["cal-day"]} cellPadding={0} cellSpacing={0}>
      <tbody>
        <tr>
          <th></th>
          <th></th>
          <th className={classNames(styles["cal-day-top"], styles["cal-day-day"])}>{format(currentDay, "EEEE")}</th>
        </tr>
        {time.allHours.map((hour) => (
          <Fragment key={`day-cal-row_${hour}`}>
            <tr className={styles["cal-day-hour00"]}>
              {hour == time.allHours[0] && (
                <td
                  onClick={prevDay}
                  rowSpan={time.allHours.length * 2}
                  title={getDailyTitle(addDays(currentDay, -1))}
                  className={styles["cal-day-daysel"]}
                >
                  <div>
                    <ArrowLeft />
                  </div>
                </td>
              )}
              <td className={styles["cal-day-time00"]}>{hour}</td>
              <td className={styles["cal-day-cell"]}>
                <div>{renderHour(currentDay, hour, dailyItems?.[`${hour * 60}`.toString().padStart(3, "0")])}</div>
              </td>
              {hour == time.allHours[0] && (
                <td
                  onClick={nextDay}
                  rowSpan={time.allHours.length * 2}
                  title={getDailyTitle(addDays(currentDay, 1))}
                  className={styles["cal-day-daysel"]}
                >
                  <div>
                    <ArrowRight />
                  </div>
                </td>
              )}
            </tr>
            <tr className={styles["cal-day-hour30"]}>
              <td className={styles["cal-day-time30"]}></td>
              <td className={styles["cal-day-cell"]}>
                <div>
                  {renderHour(currentDay, hour + 0.5, dailyItems?.[`${hour * 60 + 30}`.toString().padStart(3, "0")])}
                </div>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default DailyCalendar;

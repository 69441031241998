import { Pagination } from "../../../components/listview/Pagination";
import { Members } from "../../../listsSettings/members";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { getFilters, getItem, getItems, members } from "../../../services/webapi";
import { Fee } from "./types";

const feeUrl: string = `${members}/${Members.Lists.Fees.InternalName}`;

export const getFee = async (id: number, signal: AbortSignal): Promise<Result<Fee | null>> => {
  const url = `${feeUrl}/${id}`;
  return getItem<Fee>(url, signal);
};

export const getFees = async (pagination: Pagination, signal: AbortSignal): Promise<Result<SearchResult<Fee> | null>> =>
  getItems<Fee>(feeUrl, pagination, signal);

export const getMemberFees = async (
  memberId: number,
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<Fee> | null>> =>
  getItems<Fee>(`${members}/${Members.Lists.Members.InternalName}/${memberId}/fees`, pagination, signal);

export const getFeesCurrentYear = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<Fee> | null>> => getItems<Fee>(`${feeUrl}/current_year`, pagination, signal);

export const getMemberFeeFilters = (
  memberId: number,
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${members}/${Members.Lists.Members.InternalName}/${memberId}/fees`, fieldName, signal);

export const getFeeFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(feeUrl, fieldName, signal);

export const getFeeCurrentYearFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${feeUrl}/current_year`, fieldName, signal);

import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { IAction } from "../../services/types";
import { getParamFromUrl, setParamToUrl } from "../../services/utils";
import CalendarDay from "../../svg/CalendarDay.svg?react";
import CalendarMonth from "../../svg/CalendarMonth.svg?react";
import CalendarWeek from "../../svg/CalendarWeek.svg?react";
import Title from "../Title";
import Actions from "../controls/Actions";
import styles from "./Calendar.module.css";
import DailyCalendar from "./DailyCalendar";
import MonthlyCalendar from "./MonthlyCalendar";
import WeeklyCalendar from "./WeeklyCalendar";
import { CalendarListData, CalendarType, isCalendarType } from "./types";

interface CalendarProps {
  defaultType?: CalendarType;
  defaultDate?: Date;
  denySelectType?: true;
  denySelectDay?: true;
  showWeeks?: true;
  data: CalendarListData[];
  addHref?: string;
  onAddItem?: (date: Date) => void;
}

const Calendar: FC<CalendarProps> = (props: CalendarProps) => {
  const getTypeFromUrl = (): CalendarType | null => {
    const type = getParamFromUrl("type");
    return isCalendarType(type) ? type : null;
  };

  const [type, setType] = useState<CalendarType>(getTypeFromUrl() ?? props.defaultType ?? "month");
  const [title, setTitle] = useState("");
  const navigateTo = useNavigate();

  const updateCalendar = (title: string, type: CalendarType) => {
    setTitle(title);
    setType(type);
    setParamToUrl(navigateTo, "type", type);
  };

  const monthAction: IAction = {
    icon: CalendarMonth,
    onClick: () => {
      setType("month");
      setParamToUrl(navigateTo, "type", "month");
    },
    title: "Monat",
    active: type === "month",
  };

  const weekAction: IAction = {
    icon: CalendarWeek,
    onClick: () => {
      setType("week");
      setParamToUrl(navigateTo, "type", "week");
    },
    title: "Woche",
    active: type === "week",
  };

  const dayAction: IAction = {
    icon: CalendarDay,
    onClick: () => {
      setType("day");
      setParamToUrl(navigateTo, "type", "day");
    },
    title: "Tag",
    active: type === "day",
  };

  const actions: IAction[] = [monthAction, weekAction, dayAction];

  const renderCalender = (type: CalendarType) => {
    switch (type) {
      case "month":
        return (
          <MonthlyCalendar
            updateCalendar={updateCalendar}
            defaultMonth={props.defaultDate}
            showWeeks={props.showWeeks}
            data={props.data}
            addHref={props.addHref}
            onAddItem={props.onAddItem}
          />
        );
      case "week":
        return (
          <WeeklyCalendar
            updateCalendar={updateCalendar}
            defaultWeek={props.defaultDate}
            denySelectDay={props.denySelectDay}
            data={props.data}
            addHref={props.addHref}
            onAddItem={props.onAddItem}
          />
        );
      case "day":
        return (
          <DailyCalendar
            updateCalendar={updateCalendar}
            defaultDay={props.defaultDate}
            data={props.data}
            addHref={props.addHref}
            onAddItem={props.onAddItem}
          />
        );
    }
  };

  return (
    <div className={styles["cal-container"]}>
      <div className={styles["cal-title"]}>
        <Title text={title} />
        {!props.denySelectType && <Actions actions={actions} />}
      </div>
      {renderCalender(type)}
    </div>
  );
};

export default Calendar;

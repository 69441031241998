import classNames from "classnames";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import HGVLogo from "../assets/hgv.svg";
import useGlobalState from "../hooks/useGlobalState";
import useIdentity from "../hooks/useIdentity";
import useOutsideClick from "../hooks/useOutsideClick";
import { getCurrentUrlAsSource } from "../services/utils";
import Settings from "../svg/Settings.svg?react";
import User from "../svg/User.svg?react";
import styles from "./Ribbon.module.css";

const Ribbon: FC = () => {
  const identity = useIdentity();
  const page = useGlobalState((state) => state.page);
  const listName = useGlobalState((state) => state.listName);
  const currentSchema = useGlobalState((state) => state.schema);
  const isUserAdmin = useGlobalState((state) => state.isUserAdmin);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

  const closeSettingsMenu = () => {
    setSettingsMenuOpen(false);
  };

  const refSettings = useOutsideClick<HTMLDivElement>(closeSettingsMenu);

  const closeUserMenu = () => {
    setUserMenuOpen(false);
  };

  const refUserMenu = useOutsideClick<HTMLDivElement>(closeUserMenu);

  return (
    <div>
      <header>
        <div className={classNames(styles.ribbon, styles.flex)}>
          <span className={styles["ribbon-title"]}>
            <div>
              <img className={styles.logo} src={HGVLogo} alt="HGV" title="HGV" />
              <span>HGV Portal</span>
              {currentSchema.item?.name && <span>&nbsp;&#8594;&nbsp;{currentSchema.item?.name}</span>}
              {listName && <span>&nbsp;&#8594;&nbsp;{listName}</span>}
              {page && <span>&nbsp;&#8594;&nbsp;{page}</span>}
            </div>
          </span>
          {isUserAdmin && (
            <div className={styles.flex}>
              <Link className={styles.flex} to="#" onClick={() => setSettingsMenuOpen(true)}>
                <Settings />
              </Link>
            </div>
          )}
          <div className={classNames(styles.identity, styles.flex)}>
            {identity.name}
            <div className={styles.flex}>
              <Link className={styles.flex} to="#" onClick={() => setUserMenuOpen(true)}>
                <User />
              </Link>
            </div>
          </div>
        </div>
      </header>
      {userMenuOpen && (
        <div className={styles.menu} ref={refUserMenu}>
          <Link className={styles.flex} to="/_me">
            Über mich
          </Link>
          <Link className={styles.flex} to="#" onClick={() => identity.logout()}>
            Abmelden
          </Link>
        </div>
      )}
      {settingsMenuOpen && (
        <div className={styles.menu} ref={refSettings}>
          <Link className={styles.flex} to={`/_settings/edit_menu?${getCurrentUrlAsSource()}`}>
            Hauptmenü bearbeiten
          </Link>
        </div>
      )}
    </div>
  );
};

export default Ribbon;

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import { DialogOptions, SearchDialog } from "../../../components/dialogs/SearchDialog";
import Email from "../../../components/Email";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import {
  formatBoolean,
  getCloseAction,
  getCurrentUrlAsSource,
  getSelectedItems,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Cross from "../../../svg/Cross.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as UserFields from "../users/Fields";
import { UserFull } from "../users/types";
import { addGroup, searchUsers } from "../users/webapi";
import * as Fields from "./Fields";
import { GroupFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  addGroupGroup,
  deleteGroup,
  getGroup,
  getGroupGroups,
  getGroupGroupsFilters,
  getGroups,
  getGroupsOfParent,
  getGroupsOfParentFilters,
  getGroupUsers,
  getGroupUsersFilters,
  removeGroupGroups,
  removeGroupsOfParent,
  removeGroupUsers,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<GroupFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(id);

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: string) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Groups.InternalName,
      Administration.InternalName,
      abortController.signal,
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getGroup(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);

          if (result.data) {
            loadGroupUsers(result.data.id, paginationGroupUsers, abortController);
            loadGroupGroups(result.data.id, paginationGroupGroups, abortController);
            loadMembersOfGroup(result.data.id, paginationMembersOfGroup, abortController);
          }
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteGroup(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !state || state?.isActiveDirectoryGroup || !permissions?.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !state || state?.isActiveDirectoryGroup || !permissions?.listPermissions.includes("delete-all"),
  };

  const groupFields = [Fields.name, Fields.email, Fields.description];
  const userFields = [UserFields.name, UserFields.email];

  const groupDialogFields = [Fields.name, Fields.description];
  const userDialogFields = [UserFields.name, UserFields.email];

  const [showGroupGroupsDialog, setShowGroupGroupsDialog] = useState(false);
  const groupDialogOptions: DialogOptions<GroupFull> = {
    title: Administration.Lists.Groups.Title,
    fields: groupDialogFields,
    searchItems: async (searchValue: string, abortController: AbortController) =>
      await getGroups(
        {
          ...DefaultPagination,
          searchKey: searchValue,
          filters: [
            {
              fieldname: Fields.isActiveDirectoryGroup.fieldName,
              values: [{ value: false }],
            },
          ],
        },
        abortController.signal,
      ),
    onItemSelected: async (group: GroupFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addGroupGroup(state, group, abortController.signal);
        await loadGroupGroups(state.id, paginationMembersOfGroup, abortController);
      }
      setShowGroupGroupsDialog(false);
    },
    cancelClicked: () => setShowGroupGroupsDialog(false),
    isOpen: showGroupGroupsDialog,
  };

  const [showMembersOfGroupDialog, setShowMembersOfGroupDialog] = useState(false);
  const memberOfGroupDialogOptions: DialogOptions<GroupFull> = {
    title: Administration.Lists.Groups.Title,
    fields: groupDialogFields,
    searchItems: async (searchValue: string, abortController: AbortController) =>
      await getGroups(
        {
          ...paginationMembersOfGroup,
          searchKey: searchValue,
          filters: [
            {
              fieldname: Fields.isActiveDirectoryGroup.fieldName,
              values: [{ value: false }],
            },
          ],
        },
        abortController.signal,
      ),
    onItemSelected: async (group: GroupFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addGroupGroup(group, state, abortController.signal);
        await loadMembersOfGroup(state.id, paginationMembersOfGroup, abortController);
      }
      setShowMembersOfGroupDialog(false);
    },
    cancelClicked: () => setShowMembersOfGroupDialog(false),
    isOpen: showMembersOfGroupDialog,
  };

  const [showGroupUsersDialog, setShowGroupUsersDialog] = useState(false);
  const groupUsersDialogOptions: DialogOptions<UserFull> = {
    title: Administration.Lists.Users.Title,
    fields: userDialogFields,
    searchItems: (value: string, abortController: AbortController) => searchUsers(value, abortController.signal),
    onItemSelected: async (user: UserFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addGroup(user, state, abortController.signal);
        await loadGroupUsers(state.id, paginationGroupUsers, abortController);
      }
      setShowGroupUsersDialog(false);
    },
    cancelClicked: () => setShowGroupUsersDialog(false),
    isOpen: showGroupUsersDialog,
  };

  const listIdGroupGroups = "group-groups";
  const [groupGroups, setGroupGroups] = useState<SearchResult<GroupFull> | null>(null);

  const paginationGroupGroups = getPagination(listIdGroupGroups) || {
    ...DefaultPagination,
  };

  const loadGroupGroups = async (groupId: string, pagination: Pagination, abortController: AbortController) => {
    const result = await getGroupGroups(groupId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setGroupGroups(result.data);
    }
  };

  const listIdMembersOfGroup = "group-membersOfGroup";
  const [memberOfGropus, setMembersOfGroup] = useState<SearchResult<GroupFull> | null>(null);

  const paginationMembersOfGroup = getPagination(listIdMembersOfGroup) || {
    ...DefaultPagination,
  };

  const loadMembersOfGroup = async (groupId: string, pagination: Pagination, abortController: AbortController) => {
    const result = await getGroupsOfParent(groupId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setMembersOfGroup(result.data);
    }
  };

  const listIdGroupUsers = "group-users";
  const [groupUsers, setGroupUsers] = useState<SearchResult<UserFull> | null>(null);

  const paginationGroupUsers = getPagination(listIdGroupUsers) || {
    ...DefaultPagination,
  };

  const loadGroupUsers = async (groupId: string, pagination: Pagination, abortController: AbortController) => {
    const result = await getGroupUsers(groupId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setGroupUsers(result.data);
    }
  };

  const addMemberOfGroupAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => setShowMembersOfGroupDialog(true),
  };

  const removeMemberOfGroupAction: IAction = {
    title: strings.remove,
    icon: Cross,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(memberOfGropus);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeGroupsOfParent(state, selectedItems, abortController.signal);
          await loadMembersOfGroup(state.id, paginationMembersOfGroup, abortController);
        }
      }
    },
    disabled: getSelectedItems(memberOfGropus).length == 0,
  };

  const memberOfGroupActions = [addMemberOfGroupAction, removeMemberOfGroupAction];

  const addGroupGroupsAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => setShowGroupGroupsDialog(true),
  };

  const removeGroupGroupsAction: IAction = {
    title: strings.remove,
    icon: Cross,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(groupGroups);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeGroupGroups(state, selectedItems, abortController.signal);
          await loadGroupGroups(state.id, paginationGroupGroups, abortController);
        }
      }
    },
    disabled: getSelectedItems(groupGroups).length == 0,
  };

  const groupGroupsActions = [addGroupGroupsAction, removeGroupGroupsAction];

  const addGroupUsersAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => setShowGroupUsersDialog(true),
  };

  const removeGroupUsersAction: IAction = {
    title: strings.remove,
    icon: Cross,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(groupUsers);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeGroupUsers(state, selectedItems, abortController.signal);
          await loadGroupUsers(state.id, paginationGroupUsers, abortController);
        }
      }
    },
    disabled: getSelectedItems(groupUsers).length == 0,
  };

  const groupUsersActions = [addGroupUsersAction, removeGroupUsersAction];

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.Groups.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Gruppe"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.email.title}</FormLabel>
              <FormData disp>
                <Email value={state?.email} />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.description.title}</FormLabel>
              <FormData disp>{state?.description}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.isActiveDirectoryGroup.title}</FormLabel>
              <FormData disp>{formatBoolean(state?.isActiveDirectoryGroup)}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          {showMembersOfGroupDialog && (
            <SearchDialog
              title="Suche Gruppe"
              fields={memberOfGroupDialogOptions.fields}
              searchItems={memberOfGroupDialogOptions.searchItems}
              onItemSelected={memberOfGroupDialogOptions.onItemSelected}
              cancelClicked={() => setShowMembersOfGroupDialog(false)}
              isOpen={showMembersOfGroupDialog}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdMembersOfGroup}
            actions={memberOfGroupActions}
            title="Mitglied von"
            fields={groupFields}
            allowSelectRows={!state.isActiveDirectoryGroup}
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadMembersOfGroup(id, pagination, abortController);
            }}
            searchResult={memberOfGropus}
            getFilters={getGroupsOfParentFilters}
            pagination={paginationMembersOfGroup}
            updateItems={(items) =>
              memberOfGropus &&
              setMembersOfGroup({
                ...memberOfGropus,
                items: items,
              })
            }
          />
          {showGroupGroupsDialog && (
            <SearchDialog
              title="Suche Gruppe"
              fields={groupDialogOptions.fields}
              searchItems={groupDialogOptions.searchItems}
              onItemSelected={groupDialogOptions.onItemSelected}
              cancelClicked={() => setShowGroupGroupsDialog(false)}
              isOpen={showGroupGroupsDialog}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdGroupGroups}
            actions={groupGroupsActions}
            title="Mitglieder (Gruppen)"
            fields={groupFields}
            allowSelectRows={!state.isActiveDirectoryGroup}
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadGroupGroups(id, pagination, abortController);
            }}
            searchResult={groupGroups}
            getFilters={getGroupGroupsFilters}
            pagination={paginationGroupGroups}
            updateItems={(items) =>
              groupGroups &&
              setGroupGroups({
                ...groupGroups,
                items: items,
              })
            }
          />
          {showGroupUsersDialog && (
            <SearchDialog
              title="Suche Benutzer"
              fields={groupUsersDialogOptions.fields}
              searchItems={groupUsersDialogOptions.searchItems}
              onItemSelected={groupUsersDialogOptions.onItemSelected}
              cancelClicked={() => setShowGroupUsersDialog(false)}
              isOpen={showGroupUsersDialog}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdGroupUsers}
            actions={groupUsersActions}
            title="Mitglieder (Benutzer)"
            fields={userFields}
            allowSelectRows={!state.isActiveDirectoryGroup}
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadGroupUsers(id, pagination, abortController);
            }}
            searchResult={groupUsers}
            getFilters={getGroupUsersFilters}
            pagination={paginationGroupUsers}
            updateItems={(items) =>
              groupUsers &&
              setGroupUsers({
                ...groupUsers,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;

import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Public } from "../../../listsSettings/public";
import { MenuOptionValue, PostPolicy, Result, SearchResult } from "../../../services/types";
import {
  createFileUploadUrl,
  deleteItem,
  deleteItems,
  getData,
  getFilters,
  getItem,
  getItems,
  home,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { PersonFull, PersonPost } from "./types";

const personsUrl: string = `${home}/${Public.Persons.InternalName}`;
export const insertPerson = async (person: PersonPost, signal: AbortSignal): Promise<Result<PersonFull | null>> =>
  insertItem(personsUrl, person, signal);

export const updatePerson = async (
  personId: number,
  person: PersonPost,
  signal: AbortSignal,
): Promise<Result<PersonFull | null>> => updateItem(`${personsUrl}/${personId}`, person, signal);

export const deletePerson = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${personsUrl}/${id}`, signal);

export const deletePersons = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(personsUrl, ids, signal);

export const getPerson = async (id: number, signal: AbortSignal): Promise<Result<PersonFull | null>> =>
  getItem<PersonFull>(`${personsUrl}/${id}`, signal);

export const getPersons = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<PersonFull> | null>> => getItems<PersonFull>(personsUrl, pagination, signal);

export const searchPersons = async (
  searchValue: string,
  signal?: AbortSignal,
): Promise<Result<SearchResult<PersonFull> | null>> => searchItems<PersonFull>(personsUrl, searchValue, signal);

export const getPersonFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(personsUrl, fieldName, signal);

export const updatePicture = (id: number, filename: string, signal: AbortSignal): Promise<Result<string | null>> =>
  updateItem<{ filename: string }, string>(`${personsUrl}/${id}/picture`, { filename: filename }, signal);

export const deletePicture = (id: number, signal: AbortSignal) => deleteItem(`${personsUrl}/${id}/picture`, signal);

export const getPlaceholderUrl = (signal: AbortSignal, gender: "male" | "female"): Promise<Result<string>> =>
  getData(`${personsUrl}/placeholder/${gender}`, signal);

export const createPictureUploadUrl = (
  signal: AbortSignal,
  personId: number,
  filename: string,
): Promise<Result<PostPolicy | null>> =>
  createFileUploadUrl(`${personsUrl}/${personId}/picture`, signal, {
    filename: filename,
  });

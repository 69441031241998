import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { deleteItems, functionaries, getFilters, getItems, postData } from "../../../services/webapi";
import { ChapterElectionFull, ChapterElectionPost } from "./types";

const chapterElectionsUrl: string = `${functionaries}/${Functionaries.Lists.ChapterElections.InternalName}`;
export const insertChapterElection = async (
  chapterElection: ChapterElectionPost,
  signal: AbortSignal,
): Promise<CustomError | null> => {
  const result = await postData(chapterElectionsUrl, signal, chapterElection);
  return result?.error ?? null;
};

export const deleteChapterElections = (
  ids: number[],
  signal: AbortSignal,
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(chapterElectionsUrl, ids, signal);

export const getChapterElections = async (
  pagination: Pagination,
  signal: AbortSignal,
): Promise<Result<SearchResult<ChapterElectionFull> | null>> =>
  getItems<ChapterElectionFull>(chapterElectionsUrl, pagination, signal);

export const getChapterElectionFilters = (
  fieldName: string,
  signal: AbortSignal,
): Promise<Result<MenuOptionValue[] | null>> => getFilters(chapterElectionsUrl, fieldName, signal);
